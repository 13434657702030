import {
  DashboardTile,
  DashboardTileDataset,
  DashboardTiles,
  MenuNode,
  MenuSetupNode,
} from '@qtek/shared/models';
import { createSelector } from 'reselect';

import { MenuActions, MenuActionTypes } from './menu.actions';

export interface State {
  main: MenuNode[];
  wizard: MenuNode[];
  setup: MenuSetupNode[];
  dashboard: DashboardTiles;
  websocketDatasets: Record<string, DashboardTileDataset[]>;
  wizardStep: string;
}

const initialState: State = {
  main: [],
  wizard: [],
  setup: [],
  dashboard: null,
  websocketDatasets: {},
  wizardStep: '',
};

export function reducer(
  state = initialState,
  { type, payload }: MenuActions
): State {
  switch (type) {
    case MenuActionTypes.LOAD_MAIN_SUCCESS: {
      return {
        ...state,
        main: payload,
      };
    }

    case MenuActionTypes.LOAD_WIZARD_SUCCESS: {
      return {
        ...state,
        wizard: payload,
      };
    }

    case MenuActionTypes.LOAD_SETUP_SUCCESS: {
      return {
        ...state,
        setup: payload,
      };
    }

    case MenuActionTypes.LOAD_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboard: payload,
      };
    }

    case MenuActionTypes.UPDATE_CHART_DATASET: {
      const labelledData: DashboardTileDataset[] = payload.data.map(
        (dataset: DashboardTileDataset) => {
          const originalDataset: DashboardTileDataset = state.dashboard.tiles
            .find((tile: DashboardTile) => tile.datasource?.tp === payload.id)
            .datasets.find((ds: DashboardTileDataset) => ds.id === dataset.id);

          return { ...originalDataset, ...dataset };
        }
      );

      return {
        ...state,
        websocketDatasets: {
          ...state.websocketDatasets,
          [payload.id]: labelledData,
        },
      };
    }

    case MenuActionTypes.WIZARD_STEP: {
      return {
        ...state,
        wizardStep: payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getMainMenu = (state: State) => state.main;
export const getWizardMenu = (state: State) => state.wizard;
export const getSetupMenu = (state: State) => state.setup;
export const getDashboardMenu = (state: State) => state.dashboard;
export const getWizardStep = (state: State) => state.wizardStep;
export const getWebsocketDatasets = (state: State) => state.websocketDatasets;
export const getWizardStepMenu = createSelector(
  getWizardMenu,
  getWizardStep,
  (wizardMenu, stepUrl) => {
    let selectedMenu: MenuNode = wizardMenu[0];
    wizardMenu.forEach(menu => {
      if (menu.url.toLowerCase().includes(stepUrl.toLowerCase())) {
        selectedMenu = menu;
      }
    });
    return selectedMenu;
  }
);
