import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { AppState } from '../index';
import { getMainCompany } from './../index';
import { ParticipantService } from './participant.service';

import { WebSocketService, passWhenAlive } from '@qtek/core/websockets-core';
import { ParticipantViews } from '@qtek/shared/models';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as InvestorActions from './participants.actions';
import {
  AddInvestorToListFailureWS,
  AddInvestorToListSuccessWS,
  CreateDealBookFailureAction,
  CreateDealBookOnListFailureWS,
  CreateDealBookOnListSuccessWS,
  CreateDealBookSuccessAction,
  CreateSalesPipelineOnListFailureWS,
  CreateSalesPipelineOnListSuccessWS,
  DeleteDealBookFailureAction,
  DeleteDealBookOnListSuccessWS,
  DeleteDealBookSuccessAction,
  DeleteInvestorFailureWS,
  DeleteInvestorOnListFailureWS,
  DeleteInvestorOnListSuccessWS,
  DeleteSalesPipelineOnListFailureWS,
  DeleteSalesPipelineOnListSuccessWS,
  GetDealBookAction,
  GetDealBookFailureAction,
  GetDealBookListFailureWS,
  GetDealBookListSuccessWS,
  GetDealBookSuccessAction,
  GetInvestorListFailureWS,
  GetInvestorListSuccessWS,
  GetSalesPipelineListFailureWS,
  GetSalesPipelineListSuccessWS,
  ParticipantActionTypes,
  UpdateDealBookFailureAction,
  UpdateDealBookOnListFailureWS,
  UpdateDealBookOnListSuccessWS,
  UpdateDealBookSuccessAction,
  UpdateInvestorOnListFailureWS,
  UpdateInvestorOnListSuccessWS,
  UpdateSalesPipelineOnListFailureWS,
  UpdateSalesPipelineOnListSuccessWS,
} from './participants.actions';

@Injectable()
export class ParticipantsEffects {
  connectDealBook$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantActionTypes.CONNECT_DEALBOOK_LIST),
      switchMap(() =>
        this.webSocketService.getWebSocket$().pipe(
          filter(
            (data: any) => data.ent === this.participantService.entityName
          ),
          takeUntil(
            this.actions$.pipe(
              ofType(ParticipantActionTypes.DISCONNECT_DEALBOOK_LIST)
            )
          )
        )
      ),
      filter(
        ({ op, mysid }) =>
          op &&
          (mysid === ParticipantViews.DEAL_BOOK ||
            mysid === ParticipantViews.SALES_PIPELINE)
      ),
      map(({ op, res, sts }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new GetDealBookSuccessAction(res, sts?.meta)
              : new GetDealBookFailureAction(sts);
          case 'ins':
            return res
              ? new CreateDealBookSuccessAction(res)
              : new CreateDealBookFailureAction(sts);
          case 'upd':
            return res
              ? new UpdateDealBookSuccessAction(res)
              : new UpdateDealBookFailureAction(sts);
          case 'del':
            return res
              ? new DeleteDealBookSuccessAction(res)
              : new DeleteDealBookFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  handleDealBookErrors$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantActionTypes.CONNECT_DEALBOOK_LIST),
      withLatestFrom(this.store.pipe(select(getMainCompany))),
      switchMap(([{ payload }, acn]: [any, any]) => {
        let subPayload = {};
        if (payload === 'investor') {
          subPayload = {
            prms: {
              leadAcnId: acn._id,
              p: 'lead',
            },
            mysid: ParticipantViews.SALES_PIPELINE,
            view: ParticipantViews.INVESTORS,
            s: 'model',
          };
        } else {
          subPayload = {
            prms: {
              acnId: acn._id,
            },
            mysid: ParticipantViews.DEAL_BOOK,
            view: ParticipantViews.DEAL_BOOK,
            s: 'model',
          };
        }
        return this.participantService
          .subscribe(subPayload)
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(ParticipantActionTypes.DISCONNECT_DEALBOOK_LIST)
              )
            )
          );
      }),
      mergeMap(({ op }: { op: string }) => {
        return op !== 'query' ? [new GetDealBookAction()] : [];
      })
    )
  );

  createInvestor$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.AddInvestorWS>(
          ParticipantActionTypes.ADD_INVESTOR_WS
        ),
        tap(({ payload }) => this.participantService.createInvestor(payload))
      ),
    { dispatch: false }
  );

  createInvestors$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.AddInvestorsWS>(
          ParticipantActionTypes.ADD_INVESTORS_WS
        ),
        tap(({ payload }) => this.participantService.addInvestors(payload))
      ),
    { dispatch: false }
  );

  updateInvestor$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.UpdateInvestorWS>(
          ParticipantActionTypes.UPDATE_INVESTOR_WS
        ),
        tap(({ payload }) =>
          this.participantService.updateInvestor(
            payload.id,
            payload.payload,
            payload.queryId
          )
        )
      ),
    { dispatch: false }
  );

  deleteInvestor$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.DeleteInvestorWS>(
          ParticipantActionTypes.DELETE_INVESTOR_WS
        ),
        tap(({ payload }) =>
          this.participantService.deleteInvestor(payload.id, payload.queryId)
        )
      ),
    { dispatch: false }
  );

  resentInvestorInvite: any = createEffect(() =>
    this.actions$.pipe(
      ofType<InvestorActions.ResentInvestorInvite>(
        ParticipantActionTypes.RESENT_INVESTOR_INVITE
      ),
      passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
      mergeMap<any, Observable<Action>>(({ payload }) =>
        this.participantService.resentInvitation(payload).pipe(
          map(() => new InvestorActions.ResentInvestorInviteSuccess()),
          catchError(({ error }) =>
            of(new InvestorActions.ResentInvestorInviteFailure(error))
          )
        )
      )
    )
  );

  getDealBooks$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ParticipantActionTypes.GET_DEALBOOK),
        tap(({ payload }) => this.participantService.getDealBook(payload))
      ),
    { dispatch: false }
  );

  createDealBook$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<InvestorActions.CreateDealBookAction>(
        ParticipantActionTypes.CREATE_DEALBOOK
      ),
      mergeMap<any, Observable<Action>>(({ payload }) =>
        this.participantService.addDealBook(payload).pipe(
          map((payload: any) => {
            this.participantService.getDealBook(payload);

            return new InvestorActions.CreateDealBookSuccessAction(payload.res);
          }),
          catchError(({ error }) =>
            of(new InvestorActions.CreateDealBookFailureAction(error))
          )
        )
      )
    )
  );

  updateDealBook$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.UpdateDealBookAction>(
          ParticipantActionTypes.UPDATE_DEALBOOK
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) =>
          this.participantService.updateDealBook(payload.id, payload.payload)
        )
      ),
    { dispatch: false }
  );

  deleteDealBook$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.DeleteDealBookAction>(
          ParticipantActionTypes.DELETE_DEALBOOK
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.participantService.deleteDealBook(payload))
      ),
    { dispatch: false }
  );

  getIvests$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<InvestorActions.GetInvestsAction>(
        ParticipantActionTypes.GET_INVESTS
      ),
      mergeMap<any, Observable<Action>>(() =>
        this.participantService.getInvest().pipe(
          map(
            (payload: any) =>
              new InvestorActions.GetInvestsSuccessAction(payload.res)
          ),
          catchError(({ error }) =>
            of(new InvestorActions.GetInvestsFailureAction(error))
          )
        )
      )
    )
  );

  requestInvests$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InvestorActions.RequestInvests>(
        ParticipantActionTypes.REQUEST_INVESTS
      ),
      mergeMap<any, Observable<Action>>(({ payload }) =>
        this.participantService.requestInvest(payload).pipe(
          map(
            (payload: any) =>
              new InvestorActions.RequestInvestsSuccess(payload.res)
          ),
          catchError(({ error }) =>
            of(new InvestorActions.RequestInvestsFailure(error))
          )
        )
      )
    )
  );

  subscribeDealBookWS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InvestorActions.SubscribeDealBookListWS>(
        ParticipantActionTypes.SUBSCRIBE_DEAL_BOOK_LIST
      ),
      switchMap(({ payload }) => {
        const subPayload = {
          q: payload.query as any,
          view: ParticipantViews.DEAL_BOOK,
          mysid: `${ParticipantViews.DEAL_BOOK}_${payload.viewId}`,
        };
        return this.participantService
          .subscribe(subPayload)
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(ParticipantActionTypes.UNSUBSCRIBE_DEAL_BOOK_LIST)
              )
            )
          );
      }),
      map(({ op, res, sts }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new GetDealBookListSuccessWS(res, sts?.meta)
              : new GetDealBookListFailureWS(sts);
          case 'ins':
            return res
              ? new CreateDealBookOnListSuccessWS(res)
              : new CreateDealBookOnListFailureWS(sts);
          case 'upd':
            return res
              ? new UpdateDealBookOnListSuccessWS(res)
              : new UpdateDealBookOnListFailureWS(sts);
          case 'del':
            return res
              ? new DeleteDealBookOnListSuccessWS(res)
              : new DeleteInvestorFailureWS(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  getDealBooksWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.GetDealBookListWS>(
          ParticipantActionTypes.GET_DEAL_BOOK_LIST
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.participantService.getDealBookWS(payload))
      ),
    { dispatch: false }
  );

  createDealBookWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.CreateDealBookOnListWS>(
          ParticipantActionTypes.CREATE_DEAL_BOOK
        ),
        tap(({ payload }) => {
          this.participantService
            .addDealBook(payload)
            .pipe(take(1))
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  updateDealBookWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.UpdateDealBookOnListBookWS>(
          ParticipantActionTypes.UPDATE_DEAL_BOOK
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.participantService.updateDealBookWS(payload))
      ),
    { dispatch: false }
  );

  deleteDealBookWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.DeleteDealBookOnListWS>(
          ParticipantActionTypes.DELETE_DEAL_BOOK
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.participantService.deleteDealBookWS(payload))
      ),
    { dispatch: false }
  );

  subscribeInvestorsWS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InvestorActions.SubscribeInvestorListWS>(
        ParticipantActionTypes.SUBSCRIBE_INVESTOR_LIST
      ),
      switchMap(({ payload }) =>
        this.participantService
          .subscribe({
            q: payload.query as any,
            view: ParticipantViews.INVESTORS,
            mysid: `${ParticipantViews.INVESTORS}_${payload.viewId}`,
          })
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(ParticipantActionTypes.UNSUBSCRIBE_INVESTOR_LIST)
              )
            )
          )
      ),
      map(({ op, res, sts }: any) => {
        switch (op) {
          case 'query':
            return res
              ? new GetInvestorListSuccessWS(res)
              : new GetInvestorListFailureWS(sts);
          case 'ins':
            return res
              ? new AddInvestorToListSuccessWS(res)
              : new AddInvestorToListFailureWS(sts);
          case 'upd':
            return res
              ? new UpdateInvestorOnListSuccessWS(res)
              : new UpdateInvestorOnListFailureWS(sts);
          case 'del':
            return res
              ? new DeleteInvestorOnListSuccessWS(res)
              : new DeleteInvestorOnListFailureWS(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  getInvestorsWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.GetInvestorListWS>(
          ParticipantActionTypes.GET_INVESTOR_LIST
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.participantService.getInvestorsWS(payload))
      ),
    { dispatch: false }
  );

  addInvestorWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.AddInvestorToListWS>(
          ParticipantActionTypes.ADD_INVESTOR
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.participantService.addInvestorsWS(payload))
      ),
    { dispatch: false }
  );

  updateInvestorWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.UpdateInvestorOnListWS>(
          ParticipantActionTypes.UPDATE_INVESTOR
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.participantService.updateInvestorWS(payload))
      ),
    { dispatch: false }
  );

  deleteInvestorWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.DeleteInvestorWS>(
          ParticipantActionTypes.DELETE_INVESTOR
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) => this.participantService.deleteInvestorWS(payload))
      ),
    { dispatch: false }
  );

  subscribeSalesPipelineWS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InvestorActions.SubscribeSalesPipelineListWS>(
        ParticipantActionTypes.SUBSCRIBE_SALES_PIPELINE_LIST
      ),
      switchMap(({ payload }) =>
        this.participantService
          .subscribe({
            q: payload.query as any,
            s: 'model',
            view: ParticipantViews.INVESTORS,
            mysid: `${ParticipantViews.SALES_PIPELINE}_${payload.viewId}`,
          })
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(ParticipantActionTypes.UNSUBSCRIBE_SALES_PIPELINE_LIST)
              )
            )
          )
      ),

      map((response: any) => {
        const { op, res, sts } = response;
        switch (op) {
          case 'query':
            return res
              ? new GetSalesPipelineListSuccessWS(res, sts.meta)
              : new GetSalesPipelineListFailureWS(sts);
          case 'ins':
            return res
              ? new CreateSalesPipelineOnListSuccessWS(res)
              : new CreateSalesPipelineOnListFailureWS(sts);
          case 'upd':
            return res
              ? new UpdateSalesPipelineOnListSuccessWS(res)
              : new UpdateSalesPipelineOnListFailureWS(sts);
          case 'del':
            return res
              ? new DeleteSalesPipelineOnListSuccessWS(res)
              : new DeleteSalesPipelineOnListFailureWS(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  getSalesPipelineWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.GetSalesPipelineListWS>(
          ParticipantActionTypes.GET_SALES_PIPELINE_LIST
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) =>
          this.participantService.getSalesPipelineWS(payload)
        )
      ),
    { dispatch: false }
  );

  createSalesPipelineWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.CreateSalesPipelineOnListWS>(
          ParticipantActionTypes.CREATE_SALES_PIPELINE
        ),
        tap(({ payload }) => {
          // this.participantService.addDealBook(payload).pipe(take(1)).subscribe();
        })
      ),
    { dispatch: false }
  );

  updateSalesPipelineWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.UpdateSalesPipelineOnListBookWS>(
          ParticipantActionTypes.UPDATE_SALES_PIPELINE
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) =>
          this.participantService.updateSalesPipelineWS(payload)
        )
      ),
    { dispatch: false }
  );

  deleteSalesPipelineWS$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InvestorActions.DeleteSalesPipelineOnListWS>(
          ParticipantActionTypes.DELETE_SALES_PIPELINE
        ),
        passWhenAlive(this.webSocketService.getWebSocketIsAlive$()),
        tap(({ payload }) =>
          this.participantService.deleteSalesPipelineWS(payload)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private participantService: ParticipantService,
    private webSocketService: WebSocketService
  ) {}
}
