import { Action } from '@ngrx/store';

import { Item, EntityMeta, Message } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const ItemActionTypes = {
  SUBSCRIBE_ITEM: type('[Item] Subscribe Item'),
  UNSUBSCRIBE_ITEM: type('[Item] Unsuscribe Item'),
  CONNECT_ITEM: type('[Item] Connect Item'),
  DISCONNECT_ITEM: type('[Item] Disconnect Item'),
  CREATE_ITEM: type('[Item] Create Item'),
  CREATE_ITEM_FAILURE: type('[Item] Create Item Failure'),
  CREATE_ITEM_SUCCESS: type('[Item] Create Item Success'),
  GET_ITEMS: type('[Item] Get Items'),
  GET_ITEMS_FAILURE: type('[Item] Get Items Failure'),
  GET_ITEMS_SUCCESS: type('[Item] Get Items Success'),
  GET_ITEM: type('[Item] Get Item by id'),
  GET_ITEM_FAILURE: type('[Item] Get Item by id Failure'),
  GET_ITEM_SUCCESS: type('[Item] Get Item by id Success'),
  GET_FOOD_ITEMS: type('[Item] Get Food Items'),
  GET_NFT_ITEMS: type('[Item] Get NFT Items'),
  GET_BOOKING_ITEMS: type('[Item] Get Booking Items'),
  UPDATE_ITEM: type('[Item] Update Item'),
  UPDATE_ITEM_FAILURE: type('[Item] Update Item Failure'),
  UPDATE_ITEM_SUCCESS: type('[Item] Update Item Success'),
  DELETE_ITEM: type('[Item] Delete Item'),
  DELETE_ITEM_FAILURE: type('[Item] Delete Item Failure'),
  DELETE_ITEM_SUCCESS: type('[Item] Delete Item Success'),
  GET_ITEMS_DEMO_SUCCESS: type('[Item] Get Items demo Success'),
  MINT_ITEM: type('[Item] Mint Item'),
  MINT_ITEM_FAILURE: type('[Item] Mint Item Failure'),
  MINT_ITEM_SUCCESS: type('[Item] Mint Item Success'),
};

export class SubscribeItemsAction implements Action {
  type = ItemActionTypes.SUBSCRIBE_ITEM;

  constructor(public payload?: { q?: any[]; view?: string; mysid?: string }) {}
}

export class UnsubscribeItemsAction implements Action {
  type = ItemActionTypes.UNSUBSCRIBE_ITEM;

  constructor(public payload?: { mysid?: string }) {}
}
export class ConnectItemsAction implements Action {
  type = ItemActionTypes.CONNECT_ITEM;

  constructor(public payload?: any) {}
}

export class DisconnectItemsAction implements Action {
  type = ItemActionTypes.DISCONNECT_ITEM;

  constructor(public payload?: any) {}
}

export class GetItemsAction implements Action {
  type = ItemActionTypes.GET_ITEMS;

  constructor(public payload?: any) {}
}

export class GetItemsFailureAction implements Action {
  type = ItemActionTypes.GET_ITEMS_FAILURE;

  constructor(public payload?: any) {}
}

export class GetItemsSuccessAction implements Action {
  type = ItemActionTypes.GET_ITEMS_SUCCESS;

  constructor(
    public payload: { res: Item[]; meta: EntityMeta; mysid: string }
  ) {}
}

export class GetItemAction implements Action {
  type = ItemActionTypes.GET_ITEM;

  constructor(public payload: { id: string; view?: string; mysid?: string }) {}
}

export class GetItemFailureAction implements Action {
  type = ItemActionTypes.GET_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class GetItemSuccessAction implements Action {
  type = ItemActionTypes.GET_ITEM_SUCCESS;

  constructor(public payload: { res: Item; mysid: string }) {}
}

export class GetFoodItemsAction implements Action {
  type = ItemActionTypes.GET_FOOD_ITEMS;

  constructor(public payload?: any) {}
}

export class GetNftItemsAction implements Action {
  type = ItemActionTypes.GET_NFT_ITEMS;

  constructor(public payload?: any) {}
}

export class GetGuestManagerItemsAction implements Action {
  type = ItemActionTypes.GET_BOOKING_ITEMS;

  constructor(public payload?: any) {}
}

export class CreateItemAction implements Action {
  type = ItemActionTypes.CREATE_ITEM;

  constructor(public payload: Item) {}
}

export class CreateItemFailureAction implements Action {
  type = ItemActionTypes.CREATE_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class CreateItemSuccessAction implements Action {
  type = ItemActionTypes.CREATE_ITEM_SUCCESS;
  message = new Message('GNR_ITM_CREATED');

  constructor(public payload: { res: Item; mysid: string }) {}
}

export class UpdateItemAction implements Action {
  type = ItemActionTypes.UPDATE_ITEM;

  constructor(public payload: Item) {}
}

export class UpdateItemFailureAction implements Action {
  type = ItemActionTypes.UPDATE_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateItemSuccessAction implements Action {
  type = ItemActionTypes.UPDATE_ITEM_SUCCESS;
  message = new Message('GNR_ITM_UPDATED');

  constructor(public payload: { res: Item; mysid: string }) {}
}

export class DeleteItemAction implements Action {
  type = ItemActionTypes.DELETE_ITEM;

  constructor(public payload: number | string) {}
}

export class DeleteItemFailureAction implements Action {
  type = ItemActionTypes.DELETE_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteItemSuccessAction implements Action {
  type = ItemActionTypes.DELETE_ITEM_SUCCESS;
  message = new Message('GNR_ITM_DELETED');

  constructor(public payload: { res: Item; mysid: string }) {}
}

export class GetItemsDemoSuccessAction implements Action {
  type = ItemActionTypes.GET_ITEMS_DEMO_SUCCESS;

  constructor(public payload: { res: Item[]; mysid: string }) {}
}

export class MintItemAction implements Action {
  type = ItemActionTypes.MINT_ITEM;

  constructor(public payload: number) {}
}

export class MintItemFailureAction implements Action {
  type = ItemActionTypes.MINT_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class MintItemSuccessAction implements Action {
  type = ItemActionTypes.MINT_ITEM_SUCCESS;

  constructor(public payload: any) {}
}

export type ItemActions =
  | GetItemsAction
  | GetItemsFailureAction
  | GetItemsSuccessAction
  | GetItemAction
  | GetItemFailureAction
  | GetItemSuccessAction
  | GetFoodItemsAction
  | GetGuestManagerItemsAction
  | CreateItemAction
  | CreateItemFailureAction
  | CreateItemSuccessAction
  | UpdateItemAction
  | UpdateItemFailureAction
  | UpdateItemSuccessAction
  | DeleteItemAction
  | DeleteItemFailureAction
  | DeleteItemSuccessAction
  | GetItemsDemoSuccessAction
  | MintItemAction
  | MintItemFailureAction
  | MintItemSuccessAction;
