import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import * as fromRelations from './relations.actions';
import { StaffService } from './staff.service';

@Injectable()
export class RelationsEffects {
  staff$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRelations.GetStaffAction>(
        fromRelations.RelationsActionTypes.GET_STAFF
      ),
      switchMap(() =>
        this.staffService.getStaff().pipe(
          map(({ res }) => new fromRelations.GetStaffSuccessAction(res)),
          catchError(({ sts }) =>
            of(new fromRelations.GetStaffFailureAction(sts))
          )
        )
      )
    )
  );

  createEmployee$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRelations.CreateEmployeeAction>(
        fromRelations.RelationsActionTypes.CREATE_EMPLOYEE
      ),
      switchMap(({ payload }) =>
        this.staffService.createEmployee(payload).pipe(
          map(({ res }) => new fromRelations.CreateEmployeeSuccessAction(res)),
          catchError(({ sts }) => {
            return of(new fromRelations.CreateEmployeeFailureAction(sts));
          })
        )
      )
    )
  );

  sendStaffInvitation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRelations.SendStaffInvitationAction>(
        fromRelations.RelationsActionTypes.SEND_STAFF_INVITATION
      ),
      switchMap(({ payload }) =>
        this.staffService.sendInvitation(payload.prsId, payload.query).pipe(
          map(
            ({ res }) => new fromRelations.SendStaffInvitationSuccessAction(res)
          ),
          catchError(({ sts }) =>
            of(new fromRelations.SendStaffInvitationFailureAction(sts))
          )
        )
      )
    )
  );

  deleteEmployee$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRelations.DeleteEmployeeAction>(
        fromRelations.RelationsActionTypes.DELETE_EMPLOYEE
      ),
      switchMap(({ payload }) =>
        this.staffService.deleteStaff(payload).pipe(
          map(
            ({ res }) =>
              new fromRelations.DeleteEmployeeSuccessAction(res.relId)
          ),
          catchError(({ sts }) =>
            of(new fromRelations.DeleteEmployeeFailureAction(sts))
          )
        )
      )
    )
  );

  updateEmployee$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRelations.UpdateEmployeeAction>(
        fromRelations.RelationsActionTypes.UPDATE_EMPLOYEE
      ),
      switchMap(({ payload }) =>
        this.staffService.updateStaff(payload.form, payload.usrId).pipe(
          map(({ res }) => new fromRelations.UpdateEmployeeSuccessAction(res)),
          catchError(({ sts }) =>
            of(new fromRelations.UpdateEmployeeFailureAction(sts))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private staffService: StaffService) {}
}
