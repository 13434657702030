import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as momentTimezone from 'moment-timezone';

import { BackendResponse, Staff, entityName } from '@qtek/shared/models';
import { getGMTOffset } from '@qtek/shared/utils';
import { HttpEntityManager } from './../http-entity-manager';

@Injectable({
  providedIn: 'root',
})
export class StaffService extends HttpEntityManager<Staff, Staff> {
  entityName: entityName = 'usr';

  constructor(protected http: HttpClient) {
    super(true);
  }

  getStaff() {
    return super.getAll();
  }

  createEmployee(employee: Staff) {
    return super.post(employee, undefined, {
      gmtOffset: getGMTOffset(),
      tzGuess: btoa(momentTimezone.tz.guess()),
      _snd: 'email',
    });
  }

  deleteStaffInfo(relId: string) {
    return super.delete(btoa(relId), {
      tp: 'val',
    });
  }

  deleteStaff(relId: string) {
    return super.delete(btoa(relId), {
      tp: 'force',
    });
  }

  updateStaff(payload: Staff, usrId: string) {
    return super.put(payload, btoa(usrId));
  }

  sendInvitation(prsId: string, query: any) {
    const key = '_snd';
    const keyLanguage = 'lng';
    const value = Object.keys(query.sendingMethod)
      .filter(k => query.sendingMethod[k])
      .join('.');

    let params = new HttpParams().set(key, value);

    if (query.language) {
      params = params.append(keyLanguage, query.language);
    }

    return this.http.post<BackendResponse>(
      `/api/v1/service/snd/usr/${prsId}`,
      undefined,
      {
        ...this.baseRequestOptions,
        params,
      }
    );
  }
}
