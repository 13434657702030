import { Injectable } from '@angular/core';
import {
  BaseWebsocketMessage,
  QueryWebsocketMessageRequest,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClubItemsService {
  constructor(private websocketService: WebSocketService) {}

  entityName: WebsocketEntTypes = 'clbitm';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }

  getClubItems(payload: Omit<QueryWebsocketMessageRequest, 'ent' | 'op'>) {
    this.wsManager.query(payload);
  }
}
