import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env';
import { loadLazyStyles, startTracking } from '@qtek/libs/app-component';
import { AppModule } from './app/app.module';
import './locales';

startTracking(environment);
loadLazyStyles(environment.appName);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
// .catch(err => console.log(err));
