import { Action } from '@ngrx/store';
import { LoginModel, RegisterPayload } from '@qtek/shared/models';

import { type } from '@qtek/shared/utils';

/**
 * LOGIN
 */
export const LOGIN = type('[Auth] Login');
export const LOGIN_OK = type('[Auth] Login Ok');
export const LOGIN_ERROR = type('[Auth] Login Error');

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: LoginModel) {}
}

export class LoginOk implements Action {
  readonly type = LOGIN_OK;
}

export class LoginError implements Action {
  readonly type = LOGIN_ERROR;
}

type LoginType = Login | LoginOk | LoginError;

/**
 * LOGOUT
 */
export const LOGOUT = type('[Auth] Logut');

export class Logout implements Action {
  readonly type = LOGOUT;

  constructor(public payload?: { queryParams?: any; skipRediect?: true }) {}
}

type LogoutType = Logout;

/**
 * REGISTER
 */
export const REGISTER = type('[Auth] Register');
export const REGISTER_OK = type('[Auth] Register Ok');
export const REGISTER_ERROR = type('[Auth] Register Error');

export class Register implements Action {
  readonly type = REGISTER;

  constructor(public payload: RegisterPayload) {}
}

export class RegisterOk implements Action {
  readonly type = REGISTER_OK;
}

export class RegisterError implements Action {
  readonly type = REGISTER_ERROR;
}

type RegisterType = Register | RegisterOk | RegisterError;

/**
 * WEBSOCKET INIT TOKEN
 */
export const GET_WS_INIT_TOKEN = type('[Auth] Get WS init token');
export const GET_WS_INIT_TOKEN_OK = type('[Auth] Get WS init token Success');
export const GET_WS_INIT_TOKEN_ERROR = type('[Auth] Get WS init token Error');

export class GetWsInitTokenAction implements Action {
  readonly type = GET_WS_INIT_TOKEN;
}

export class GetWsInitTokenActionOk implements Action {
  readonly type = GET_WS_INIT_TOKEN_OK;

  constructor(public payload: string) {}
}

export class GetWsInitTokenActionError implements Action {
  readonly type = GET_WS_INIT_TOKEN_ERROR;
}

type GetWsInitTokenType =
  | GetWsInitTokenAction
  | GetWsInitTokenActionOk
  | GetWsInitTokenActionError;

/**
 * RESET PASSWORD
 */
export const RESET_PASSWORD_EMAIL = type('[Auth] Reset password email');
export const RESET_PASSWORD_EMAIL_SUCCESS = type(
  '[Auth] Reset password email success'
);
export const RESET_PASSWORD_EMAIL_FAILURRE = type(
  '[Auth] Reset password email failure'
);

export class ResetPasswordEmail implements Action {
  readonly type = RESET_PASSWORD_EMAIL;

  constructor(public payload: any) {}
}

export class ResetPasswordEmailSuccess implements Action {
  readonly type = RESET_PASSWORD_EMAIL_SUCCESS;
}

export class ResetPasswordEmailFailure implements Action {
  readonly type = RESET_PASSWORD_EMAIL_FAILURRE;
}

type ResetPasswordEmailType =
  | ResetPasswordEmail
  | ResetPasswordEmailSuccess
  | ResetPasswordEmailFailure;

// Action types
export type All =
  | LoginType
  | LogoutType
  | RegisterType
  | GetWsInitTokenType
  | ResetPasswordEmailType;
