import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ItemTypeBitMasks } from '@qtek/shared/models';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import * as ItemActions from './item.actions';
import { ItemService } from './item.service';

@Injectable()
export class ItemEffects {
  constructor(private actions$: Actions, private itemService: ItemService) {}

  connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ItemActions.ConnectItemsAction | ItemActions.SubscribeItemsAction>(
        ItemActions.ItemActionTypes.CONNECT_ITEM,
        ItemActions.ItemActionTypes.SUBSCRIBE_ITEM
      ),
      mergeMap(action => {
        const isQuery =
          action.type === ItemActions.ItemActionTypes.CONNECT_ITEM;
        return this.itemService.subscribe(action.payload).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType<
                | ItemActions.DisconnectItemsAction
                | ItemActions.UnsubscribeItemsAction
              >(
                ItemActions.ItemActionTypes.DISCONNECT_ITEM,
                ItemActions.ItemActionTypes.UNSUBSCRIBE_ITEM
              ),
              filter(({ payload }) => payload?.mysid === action.payload?.mysid)
            )
          )
        );
      }),
      filter((data: any) => data.op),
      map(({ res, op, sts, mysid }) => {
        switch (op) {
          case 'query':
            return res
              ? new ItemActions.GetItemsSuccessAction({
                  res: res || [],
                  meta: sts?.meta,
                  mysid,
                })
              : new ItemActions.GetItemsFailureAction(sts);
          case 'get':
            return res
              ? new ItemActions.GetItemSuccessAction({ res: res || [], mysid })
              : new ItemActions.GetItemFailureAction(sts);
          case 'ins':
            return res
              ? new ItemActions.CreateItemSuccessAction({
                  res: res || [],
                  mysid,
                })
              : new ItemActions.CreateItemFailureAction(sts);
          case 'upd':
            return res
              ? new ItemActions.UpdateItemSuccessAction({
                  res: res || [],
                  mysid,
                })
              : new ItemActions.UpdateItemFailureAction(sts);
          case 'del':
            return res
              ? new ItemActions.DeleteItemSuccessAction({
                  res: res || [],
                  mysid,
                })
              : new ItemActions.DeleteItemFailureAction(sts);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  items$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ItemActions.ItemActionTypes.GET_ITEMS),
        tap(({ payload }) => this.itemService.getItems(payload))
      ),
    { dispatch: false }
  );

  getItemById$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ItemActions.GetItemAction>(ItemActions.ItemActionTypes.GET_ITEM),
        tap(({ payload }) => this.itemService.getItemById(payload))
      ),
    { dispatch: false }
  );

  foodItems$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ItemActions.ItemActionTypes.GET_FOOD_ITEMS),
        tap(() =>
          this.itemService.getItems({
            prms: { prTp: ItemTypeBitMasks.PRODUCT_FOR_SALE },
          })
        )
      ),
    { dispatch: false }
  );

  nftItems$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ItemActions.ItemActionTypes.GET_NFT_ITEMS),
        tap(() => this.itemService.getItems({ prms: { lc: 'own' } }))
      ),
    { dispatch: false }
  );

  bookingItems$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ItemActions.ItemActionTypes.GET_BOOKING_ITEMS),
        tap(() =>
          this.itemService.getItems({
            prms: { prTp: ItemTypeBitMasks.BOOKING },
          })
        )
      ),
    { dispatch: false }
  );

  createItem$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ItemActions.CreateItemAction>(
          ItemActions.ItemActionTypes.CREATE_ITEM
        ),
        tap(({ payload }) => this.itemService.createItem({ res: payload }))
      ),
    { dispatch: false }
  );

  updateItem$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ItemActions.UpdateItemAction>(
          ItemActions.ItemActionTypes.UPDATE_ITEM
        ),
        tap(({ payload }) =>
          this.itemService.updateItem({ res: payload, id: payload.seqno })
        )
      ),
    { dispatch: false }
  );

  deleteItem$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ItemActions.DeleteItemAction>(
          ItemActions.ItemActionTypes.DELETE_ITEM
        ),
        tap(({ payload }) => this.itemService.deleteItem({ id: payload }))
      ),
    { dispatch: false }
  );

  mintItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ItemActions.MintItemAction>(ItemActions.ItemActionTypes.MINT_ITEM),
      switchMap(({ payload }) =>
        this.itemService.mintItem(payload).pipe(
          map(({ res }) => new ItemActions.MintItemSuccessAction(res)),
          catchError(({ sts }) =>
            of(new ItemActions.MintItemFailureAction(sts))
          )
        )
      )
    )
  );
}
