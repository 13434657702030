import { ETLProcessor } from '@qtek/shared/models';
import {
  UploadCenterActionTypes,
  UploadCenterActions,
} from './upload-center.actions';

export interface State {
  documents: { [key: string]: Array<any> };
  etlMap: ETLProcessor[];
}

const initialState: State = {
  documents: {},
  etlMap: [],
};

export function reducer(
  state = initialState,
  { type, payload }: UploadCenterActions
): State {
  switch (type) {
    case UploadCenterActionTypes.GET_ETL_MAP_SUCCESS: {
      return {
        ...state,
        etlMap: payload || [],
      };
    }

    case UploadCenterActionTypes.GET_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documents: {
          ...state.documents,
          [payload.mysid]: payload.res || [],
        },
      };
    }

    case UploadCenterActionTypes.UPLOAD_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documents: {
          ...state.documents,
          [payload.mysid]: [
            ...(state.documents[payload.mysid] || []),
            payload.res,
          ],
        },
      };
    }

    case UploadCenterActionTypes.UPDATE_DOCUMENTS_SUCCESS: {
      const newArr = state.documents[payload.mysid].slice();
      const index = newArr.findIndex(
        document => document._id === payload.res._id
      );
      newArr[index] = payload.res;

      return {
        ...state,
        documents: {
          ...state.documents,
          [payload.mysid]: newArr,
        },
      };
    }

    case UploadCenterActionTypes.DELETE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        documents: {
          ...state.documents,
          [payload.mysid]: [
            ...(state.documents[payload.mysid] || []).filter(
              document => document._id !== payload.id
            ),
          ],
        },
      };
    }

    default: {
      return state;
    }
  }
}
export const getUploadCenterDocuments = (state: State) => state.documents;
export const getUploadCenterETLMap = (state: State) => state.etlMap;
