import { Loan } from './loan.model';
import { LocalizationOutput } from './localization-output.model';
import { Scopes } from './scopes.model';
import { unit } from './unit-type.model';

export type ViewOrigin = 'sales_pipeline_originations' | 'other';

export class Item {
  /**
   * @param name Item name.
   * @param pr Default price per unit defined in utp.
   * @param cur Currency of sale item
   * @param utp Unit of item.
   * @param dur Item's duration.
   * @param seqno Item's unique number for given company.
   * TODO: Add param info.
   * @param accId
   * @param slstx Tax of sales item
   * @param locs  service localization
   * @param prTp service type bitmask. 1 - booking, 2 - for sale, 16 - payment
   * @param wznId
   * @param ctgs categories -  breakfast category etc
   * @param cd
   * @param itemType
   * @param selectedCategory
   * @param cap capacity
   * @param btp
   * @param docId
   * @param origfl file name
   * @param desc
   * @param dsc Description
   * @param author Author
   * @param etp
   * @param ctp
   * @param size File size in Bytes
   * @param loan
   * @param _scopes
   * @param visibility
   * @param data
   * @param loan
   * @param visibility
   * @param inComponent
   * @param webId
   * @param clbs
   * @param refId
   */
  constructor(
    public _id?: string,
    public pid?: string,
    public name?: string,
    public cmpId?: string,
    public selected?: boolean,
    public pr?: number,
    public cur?: string,
    public utp?: unit,
    public dur?: number,
    public seqno?: number,
    public accId?: number,
    public slstx?: number,
    public locs?: LocalizationOutput[],
    public prTp?: ItemTypeBitMask,
    public wznId?: string,
    public ctgs?: string[],
    public cd?: string,
    public itemType?: string,
    public selectedCategory?: any[],
    public cap?: number,
    public btp?: ReservationTypeBitMask,
    public docId?: string,
    public origfl?: string,
    public desc?: string,
    public dsc?: string,
    public author?: string,
    public etp?: string,
    public data?: {
      data?: { [key: string]: any };
      sid?: { [key: string]: string };
    },
    public ctp?: string,
    public size?: number,
    public loan?: Loan,
    public _scopes?: Scopes,
    public visibility?: number,
    public inComponent?: any,
    public webId?: string,
    public clbs?: any[],
    public refId?: string,
    public myPar?: {
      sts: number;
      role: number;
      committedVal?: {
        a: number;
        c: string;
      };
    },
    public namespace?: { [key: string]: string }
  ) {}
}

export type ItemTypeBitMask =
  | 0
  | 1
  | 2
  | 4
  | 8
  | 16
  | 32
  | 64
  | 128
  | 512
  | 1024
  | 65536;

export const BOOKING: ItemTypeBitMask = 1;
export const PRODUCT_FOR_SALE: ItemTypeBitMask = 2;
export const PRODUCT_FOR_PICKUP: ItemTypeBitMask = 4;
export const PRODUCT_FOR_DELIVERY: ItemTypeBitMask = 8;
export const PRODUCT_FOR_CATERING: ItemTypeBitMask = 16;
export const GIFT_CARD: ItemTypeBitMask = 32;
export const NFT: ItemTypeBitMask = 64;
export const OBS: ItemTypeBitMask = 128;
export const LOAN: ItemTypeBitMask = 512;
export const PRODUCT_FOR_SYN: ItemTypeBitMask = 1024;
export const ADVERTISEMENT: ItemTypeBitMask = 65536;

export const ItemTypeBitMasks = {
  BOOKING,
  PRODUCT_FOR_SALE,
  PRODUCT_FOR_PICKUP,
  PRODUCT_FOR_DELIVERY,
  PRODUCT_FOR_CATERING,
  GIFT_CARD,
  NFT,
  OBS,
  LOAN,
  PRODUCT_FOR_SYN,
  ADVERTISEMENT,
} as const;

export type ReservationTypeBitMask = 0 | 10 | 20 | 30;

export const UNDEFINED: ReservationTypeBitMask = 0;
export const PERSONAL: ReservationTypeBitMask = 10;
export const TABLE: ReservationTypeBitMask = 20;
export const EVENT: ReservationTypeBitMask = 30;

export const ReservationTypeBitMasks = {
  UNDEFINED,
  PERSONAL,
  TABLE,
  EVENT,
} as const;

export enum ReservationTypeEnum {
  'GNR_ITM_UNDEFINED' = UNDEFINED,
  'GNR_ITM_PERSONAL' = PERSONAL,
  'GNR_ITM_TABLE' = TABLE,
  'FTR_ITM_CATERING' = EVENT,
}
