import { DealTeam } from '@qtek/shared/models';
import { DealTeamActions, DealTeamActionTypes } from './deal-team.actions';

export interface DealTeamState {
  dealTeams: Record<string, DealTeam[]>;
}

const initialState: DealTeamState = {
  dealTeams: {},
};

const generateId = (deal: DealTeam) => `${deal.parId}#${deal.usr._id}`;
export function dealTeamReducer(
  state = initialState,
  { type, payload }: DealTeamActions
): DealTeamState {
  switch (type) {
    case DealTeamActionTypes.GET_DEAL_TEAMS_SUCCESS: {
      return {
        ...state,
        dealTeams: {
          ...state.dealTeams,
          [payload.mysid]: payload.dealTeams.map(deal => ({
            ...deal,
            id: generateId(deal),
          })),
        },
      };
    }

    case DealTeamActionTypes.CREATE_DEAL_TEAM_SUCCESS: {
      return {
        ...state,
        dealTeams: {
          ...state.dealTeams,
          [payload.mysid]: [
            ...(state.dealTeams[payload.mysid] || []),
            { ...payload.dealTeam, id: generateId(payload.dealTeam) },
          ],
        },
      };
    }

    case DealTeamActionTypes.DELETE_DEAL_TEAM_SUCCESS: {
      const id = `${payload.dealTeam.parId}#${payload.dealTeam.usr._id}`;
      return {
        ...state,
        dealTeams: {
          ...state.dealTeams,
          [payload.mysid]: (state.dealTeams[payload.mysid] || []).filter(
            dealTeam => dealTeam.id !== id
          ),
        },
      };
    }

    case DealTeamActionTypes.UPDATE_DEAL_TEAM_SUCCESS: {
      const updatedDealID = generateId(payload.dealTeam);

      const newArr = state.dealTeams[payload.mysid].slice();
      const index = newArr.findIndex(
        (dealTeam: any) => dealTeam.id === updatedDealID
      );
      newArr[index] = { ...payload.dealTeam, id: updatedDealID };

      return {
        ...state,
        dealTeams: {
          ...state.dealTeams,
          [payload.mysid]: newArr,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const getDealTeams = (state: DealTeamState) => state.dealTeams;
