import { Action } from '@ngrx/store';

import { PaymentPlan, Message, PaymentPlanInput } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export class PaymentPlanActionTypes {
  static readonly GET_PAYMENT_PLANS = type('[PaymentPlan] Get PaymentPlans');
  static readonly GET_PAYMENT_PLANS_SUCCESS = type(
    '[PaymentPlan] Get PaymentPlans Success'
  );
  static readonly GET_PAYMENT_PLANS_FAILURE = type(
    '[PaymentPlan] Get PaymentPlans Failure'
  );
  static readonly UPDATE_PAYMENT_PLAN = type(
    '[PaymentPlan] Update PaymentPlan'
  );
  static readonly UPDATE_PAYMENT_PLAN_SUCCESS = type(
    '[PaymentPlan] Update PaymentPlan Success'
  );
  static readonly UPDATE_PAYMENT_PLAN_FAILURE = type(
    '[PaymentPlan] Update PaymentPlan Failure'
  );
}

export class GetPaymentPlansAction implements Action {
  readonly type = PaymentPlanActionTypes.GET_PAYMENT_PLANS;

  constructor(public payload: string) {}
}

export class GetPaymentPlansSuccessAction implements Action {
  readonly type = PaymentPlanActionTypes.GET_PAYMENT_PLANS_SUCCESS;

  constructor(public payload: PaymentPlan[]) {}
}

export class GetPaymentPlansFailureAction implements Action {
  readonly type = PaymentPlanActionTypes.GET_PAYMENT_PLANS_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdatePaymentPlanAction implements Action {
  readonly type = PaymentPlanActionTypes.UPDATE_PAYMENT_PLAN;

  constructor(public payload: PaymentPlanInput) {}
}

export class UpdatePaymentPlanSuccessAction implements Action {
  readonly type = PaymentPlanActionTypes.UPDATE_PAYMENT_PLAN_SUCCESS;
  message = new Message('FTR_PLAN_UPDATED');

  constructor(public payload: PaymentPlan[]) {}
}

export class UpdatePaymentPlanFailureAction implements Action {
  readonly type = PaymentPlanActionTypes.UPDATE_PAYMENT_PLAN_FAILURE;

  constructor(public payload?: any) {}
}

export type PaymentPlanActions =
  | GetPaymentPlansAction
  | GetPaymentPlansSuccessAction
  | GetPaymentPlansFailureAction
  | UpdatePaymentPlanAction
  | UpdatePaymentPlanSuccessAction
  | UpdatePaymentPlanFailureAction;
