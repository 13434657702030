import { Action } from '@ngrx/store';
import { Message, DealTeam } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const DealTeamActionTypes = {
  CONNECT_DEAL_TEAM: type('[Deal team] Connect Deal team'),
  DISCONNECT_DEAL_TEAM: type('[Deal team] Disconnect Deal team'),
  CREATE_DEAL_TEAM: type('[Deal team] Create Deal team'),
  CREATE_DEAL_TEAM_FAILURE: type('[Deal team] Create Deal team Failure'),
  CREATE_DEAL_TEAM_SUCCESS: type('[Deal team] Create Deal team Success'),
  GET_DEAL_TEAMS: type('[Deal team] Get Deal team'),
  GET_DEAL_TEAMS_FAILURE: type('[Deal team] Get Deal teams Failure'),
  GET_DEAL_TEAMS_SUCCESS: type('[Deal team] Get Deal teams Success'),
  UPDATE_DEAL_TEAM: type('[Deal team] Update Deal team'),
  UPDATE_DEAL_TEAM_FAILURE: type('[Deal team] Update Deal team Failure'),
  UPDATE_DEAL_TEAM_SUCCESS: type('[Deal team] Update Deal team Success'),
  DELETE_DEAL_TEAM: type('[Deal team] Delete Deal team'),
  DELETE_DEAL_TEAM_FAILURE: type('[Deal team] Delete Deal team Failure'),
  DELETE_DEAL_TEAM_SUCCESS: type('[Deal team] Delete Deal team Success'),
};

export class ConnectDealTeamsAction implements Action {
  constructor(public payload: { mysid: string; q: object[] }) {}
  type = DealTeamActionTypes.CONNECT_DEAL_TEAM;
}

export class DisconnectDealTeamsAction implements Action {
  constructor(public payload?: any) {}
  type = DealTeamActionTypes.DISCONNECT_DEAL_TEAM;
}

export class CreateDealTeamAction implements Action {
  constructor(public payload: { dealTeam: Partial<DealTeam> }) {}
  type = DealTeamActionTypes.CREATE_DEAL_TEAM;
}

export class CreateDealTeamSuccessAction implements Action {
  constructor(public payload: { dealTeam: DealTeam; mysid: string }) {}
  type = DealTeamActionTypes.CREATE_DEAL_TEAM_SUCCESS;
  message = new Message('FTR_DEAL_TEAM_ADDED');
}

export class CreateDealTeamFailureAction implements Action {
  constructor(public payload?: any) {}
  type = DealTeamActionTypes.CREATE_DEAL_TEAM_FAILURE;
}

export class UpdateDealTeamAction implements Action {
  constructor(public payload: { dealTeam: Partial<DealTeam>; id: string }) {}
  type = DealTeamActionTypes.UPDATE_DEAL_TEAM;
}

export class UpdateDealTeamSuccessAction implements Action {
  constructor(public payload: { dealTeam: DealTeam; mysid: string }) {}
  type = DealTeamActionTypes.UPDATE_DEAL_TEAM_SUCCESS;
  message = new Message('FTR_DEAL_TEAM_UPDATED');
}

export class UpdateDealTeamFailureAction implements Action {
  constructor(public payload?: any) {}
  type = DealTeamActionTypes.UPDATE_DEAL_TEAM_FAILURE;
}

export class DeleteDealTeamAction implements Action {
  constructor(public payload: { id: string }) {}
  type = DealTeamActionTypes.DELETE_DEAL_TEAM;
}

export class DeleteDealTeamSuccessAction implements Action {
  constructor(public payload: { dealTeam: Partial<DealTeam>; mysid: string }) {}
  type = DealTeamActionTypes.DELETE_DEAL_TEAM_SUCCESS;
  message = new Message('FTR_DEAL_TEAM_REMOVED');
}

export class DeleteDealTeamFailureAction implements Action {
  constructor(public payload?: any) {}
  type = DealTeamActionTypes.DELETE_DEAL_TEAM_FAILURE;
}

export class GetDealTeamsSuccessAction implements Action {
  constructor(public payload: { mysid: string; dealTeams: DealTeam[] }) {}
  type = DealTeamActionTypes.GET_DEAL_TEAMS_SUCCESS;
}

export class GetDealTeamsFailureAction implements Action {
  constructor(public payload?: any) {}
  type = DealTeamActionTypes.GET_DEAL_TEAMS_FAILURE;
}

export type DealTeamActions =
  | ConnectDealTeamsAction
  | DisconnectDealTeamsAction
  | CreateDealTeamAction
  | CreateDealTeamSuccessAction
  | CreateDealTeamFailureAction
  | UpdateDealTeamAction
  | UpdateDealTeamSuccessAction
  | UpdateDealTeamFailureAction
  | DeleteDealTeamAction
  | DeleteDealTeamSuccessAction
  | DeleteDealTeamFailureAction
  | GetDealTeamsSuccessAction
  | GetDealTeamsFailureAction;
