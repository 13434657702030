import { Action } from '@ngrx/store';
import { Customer, Message, Person, Staff } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export class RelationsActionTypes {
  static readonly GET_STAFF = type('[Relationship] Get Staff');
  static readonly GET_STAFF_SUCCESS = type('[Relationship] Get Staff Success');
  static readonly GET_STAFF_FAILURE = type('[Relationship] Get Staff Failure');
  static readonly CREATE_EMPLOYEE = type('[Relationship] Create Staff');
  static readonly CREATE_EMPLOYEE_SUCCESS = type(
    '[Relationship] Create Staff Success'
  );
  static readonly CREATE_EMPLOYEE_FAILURE = type(
    '[Relationship] Create Staff Failure'
  );
  static readonly DELETE_EMPLOYEE = type('[Relationship] Delete Employee');
  static readonly DELETE_EMPLOYEE_SUCCESS = type(
    '[Relationship] Delete Employee Success'
  );
  static readonly DELETE_EMPLOYEE_FAILURE = type(
    '[Relationship] Delete Employee Failure'
  );
  static readonly UPDATE_EMPLOYEE = type('[Relationship] Update Employee');
  static readonly UPDATE_EMPLOYEE_SUCCESS = type(
    '[Relationship] Update Employee Success'
  );
  static readonly UPDATE_EMPLOYEE_FAILURE = type(
    '[Relationship] Update Employee Failure'
  );
  static readonly UPDATE_CONTACT_DETAILS = type(
    '[Relationship] Update contact details'
  );
  static readonly UPDATE_CONTACT_DETAILS_SUCCESS = type(
    '[Relationship] Update contact details Success'
  );
  static readonly UPDATE_CONTACT_DETAILS_FAILURE = type(
    '[Relationship] Update contact details Failure'
  );
  static readonly GET_RELATIONS_DEMO_SUCCESS = type(
    '[Relationship] Get Relations demo Success'
  );
  static readonly SEND_STAFF_INVITATION = type(
    '[Relationship] Send staff Invitation'
  );
  static readonly SEND_STAFF_INVITATION_SUCCESS = type(
    '[Relationship] Send staff Invitation Success'
  );
  static readonly SEND_STAFF_INVITATION_FAILURE = type(
    '[Relationship] Send staff Invitation Failure'
  );
}

export class GetStaffAction implements Action {
  readonly type = RelationsActionTypes.GET_STAFF;

  constructor(public payload?: any) {}
}

export class GetStaffSuccessAction implements Action {
  readonly type = RelationsActionTypes.GET_STAFF_SUCCESS;

  constructor(public payload: Staff[]) {}
}

export class GetStaffFailureAction implements Action {
  readonly type = RelationsActionTypes.GET_STAFF_FAILURE;

  constructor(public payload: any) {}
}

export class CreateEmployeeAction implements Action {
  readonly type = RelationsActionTypes.CREATE_EMPLOYEE;
  message = new Message('GNR_EMPLOYEE_CREATING_NEW');

  constructor(public payload: any) {}
}

export class CreateEmployeeSuccessAction implements Action {
  readonly type = RelationsActionTypes.CREATE_EMPLOYEE_SUCCESS;
  message = new Message('GNR_EMPLOYEE_CREATED');

  constructor(public payload: Staff) {}
}

export class CreateEmployeeFailureAction implements Action {
  readonly type = RelationsActionTypes.CREATE_EMPLOYEE_FAILURE;

  constructor(public payload: any) {}
}

export class SendStaffInvitationAction implements Action {
  readonly type = RelationsActionTypes.SEND_STAFF_INVITATION;
  message = new Message('GNR_MSG_INVITE_SENDING', this.params);

  constructor(
    public payload: {
      prsId: string;
      query: any;
    },
    public params?: Array<any>
  ) {}
}

export class SendStaffInvitationSuccessAction implements Action {
  readonly type = RelationsActionTypes.SEND_STAFF_INVITATION_SUCCESS;

  constructor(public payload: any) {}
}

export class SendStaffInvitationFailureAction implements Action {
  readonly type = RelationsActionTypes.SEND_STAFF_INVITATION_FAILURE;

  constructor(public payload: any) {}
}

export class DeleteEmployeeAction implements Action {
  readonly type = RelationsActionTypes.DELETE_EMPLOYEE;

  constructor(public payload: string) {}
}

export class DeleteEmployeeSuccessAction implements Action {
  readonly type = RelationsActionTypes.DELETE_EMPLOYEE_SUCCESS;
  message = new Message('GNR_EMPLOYEE_DELETED');

  constructor(public payload: any) {}
}

export class DeleteEmployeeFailureAction implements Action {
  readonly type = RelationsActionTypes.DELETE_EMPLOYEE_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateEmployeeAction implements Action {
  readonly type = RelationsActionTypes.UPDATE_EMPLOYEE;

  constructor(public payload: { form: Staff; usrId: string }) {}
}

export class UpdateEmployeeSuccessAction implements Action {
  readonly type = RelationsActionTypes.UPDATE_EMPLOYEE_SUCCESS;
  message = new Message('GNR_EMPLOYEE_UPDATED');

  constructor(public payload: Staff) {}
}

export class UpdateEmployeeFailureAction implements Action {
  readonly type = RelationsActionTypes.UPDATE_EMPLOYEE_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateContactDetailsAction implements Action {
  readonly type = RelationsActionTypes.UPDATE_CONTACT_DETAILS;

  constructor(
    public payload: {
      form: Person;
      relId: string;
      type: 'staff' | 'customer';
    }
  ) {}
}

export class UpdateContactDetailsSuccessAction implements Action {
  readonly type = RelationsActionTypes.UPDATE_CONTACT_DETAILS_SUCCESS;
  message = new Message('OK_REL_UPDATED');

  constructor(public payload: { data: Person; type: 'staff' | 'customer' }) {}
}

export class UpdateContactDetailsFailureAction implements Action {
  readonly type = RelationsActionTypes.UPDATE_CONTACT_DETAILS_FAILURE;

  constructor(public payload: any) {}
}

export class GetRelationsDemoSuccessAction implements Action {
  readonly type = RelationsActionTypes.GET_RELATIONS_DEMO_SUCCESS;

  constructor(public payload: any[]) {}
}

export type RelationsActions =
  | GetStaffAction
  | GetStaffSuccessAction
  | GetStaffFailureAction
  | CreateEmployeeAction
  | CreateEmployeeSuccessAction
  | CreateEmployeeFailureAction
  | DeleteEmployeeAction
  | DeleteEmployeeSuccessAction
  | DeleteEmployeeFailureAction
  | UpdateEmployeeAction
  | UpdateEmployeeSuccessAction
  | UpdateEmployeeFailureAction
  | UpdateContactDetailsAction
  | UpdateContactDetailsSuccessAction
  | UpdateContactDetailsFailureAction
  | GetRelationsDemoSuccessAction
  | SendStaffInvitationAction
  | SendStaffInvitationSuccessAction
  | SendStaffInvitationFailureAction;
