import { CategoryActions, CategoryActionTypes } from './category.actions';
import { Category } from '@qtek/shared/models';

export interface State {
  categories: Category[];
}

const initialState: State = {
  categories: [],
};

export function reducer(state = initialState, action: CategoryActions): State {
  switch (action.type) {
    case CategoryActionTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload || [],
      };
    }
    case CategoryActionTypes.GET_CATEGORIES_DEMO_SUCCESS: {
      return {
        ...state,
        categories: state.categories.concat(action.payload || []),
      };
    }

    default: {
      return state;
    }
  }
}

export const getCategories = (state: State) => state.categories;
