import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { bookingDialogRoutes } from '@qtek/libs/dialog-routes';

const routes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('@qtek/feature/error').then(m => m.ErrorModule),
  },
  {
    path: 'dialog',
    children: bookingDialogRoutes,
  },
  {
    path: '',
    loadChildren: () =>
      import('@qtek/feature/booking').then(m => m.BookingModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
