import {
  AccountOutput,
  BookingCartItem,
  BookingOwner,
  BookingProduct,
  BookingUser,
  ItemTypeBitMask,
  ItemTypeBitMasks,
  WebId,
} from '@qtek/shared/models';
import { UserActionTypes, UserActions } from '../actions';
import { BookingActionTypes, BookingActions } from './booking.actions';

export interface State {
  items?: BookingProduct[];
  owner?: BookingOwner;
  cart?: BookingCartItem[];
  lead?: string;
  club?: WebId;
  customer?: any;
  companyInfo?: AccountOutput;
  stp?: ItemTypeBitMask[];
  exTs?: string;
  loc?: string;
  usr?: BookingUser;
  selectedItems?: BookingProduct[];
}

const initialState: State = {
  items: [],
  owner: null,
  cart: [],
  lead: null,
  club: null,
  customer: null,
  companyInfo: null,
  stp: [ItemTypeBitMasks.PRODUCT_FOR_DELIVERY],
  exTs: 'NOW',
  loc: 'nowhere',
  usr: null,
  selectedItems: [],
};

export function reducer(
  state = initialState,
  action: BookingActions | UserActions
): State {
  switch (action.type) {
    case BookingActionTypes.INIT_BOOKING_SUCCESS: {
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        club: action.payload,
      };
    }
    case BookingActionTypes.GET_CLUB_ITEMS_SUCCESS: {
      return {
        ...state,
        items: action.payload || [],
      };
    }
    case BookingActionTypes.GET_BOOKING_ITEMS_SUCCESS: {
      const items = [
        ...(action.payload.sale ?? []).filter(
          p => p.prTp !== ItemTypeBitMasks.GIFT_CARD
        ),
        ...(action.payload.gift ?? []),
      ];
      return {
        ...state,
        items,
      };
    }
    case BookingActionTypes.SET_LEAD: {
      return {
        ...state,
        lead: action.payload,
      };
    }
    case BookingActionTypes.SET_CLUB: {
      return {
        ...state,
        club: action.payload,
      };
    }

    case BookingActionTypes.SET_ITEM_TYPE: {
      return {
        ...state,
        stp: action.payload,
      };
    }

    case BookingActionTypes.SET_DELIVERY_TIME: {
      return {
        ...state,
        exTs: action.payload,
      };
    }

    case UserActionTypes.GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        usr: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getBookingItems = (state: State) => state.items;
export const getBookingOwner = (state: State) => state.owner;
export const getBookingCart = (state: State) => state.cart;
export const getLead = (state: State) => state.lead;
export const getClub = (state: State) => state.club;
export const getCompanyInfo = (state: State) => state.companyInfo;
export const getItemType = (state: State) => state.stp;
export const getDeliveryTime = (state: State) => state.exTs;
export const getLocation = (state: State) => state.loc;
export const getUserData = (state: State) => state.usr;

export const getBookingOwnerName = (state: State) => state.owner?.name;
export const getBookingOwnerAvatarImage = (state: State) => state.owner?.lgoImg;
export const getBookingOwnerBackgroundImage = (state: State) =>
  state.owner?.bkImg;
export const getBookingOwnerPic = (state: State) => state.owner?.pic;
export const getBookingOwnerPhone = (state: State) => state.owner?.phnCnt;
