import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorMessageResponse } from '@qtek/core/websockets-core';
import { Message } from '@qtek/shared/models';

import * as fromAlertDialogComponent from './components/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class WsErrorHandlerService {
  constructor(private matDialog: MatDialog) {}

  getErrorHandler() {
    return this.errorsHandler;
  }

  public errorsHandler(message: ErrorMessageResponse) {
    if (message.sts && message.sts.sts === 'ERR') {
      const { sts } = message;
      const errors = Object.keys(sts && sts.attrs ? sts.attrs : {}).map(
        key => sts.attrs[key]
      );
      const main = errors.find(error => error.main);
      const details = errors.filter(error => !error.main);

      const dialog = this.matDialog.open(
        fromAlertDialogComponent.WSAlertDialogComponent,
        {
          ...fromAlertDialogComponent.wsAlertDialogConfig,
          data: {
            message: new Message(
              main.msg ?? `ERR_DEFAULT_MSG_WITH_PARAMS`,
              main.prms ?? [
                Object.keys(sts && sts.attrs ? sts.attrs : {}).join(', '),
              ]
            ),
            details: details.map(
              detail => new Message(detail.msg, detail.prms)
            ),
          },
        }
      );
    }
  }
}
