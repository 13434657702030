import { Action } from '@ngrx/store';
import {
  BookingItemsPayload,
  BookingSuggestionsRequest,
  EventSuggestion,
  ItemTypeBitMask,
  Message,
  MyInvestment,
  SendLinkPayload,
  WebId,
} from '@qtek/shared/models';

import { type } from '@qtek/shared/utils';
import { DisconnectClubsAction } from '../actions';

export class BookingActionTypes {
  public static readonly SUBSCRIBE_CLUB = type(
    '[Booking] WS Subscribe with query to club'
  );
  public static readonly DISCONNECT_CLUB = type(
    '[Booking] WS Unsubscribe to club'
  );
  public static readonly GET_CLUB_ITEMS = type('[Booking] WS Get club items');
  public static readonly GET_CLUB_ITEMS_SUCCESS = type(
    '[Booking] WS Get club items SUCCESS'
  );
  public static readonly CREATE_CLUB_ITEM_SUCCESS = type(
    '[Booking] WS Create club item SUCCESS'
  );
  public static readonly UPDATE_CLUB_ITEM_SUCCESS = type(
    '[Booking] WS Update club item SUCCESS'
  );
  public static readonly DELETE_CLUB_ITEM_SUCCESS = type(
    '[Booking] WS Delete club item SUCCESS'
  );

  public static readonly WARNING_BOOKING = type('[Booking] Warning booking');
  public static readonly INIT_BOOKING = type('[Booking] Init booking');
  public static readonly INIT_BOOKING_SUCCESS = type(
    '[Booking] Init booking Success'
  );
  public static readonly INIT_BOOKING_FAILURE = type(
    '[Booking] Init booking Failure'
  );
  public static readonly SEND_BOOKING = type('[Booking] Send booking');
  public static readonly SEND_BOOKING_SUCCESS = type(
    '[Booking] Send booking Success'
  );
  public static readonly SEND_BOOKING_FAILURE = type(
    '[Booking] Send booking Failure'
  );
  public static readonly SEND_BOOKING_QR = type(
    '[Booking] Send booking  qr code'
  );
  public static readonly SEND_BOOKING_QR_SUCCESS = type(
    '[Booking] Send booking qr code Success'
  );
  public static readonly SEND_BOOKING_QR_FAILURE = type(
    '[Booking] Send booking qr code Failure'
  );
  public static readonly GET_BOOKING_ITEMS = type('[Booking] Get items');
  public static readonly GET_BOOKING_ITEMS_FAILURE = type(
    '[Booking] Get items Failure'
  );
  public static readonly GET_BOOKING_ITEMS_SUCCESS = type(
    '[Booking] Get items Success'
  );
  public static readonly SAVE_BOOKING_ITEM = type('[Booking] Save item');
  public static readonly SAVE_BOOKING_ITEM_FAILURE = type(
    '[Booking] Save item Failure'
  );
  public static readonly SAVE_BOOKING_ITEM_SUCCESS = type(
    '[Booking] Save item Success'
  );
  public static readonly RESET_CART = type('[Booking] Reset cart');
  public static readonly SET_LEAD = type('[Booking] Set lead');
  public static readonly SET_CLUB = type('[Booking] Set Club');

  public static readonly SET_ITEM_TYPE = type('[Booking] Set Item type');
  public static readonly SET_DELIVERY_TIME = type(
    '[Booking] Set delivery time'
  );
  public static readonly CREATE_LEAD_USER = type('[Booking] Create lead user');
  public static readonly INVEST = type('[Booking] Invest');
  public static readonly INVEST_SUCCESS = type('[Booking] Invest Success');
  public static readonly INVEST_FAILURE = type('[Booking] Invest Failure');
}

export class SubscribeClubAction implements Action {
  constructor() {}

  readonly type = BookingActionTypes.SUBSCRIBE_CLUB;
}

export class DisonnectClubAction implements Action {
  constructor() {}

  readonly type = BookingActionTypes.DISCONNECT_CLUB;
}

export class GetClubItemsAction implements Action {
  constructor() {}

  readonly type = BookingActionTypes.GET_CLUB_ITEMS;
}

export class GetClubItemsSuccessAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.GET_CLUB_ITEMS_SUCCESS;
}

export class CreateClubItemSuccessAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.CREATE_CLUB_ITEM_SUCCESS;
}

export class UpdateClubItemSuccessAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.UPDATE_CLUB_ITEM_SUCCESS;
}

export class DeleteClubItemSuccessAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.DELETE_CLUB_ITEM_SUCCESS;
}

export class WarningBookingAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.WARNING_BOOKING;
}

export class InitBookingAction implements Action {
  constructor(public payload: { id: string; subId?: string; invId?: string }) {}

  readonly type = BookingActionTypes.INIT_BOOKING;
}

export class InitBookingSuccessAction implements Action {
  constructor(public payload: WebId) {}

  readonly type = BookingActionTypes.INIT_BOOKING_SUCCESS;
}

export class InitBookingFailureAction implements Action {
  constructor(public payload?: any) {}

  readonly type = BookingActionTypes.INIT_BOOKING_FAILURE;
}

export class SendBookingAction implements Action {
  constructor(public payload: SendLinkPayload, public params?: Array<any>) {}

  readonly type = BookingActionTypes.SEND_BOOKING;

  message = new Message('GNR_BOOKING_SENDING_VIA', this.params);
}

export class SendBookingSuccessAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.SEND_BOOKING_SUCCESS;
}

export class SendBookingFailureAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.SEND_BOOKING_FAILURE;
}

export class SendBookingQrCodeAction implements Action {
  constructor(public payload: SendLinkPayload, public params?: Array<any>) {}

  readonly type = BookingActionTypes.SEND_BOOKING_QR;

  message = new Message('GNR_BOOKING_SENDING_QR_VIA', this.params);
}

export class SendBookingQrCodeSuccessAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.SEND_BOOKING_QR_SUCCESS;
}

export class SendBookingQrCodeFailureAction implements Action {
  constructor(public payload: any) {}

  readonly type = BookingActionTypes.SEND_BOOKING_QR_FAILURE;
}

export class GetBookingItemsAction implements Action {
  constructor(public payload: string) {}

  readonly type = BookingActionTypes.GET_BOOKING_ITEMS;
}

export class GetBookingItemsFailureAction implements Action {
  constructor(public payload?: any) {}

  readonly type = BookingActionTypes.GET_BOOKING_ITEMS_FAILURE;
}

export class GetBookingItemsSuccessAction implements Action {
  constructor(public payload: BookingItemsPayload) {}

  readonly type = BookingActionTypes.GET_BOOKING_ITEMS_SUCCESS;
}

export class SaveBookingItemAction implements Action {
  constructor(
    public payload: { id: string; data: BookingSuggestionsRequest }
  ) {}

  readonly type = BookingActionTypes.SAVE_BOOKING_ITEM;
}

export class SaveBookingItemFailureAction implements Action {
  constructor(public payload?: any) {}

  readonly type = BookingActionTypes.SAVE_BOOKING_ITEM_FAILURE;
}

export class SaveBookingItemSuccessAction implements Action {
  constructor(public payload: EventSuggestion[]) {}

  readonly type = BookingActionTypes.SAVE_BOOKING_ITEM_SUCCESS;
}

export class InvestAction implements Action {
  constructor(public payload: { investment: MyInvestment; webId: string }) {}

  readonly type = BookingActionTypes.INVEST;
}

export class InvestActionSuccess implements Action {
  constructor(public payload: MyInvestment) {}

  readonly type = BookingActionTypes.INVEST_SUCCESS;
}

export class InvestActionFailure implements Action {
  constructor(public payload?: any) {}

  readonly type = BookingActionTypes.INVEST_FAILURE;
}

export class ResetCartAction implements Action {
  constructor(public payload?: any) {}

  readonly type = BookingActionTypes.RESET_CART;
}

export class SetLeadAction implements Action {
  constructor(public payload: string) {}

  readonly type = BookingActionTypes.SET_LEAD;
}

export class SetClubAction implements Action {
  constructor(public payload: WebId) {}

  readonly type = BookingActionTypes.SET_CLUB;
}

export class SetItemTypeAction implements Action {
  constructor(public payload: ItemTypeBitMask[]) {}

  public readonly type = BookingActionTypes.SET_ITEM_TYPE;
}

export class SetDeliveryTimeAction implements Action {
  constructor(public payload: string) {}

  public readonly type = BookingActionTypes.SET_DELIVERY_TIME;
}

export class CreateLeadUserAction implements Action {
  constructor(public payload: any) {}

  public readonly type = BookingActionTypes.CREATE_LEAD_USER;
}

export type BookingActions =
  | SubscribeClubAction
  | DisconnectClubsAction
  | GetClubItemsAction
  | GetClubItemsSuccessAction
  | UpdateClubItemSuccessAction
  | CreateClubItemSuccessAction
  | DeleteClubItemSuccessAction
  | InitBookingAction
  | InitBookingSuccessAction
  | InitBookingFailureAction
  | SendBookingAction
  | SendBookingSuccessAction
  | SendBookingFailureAction
  | SendBookingQrCodeAction
  | SendBookingQrCodeSuccessAction
  | SendBookingQrCodeFailureAction
  | GetBookingItemsAction
  | GetBookingItemsFailureAction
  | GetBookingItemsSuccessAction
  | SaveBookingItemAction
  | SaveBookingItemFailureAction
  | SaveBookingItemSuccessAction
  | WarningBookingAction
  | SetLeadAction
  | SetClubAction
  | ResetCartAction
  | SetItemTypeAction
  | SetDeliveryTimeAction
  | CreateLeadUserAction;
