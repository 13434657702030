import { Action } from '@ngrx/store';

import { type } from '@qtek/shared/utils';
import { Message } from '@qtek/shared/models';
import { QtCalendarEvent, SendDialogOutput } from '@qtek/shared/models';

export class CalendarActionTypes {
  static readonly CONNECT_EVENTS = type('[Calendar] Connect events');
  static readonly DISCONNECT_EVENTS = type('[Calendar] Disconnect events');
  static readonly SUBSCRIBE_EVENTS = type('[Calendar] Subscribe events');
  static readonly UNSUBSCRIBE_EVENTS = type('[Calendar] Unsubscribe events');
  static readonly GET_EVENTS = type('[Calendar] Get events');
  static readonly GET_EVENTS_SUCCESS = type('[Calendar] Get events success');
  static readonly GET_EVENT = type('[Calendar] Get event');
  static readonly GET_EVENT_SUCCESS = type('[Calendar] Get event success');
  static readonly ADD_EVENT = type('[Calendar] Add event');
  static readonly ADD_EVENT_SUCCESS = type('[Calendar] Add event success');
  static readonly ADD_EVENT_FAILURE = type('[Calendar] Add event failure');
  static readonly UPDATE_EVENT = type('[Calendar] Update event');
  static readonly UPDATE_EVENT_SUCCESS = type(
    '[Calendar] Update event success'
  );
  static readonly UPDATE_EVENT_FAILURE = type(
    '[Calendar] Update event failure'
  );
  static readonly DELETE_EVENT = type('[Calendar] Delete event');
  static readonly DELETE_EVENT_SUCCESS = type(
    '[Calendar] Delete event success'
  );
  static readonly DELETE_EVENT_FAILURE = type(
    '[Calendar] Delete event failure'
  );
  static readonly SEND_EVENT = type('[Calendar] Send event');
  static readonly SEND_EVENT_SUCCESS = type('[Calendar] Send event success');
  static readonly SEND_EVENT_FAILURE = type('[Calendar] Send event failure');

  static readonly SEND_WALK_IN_READY = type('[Calendar] Send table ready');
  static readonly SEND_WALK_IN_READY_SUCCESS = type(
    '[Calendar] Send table ready success'
  );
  static readonly SEND_WALK_IN_READY_FAILURE = type(
    '[Calendar] Send table ready failure'
  );
}

export class ConnectEventsAction implements Action {
  type = CalendarActionTypes.CONNECT_EVENTS;

  constructor(public payload?: any) {}
}

export class DisconnectEventsAction implements Action {
  type = CalendarActionTypes.DISCONNECT_EVENTS;

  constructor(public payload?: any) {}
}

export class SubscribeEventsAction implements Action {
  type = CalendarActionTypes.SUBSCRIBE_EVENTS;

  constructor(public payload?: { prms?: any; mysid?: string; id?: string }) {}
}

export class UnsubscribeEventsAction implements Action {
  type = CalendarActionTypes.UNSUBSCRIBE_EVENTS;

  constructor(public payload?: { mysid?: string }) {}
}

export class GetEventsAction implements Action {
  readonly type = CalendarActionTypes.GET_EVENTS;

  constructor(public payload?: any) {}
}

export class GetEventsSuccessAction implements Action {
  readonly type = CalendarActionTypes.GET_EVENTS_SUCCESS;

  constructor(public payload: QtCalendarEvent[]) {}
}

export class GetEventAction implements Action {
  readonly type = CalendarActionTypes.GET_EVENT;

  constructor(public payload: string) {}
}

export class GetEventSuccessAction implements Action {
  readonly type = CalendarActionTypes.GET_EVENT_SUCCESS;

  constructor(public payload: QtCalendarEvent) {}
}

export class AddEventAction implements Action {
  readonly type = CalendarActionTypes.ADD_EVENT;
  constructor(public payload: QtCalendarEvent, public dst?: string) {}
}

export class AddEventSuccessAction implements Action {
  readonly type = CalendarActionTypes.ADD_EVENT_SUCCESS;
  message = this.payload.ats
    ? new Message('GNR_CAL_NOTIFY')
    : new Message('MSG_CAL_CREATED');

  constructor(public payload: QtCalendarEvent) {}
}

export class AddEventFailureAction implements Action {
  readonly type = CalendarActionTypes.ADD_EVENT_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateEventAction implements Action {
  readonly type = CalendarActionTypes.UPDATE_EVENT;

  constructor(public payload: { id: string; event: QtCalendarEvent }) {}
}

export class UpdateEventSuccessAction implements Action {
  readonly type = CalendarActionTypes.UPDATE_EVENT_SUCCESS;
  message = new Message('MSG_CAL_UPDATED');

  constructor(public payload: QtCalendarEvent) {}
}

export class UpdateEventFailureAction implements Action {
  readonly type = CalendarActionTypes.UPDATE_EVENT_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteEventAction implements Action {
  readonly type = CalendarActionTypes.DELETE_EVENT;

  constructor(public payload: string) {}
}

export class DeleteEventSuccessAction implements Action {
  readonly type = CalendarActionTypes.DELETE_EVENT_SUCCESS;
  message = new Message('MSG_CAL_DELETED');

  constructor(public payload: QtCalendarEvent) {}
}

export class DeleteEventFailureAction implements Action {
  readonly type = CalendarActionTypes.DELETE_EVENT_FAILURE;

  constructor(public payload?: any) {}
}

export class SendEventAction implements Action {
  readonly type = CalendarActionTypes.SEND_EVENT;
  message = new Message('GNR_CAL_SENDING_VIA', this.params);

  constructor(public payload: SendDialogOutput, public params: any) {}
}

export class SendEventSuccessAction implements Action {
  readonly type = CalendarActionTypes.SEND_EVENT_SUCCESS;

  constructor(public payload: any) {}
}

export class SendEventFailureAction implements Action {
  readonly type = CalendarActionTypes.SEND_EVENT_FAILURE;

  constructor(public payload: any) {}
}

export class SendWalkInTableReadyAction {
  readonly type = CalendarActionTypes.SEND_WALK_IN_READY;

  constructor(public payload: string) {}
}

export class SendWalkInTableReadyActionSuccess {
  readonly type = CalendarActionTypes.SEND_WALK_IN_READY_SUCCESS;
  message = new Message('GNR_CAL_SND_RDY');

  constructor(public payload?: string) {}
}

export class SendWalkInTableReadyActionFailure {
  readonly type = CalendarActionTypes.SEND_WALK_IN_READY_FAILURE;

  constructor(public payload?: any) {}
}

export type CalendarActions =
  | GetEventsAction
  | GetEventsSuccessAction
  | GetEventAction
  | GetEventSuccessAction
  | AddEventAction
  | AddEventSuccessAction
  | AddEventFailureAction
  | UpdateEventAction
  | UpdateEventSuccessAction
  | UpdateEventFailureAction
  | DeleteEventAction
  | DeleteEventSuccessAction
  | DeleteEventFailureAction
  | SendEventAction
  | SendEventSuccessAction
  | SendEventFailureAction
  | SendWalkInTableReadyAction
  | SendWalkInTableReadyActionSuccess
  | SendWalkInTableReadyActionFailure;
