import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Category } from '@qtek/shared/models';
import { HttpEntityManager } from '../http-entity-manager';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends HttpEntityManager<Category, Category> {
  entityName: WebsocketEntTypes = 'ctg';
  wsManager = new WSEntityManager(this.wsService, this.entityName);

  constructor(protected http: HttpClient, private wsService: WebSocketService) {
    super();
  }

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload);
  }

  createFolder(payload: any) {
    this.wsManager.create({ res: payload });
  }

  updateFolder(id: string, payload: any) {
    this.wsManager.update({ res: payload, id });
  }

  deleteFolder(id: string, forceKeyword?: string) {
    this.wsManager.delete({
      id,
      prms: {
        'force-code': forceKeyword,
      },
    });
  }

  bulkCreate(payload: any) {
    this.wsManager.bulkUpdate({ ...payload });
  }

  getCategories() {
    const params = {
      etp: 'mtg',
    };
    return super.getAll(null, params);
  }
}
