import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WebSocketService } from '@qtek/core/websockets-core';
import { HealthcheckCoreService } from '@qtek/libs/healthcheck-core';
import { MetaCoreFeature } from '@qtek/libs/meta-core';
import { isNonNullable } from '@qtek/shared/utils';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ConnectItemsAction,
  GET_WS_INIT_TOKEN_ERROR,
  GET_WS_INIT_TOKEN_OK,
  GetCurrentUserAction,
  GetCurrentUserSuccessAction,
  GetWsInitTokenAction,
  GetWsInitTokenActionError,
  GetWsInitTokenActionOk,
  UserActionTypes,
} from '../actions';
import { BookingAppState } from '../index';
import { AuthBookingService } from './auth.service';

import { WsErrorHandlerService } from '@qtek/libs/ws-error-handler';
import * as AuthActions from './booking-auth.actions';

@Injectable()
export class BookingAuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthBookingService,
    private store: Store<BookingAppState>,
    private wsService: WebSocketService,
    private healthcheckCoreService: HealthcheckCoreService,
    private wsErrorHandler: WsErrorHandlerService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.LoginAction>(AuthActions.BOOKING_LOGIN),
      switchMap(({ payload }) =>
        this.authService.login(payload).pipe(
          mergeMap(res =>
            res.sts?.sts === 'WARN' || !res.res.acn || !res.res.prs
              ? [new AuthActions.LoginSuccessAction(), new ConnectItemsAction()]
              : [
                  new AuthActions.LoginSuccessAction(),
                  // new GetCurrentUserSuccessAction(res.res),
                  new GetCurrentUserAction(),
                  new ConnectItemsAction(),
                  new GetWsInitTokenAction(),
                ]
          ),
          catchError(() => of(new AuthActions.LoginFailureAction()))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.LogoutAction>(AuthActions.BOOKING_LOGOUT),
      switchMap(({ payload }) =>
        this.authService
          .logout()
          .pipe(map(() => new GetCurrentUserSuccessAction(null)))
      ),
      tap(() => {
        // reconnect ws
        this.wsService.initWebsocketConnection(
          this.store
            .select(MetaCoreFeature.selectWsPingPong)
            .pipe(filter(isNonNullable)),
          this.wsErrorHandler.errorsHandler.bind(this.wsErrorHandler)
        );
        this.healthcheckCoreService.registerCheckHealthSubscription(
          this.store
            .select(MetaCoreFeature.selectWsPingPong)
            .pipe(filter(isNonNullable)),
          of('/api/v1/service/meta')
        );
      })
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.RegisterAction>(AuthActions.BOOKING_REGISTER),
      exhaustMap(({ payload }) =>
        this.authService.register(payload).pipe(
          concatMap(() =>
            this.authService.login({
              email: payload.email,
              passwd: payload.passwd,
              remember: true,
            })
          ),
          mergeMap(({ res }) => [
            new GetCurrentUserSuccessAction(res),
            new AuthActions.RegisterSuccessAction(),
          ]),
          catchError(() => of(new AuthActions.RegisterFailureAction()))
        )
      )
    )
  );

  loadUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetCurrentUserSuccessAction>(
          UserActionTypes.GET_CURRENT_USER_SUCCESS
        ),
        filter(({ payload }) => payload != null)
      ),
    { dispatch: false }
  );

  wsInitTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetWsInitTokenActionOk>(GET_WS_INIT_TOKEN_OK),
        tap(({ payload }) => {
          // reconnect ws
          this.wsService.initWebsocketConnection(
            this.store
              .select(MetaCoreFeature.selectWsPingPong)
              .pipe(filter(isNonNullable)),
            this.wsErrorHandler.errorsHandler.bind(this.wsErrorHandler),
            { wsTkn: payload }
          );
          this.healthcheckCoreService.registerCheckHealthSubscription(
            this.store
              .select(MetaCoreFeature.selectWsPingPong)
              .pipe(filter(isNonNullable)),
            of('/api/v1/service/meta')
          );
        })
      ),
    { dispatch: false }
  );

  wsInitTokenError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetWsInitTokenActionError>(GET_WS_INIT_TOKEN_ERROR),
        tap(() => {
          // reconnect ws
          this.wsService.initWebsocketConnection(
            this.store
              .select(MetaCoreFeature.selectWsPingPong)
              .pipe(filter(isNonNullable)),
            this.wsErrorHandler.errorsHandler.bind(this.wsErrorHandler)
          );
          this.healthcheckCoreService.registerCheckHealthSubscription(
            this.store
              .select(MetaCoreFeature.selectWsPingPong)
              .pipe(filter(isNonNullable)),
            of('/api/v1/service/meta')
          );
        })
      ),
    { dispatch: false }
  );
}
