import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { OnlineService } from './online.service';
import { QtHttpInterceptor } from '../qtek-interceptor';

@Injectable()
export class OnlineInterceptor implements QtHttpInterceptor {
  readonly qtek = true;

  constructor(private online: OnlineService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.online.isOnline()) {
      this.notifyUserAboutOfflineState();

      return EMPTY;
    }

    return next.handle(req);
  }

  notifyUserAboutOfflineState() {
    console.log('detected offline');
    this.online.notifyUser();
  }
}
