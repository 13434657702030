import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthService } from './auth.service';

import { AppState, getGuiDomain } from '..';
import {
  CloseLoadingAction,
  GetCurrentUserSuccessAction,
  OpenLoadingAction,
} from '../actions';

import { ENVIRONMENT_APP_NAME } from '@qtek/core/api-core';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT_APP_NAME) private envAppName: string,
    private store: Store<AppState>
  ) {}

  wsInitToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.GetWsInitTokenAction>(AuthActions.GET_WS_INIT_TOKEN),
      mergeMap(() =>
        this.authService.getWsSessionInitToken().pipe(
          map(({ res }) => new AuthActions.GetWsInitTokenActionOk(res.wsTkn)),
          catchError(() => of(new AuthActions.GetWsInitTokenActionError()))
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.Login>(AuthActions.LOGIN),
      tap(() => this.store.dispatch(new OpenLoadingAction())),
      mergeMap(({ payload }) =>
        this.authService.login(payload).pipe(
          tap(res => {
            if (res.sts?.sts === 'WARN' && (!res.res.acn || !res.res.prs)) {
              this.router.navigate(['/']);
            } else {
              let { t, returnUrl } = this.route.snapshot.queryParams;
              if (t && typeof t == 'string') {
                const decodeMap: any = {
                  '-': '+',
                  _: '/',
                  '.': '=',
                };

                t = t.replace(/[-_.]/g, ch => decodeMap[ch]);

                return this.router.navigateByUrl(decodeURIComponent(atob(t)));
              }
              this.router.navigateByUrl(returnUrl || '');
            }
            return null;
          }),
          mergeMap(res => {
            if (res.sts?.sts === 'WARN' || !res.res.acn || !res.res.prs) {
              return [new AuthActions.LoginOk()];
            }
            return [
              new AuthActions.LoginOk(),
              new GetCurrentUserSuccessAction(res.res),
            ];
          }),
          catchError(() => {
            this.store.dispatch(new CloseLoadingAction());
            return of(new AuthActions.LoginError());
          })
        )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AuthActions.Logout>(AuthActions.LOGOUT),
        concatMap(({ payload }) =>
          this.authService.logout().pipe(
            finalize(() => {
              const queryParams = (payload && payload.queryParams) || null;

              this.matDialog.closeAll();

              if (!payload?.skipRediect) {
                this.router.navigate(
                  [this.envAppName === 'booking' ? '' : 'auth'],
                  {
                    queryParams,
                    queryParamsHandling: 'merge',
                  }
                );
              }
            }),
            catchError(() => {
              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.Register>(AuthActions.REGISTER),
      exhaustMap(({ payload }) => {
        this.store.dispatch(new OpenLoadingAction());

        return this.authService.register(payload).pipe(
          concatMap(() =>
            this.authService.login({
              email: payload.email,
              passwd: payload.passwd,
              mfa_code: 'Test7777',
            })
          ),
          mergeMap(({ res }) => [
            new GetCurrentUserSuccessAction(res),
            new AuthActions.RegisterOk(),
          ]),
          tap(() => {
            if (this.envAppName === 'booking') {
              this.store
                .pipe(select(getGuiDomain), filter(Boolean), take(1))
                .subscribe(domain => (this.document.location.href = domain));
            } else {
              return this.router.navigateByUrl(
                decodeURIComponent(
                  this.route.snapshot.queryParams['returnUrl'] || ''
                )
              );
            }
            return null;
          }),
          catchError(() => {
            this.store.dispatch(new CloseLoadingAction());
            return of(new AuthActions.RegisterError());
          })
        );
      })
    )
  );

  resetPasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.ResetPasswordEmail>(AuthActions.RESET_PASSWORD_EMAIL),
      switchMap(({ payload }) =>
        this.authService.sendResetPasswordEmail(payload).pipe(
          map(() => new AuthActions.ResetPasswordEmailSuccess()),
          catchError(() => of(new AuthActions.ResetPasswordEmailFailure()))
        )
      )
    )
  );
}
