import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CategoryService } from './category.service';
import {
  CreateFolderFailureWSAction,
  CreateFolderSuccessWSAction,
  DeleteFolderFailureWSAction,
  DeleteFolderSuccessWSAction,
  UpdateFolderFailureWSAction,
  UpdateFolderSuccessWSAction,
} from '../actions';
import * as CategoryActions from './category.actions';
import { CategoryActionTypes, GetCategoriesFailureAction, GetCategoriesSuccessAction } from './category.actions';

@Injectable()
export class CategoryEffects {
  connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActionTypes.CONNECT_FOLDER_LIST),
      switchMap(({ payload }: any) =>
        this.categoryService.subscribe({ prms: payload, view: 'doc' }).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(CategoryActionTypes.DISCONNECT_FOLDER_LIST),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ op, res, sts }: any) => {
        switch (op) {
          case 'ins':
            return res ? new CreateFolderSuccessWSAction(res) : new CreateFolderFailureWSAction(sts);
          case 'del':
            return res ? new DeleteFolderSuccessWSAction(res) : new DeleteFolderFailureWSAction(sts);
          case 'upd':
            return res ? new UpdateFolderSuccessWSAction(res) : new UpdateFolderFailureWSAction(sts);
          default:
            return { type: 'NON_EXISTS' };
        }
      })
    )
  );

  category$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActionTypes.GET_CATEGORIES),
      switchMap(() =>
        this.categoryService.getCategories().pipe(
          map(({ res }) => new GetCategoriesSuccessAction(res)),
          catchError(({ sts }) => of(new GetCategoriesFailureAction(sts)))
        )
      )
    )
  );

  createFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CategoryActions.AddFolderActionWS>(CategoryActionTypes.ADD_FOLDER_WS),
        tap(({ payload }) => this.categoryService.createFolder(payload))
      ),
    { dispatch: false }
  );

  updateFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CategoryActions.UpdateFolderActionWS>(CategoryActionTypes.UPDATE_FOLDER_WS),
        tap(({ payload }) => this.categoryService.updateFolder(payload.id, payload.payload))
      ),
    { dispatch: false }
  );

  deleteFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CategoryActions.DeleteFolderActionWS>(CategoryActionTypes.DELETE_FOLDER_WS),
        tap(({ payload }) => this.categoryService.deleteFolder(payload.id, payload.forceKeyword))
      ),
    { dispatch: false }
  );

  bulkCreateFolders$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CategoryActions.BulkAddFoldersActionWS>(CategoryActionTypes.BULK_CREATE_FOLDER_LIST_WS),
        tap(({ payload }) => this.categoryService.bulkCreate(payload))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private categoryService: CategoryService) {}
}
