import { Action } from '@ngrx/store';
import { LoginModel, RegisterPayload } from '@qtek/shared/models';

import { type } from '@qtek/shared/utils';

export const BOOKING_LOGOUT = type('[Booking - Auth] Logut');
export const BOOKING_LOGIN = type('[Booking - Auth] Login');
export const BOOKING_LOGIN_SUCCESS = type('[Booking - Auth] Login success');
export const BOOKING_LOGIN_FAILURE = type('[Booking - Auth] Login failure');
export const BOOKING_REGISTER = type('[Booking - Auth] Register');
export const BOOKING_REGISTER_SUCCESS = type(
  '[Booking - Auth] Register success'
);
export const REGISTER_FAILURE = type('[Booking - Auth] Register failure');

export class LoginAction implements Action {
  readonly type = BOOKING_LOGIN;

  constructor(public payload: LoginModel) {}
}

export class LoginSuccessAction implements Action {
  readonly type = BOOKING_LOGIN_SUCCESS;
}

export class LoginFailureAction implements Action {
  readonly type = BOOKING_LOGIN_FAILURE;
}

export class LogoutAction implements Action {
  readonly type = BOOKING_LOGOUT;

  constructor(public payload?: { queryParams: any }) {}
}

export class RegisterAction implements Action {
  readonly type = BOOKING_REGISTER;

  constructor(public payload: RegisterPayload) {}
}

export class RegisterSuccessAction implements Action {
  readonly type = BOOKING_REGISTER_SUCCESS;
}

export class RegisterFailureAction implements Action {
  readonly type = REGISTER_FAILURE;
}

export type BookingAuthActions =
  | LoginAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction
  | RegisterAction
  | RegisterSuccessAction
  | RegisterFailureAction;
