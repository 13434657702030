import { DocumentMarkupActions, DocumentMarkupActionsTypes } from './document-markup.actions';

export interface State {
  markups: any[];
}

const initialState: State = {
  markups: [],
};

export function reducer(state = initialState, action: DocumentMarkupActions): State {
  switch (action.type) {
    case DocumentMarkupActionsTypes.GET_DOCUMENT_MARKUPS_SUCCESS: {
      return {
        ...state,
        markups: action.payload || [],
      };
    }

    case DocumentMarkupActionsTypes.UPDATE_DOCUMENT_MARKUP_SUCCESS: {
      const index = state.markups.findIndex((profile) => profile.seqno === action.payload.seqno);
      const newProfiles = state.markups.slice();
      newProfiles[index] = action.payload;
      return {
        ...state,
        markups: newProfiles || [],
      };
    }

    case DocumentMarkupActionsTypes.DELETE_DOCUMENT_MARKUP_SUCCESS: {
      return {
        ...state,
        markups: state.markups.filter((profile) => profile.seqno !== action.payload.seqno) || [],
      };
    }

    case DocumentMarkupActionsTypes.CREATE_DOCUMENT_MARKUP_SUCCESS: {
      return {
        ...state,
        markups: [action.payload].concat(state.markups) || [],
      };
    }

    default: {
      return state;
    }
  }
}

export const getDocumentMarkups = (state: any) => state.markups;
