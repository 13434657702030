import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { WebDomainsService } from './webdomains.service';
import * as fromWebDomains from './webdomains.actions';

@Injectable()
export class WebDomainsEffects {
  webDomains$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromWebDomains.GetWebDomainsAction>(fromWebDomains.WebDomainsActionTypes.GET_WEBDOMAINS),
      switchMap(() =>
        this.webDomainsService.getWebDomains().pipe(
          map(({ res }) => new fromWebDomains.GetWebDomainsSuccessAction(res)),
          catchError(({ sts }) => of(new fromWebDomains.GetWebDomainsFailureAction(sts)))
        )
      )
    )
  );

  createWebDomain$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromWebDomains.CreateWebDomainAction>(fromWebDomains.WebDomainsActionTypes.CREATE_WEBDOMAIN),
      switchMap(({ payload }) =>
        this.webDomainsService.createWebDomain(payload).pipe(
          map(({ res }) => new fromWebDomains.CreateWebDomainSuccessAction(res)),
          catchError(({ sts }) => {
            return of(new fromWebDomains.CreateWebDomainFailureAction(sts));
          })
        )
      )
    )
  );

  deleteWebDomain$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromWebDomains.DeleteWebDomainAction>(fromWebDomains.WebDomainsActionTypes.DELETE_WEBDOMAIN),
      switchMap(({ payload }) =>
        this.webDomainsService.deleteWebDomain(payload).pipe(
          map(({ res }) => new fromWebDomains.DeleteWebDomainSuccessAction(res._id)),
          catchError(({ sts }) => of(new fromWebDomains.DeleteWebDomainFailureAction(sts)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private webDomainsService: WebDomainsService) {}
}
