import { Action } from '@ngrx/store';
import { EntityMeta, Message, Note, NoteValue } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const NoteActionTypes = {
  // entity - 'not'

  CONNECT_NOTE: type('[Note] Connect Note'),
  DISCONNECT_NOTE: type('[Note] Disconnect Note'),

  CREATE_NOTE: type('[Note] Create Note'),
  CREATE_NOTE_FAILURE: type('[Note] Create Note Failure'),
  CREATE_NOTE_SUCCESS: type('[Note] Create Note Success'),

  GET_NOTES: type('[Note] Get Notes'),
  GET_NOTES_FAILURE: type('[Note] Get Notes Failure'),
  GET_NOTES_SUCCESS: type('[Note] Get Notes Success'),

  UPDATE_NOTE: type('[Note] Update Note'),
  UPDATE_NOTE_FAILURE: type('[Note] Update Note Failure'),
  UPDATE_NOTE_SUCCESS: type('[Note] Update Note Success'),

  DELETE_NOTE: type('[Note] Delete Note'),
  DELETE_NOTE_FAILURE: type('[Note] Delete Note Failure'),
  DELETE_NOTE_SUCCESS: type('[Note] Delete Note Success'),

  // entity - 'notusr'

  CONNECT_NOTE_USER: type('[Note User] Connect Note'),
  DISCONNECT_NOTE_USER: type('[Note User] Disconnect Note'),

  CREATE_NOTE_USER: type('[Note User] Create Note'),
  CREATE_NOTE_USER_FAILURE: type('[Note User] Create Note Failure'),
  CREATE_NOTE_USER_SUCCESS: type('[Note User] Create Note Success'),

  GET_NOTES_USER: type('[Note User] Get Notes'),
  GET_NOTES_USER_FAILURE: type('[Note User] Get Notes Failure'),
  GET_NOTES_USER_SUCCESS: type('[Note User] Get Notes Success'),

  UPDATE_NOTE_USER: type('[Note User] Update Note'),
  UPDATE_NOTE_USER_FAILURE: type('[Note User] Update Note Failure'),
  UPDATE_NOTE_USER_SUCCESS: type('[Note User] Update Note Success'),

  DELETE_NOTE_USER: type('[Note User] Delete Note'),
  DELETE_NOTE_USER_FAILURE: type('[Note User] Delete Note Failure'),
  DELETE_NOTE_USER_SUCCESS: type('[Note User] Delete Note Success'),

  // entity - 'notcmp'

  CONNECT_NOTE_COMPANY: type('[Note Company] Connect Note'),
  DISCONNECT_NOTE_COMPANY: type('[Note Company] Disconnect Note'),

  CREATE_NOTE_COMPANY: type('[Note Company] Create Note'),
  CREATE_NOTE_COMPANY_FAILURE: type('[Note Company] Create Note Failure'),
  CREATE_NOTE_COMPANY_SUCCESS: type('[Note Company] Create Note Success'),

  GET_NOTES_COMPANY: type('[Note Company] Get Notes'),
  GET_NOTES_COMPANY_FAILURE: type('[Note Company] Get Notes Failure'),
  GET_NOTES_COMPANY_SUCCESS: type('[Note Company] Get Notes Success'),

  UPDATE_NOTE_COMPANY: type('[Note Company] Update Note'),
  UPDATE_NOTE_COMPANY_FAILURE: type('[Note Company] Update Note Failure'),
  UPDATE_NOTE_COMPANY_SUCCESS: type('[Note Company] Update Note Success'),

  DELETE_NOTE_COMPANY: type('[Note Company] Delete Note'),
  DELETE_NOTE_COMPANY_FAILURE: type('[Note Company] Delete Note Failure'),
  DELETE_NOTE_COMPANY_SUCCESS: type('[Note Company] Delete Note Success'),
};

export class ConnectNotesAction implements Action {
  type = NoteActionTypes.CONNECT_NOTE;

  constructor(public payload: { mysid: string; q: object[]; view?: string }) {}
}

export class DisconnectNotesAction implements Action {
  type = NoteActionTypes.DISCONNECT_NOTE;

  constructor(public payload?: any) {}
}

export class CreateNoteAction implements Action {
  type = NoteActionTypes.CREATE_NOTE;

  constructor(
    public payload: { mysid: string; refId: string; note: NoteValue }
  ) {}
}

export class CreateNoteSuccessAction implements Action {
  type = NoteActionTypes.CREATE_NOTE_SUCCESS;
  message = new Message('FTR_NOTE_ADDED');

  constructor(public payload: { note: Note; mysid: string }) {}
}

export class CreateNoteFailureAction implements Action {
  type = NoteActionTypes.CREATE_NOTE_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateNoteAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE;

  constructor(public payload: { mysid: string; note: Note }) {}
}

export class UpdateNoteSuccessAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE_SUCCESS;
  message = new Message('FTR_NOTE_UPDATED');

  constructor(public payload: { note: Note; mysid: string }) {}
}

export class UpdateNoteFailureAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteNoteAction implements Action {
  type = NoteActionTypes.DELETE_NOTE;

  constructor(public payload: { id: string; mysid: string }) {}
}

export class DeleteNoteSuccessAction implements Action {
  type = NoteActionTypes.DELETE_NOTE_SUCCESS;
  message = new Message('FTR_NOTE_REMOVED');

  constructor(public payload: { id: string; mysid: string }) {}
}

export class DeleteNoteFailureAction implements Action {
  type = NoteActionTypes.DELETE_NOTE_FAILURE;

  constructor(public payload?: any) {}
}

export class GetNotesSuccessAction implements Action {
  type = NoteActionTypes.GET_NOTES_SUCCESS;

  constructor(
    public payload: { mysid: string; notes: Note[]; meta: EntityMeta }
  ) {}
}

export class GetNotesFailureAction implements Action {
  type = NoteActionTypes.GET_NOTES_FAILURE;

  constructor(public payload?: any) {}
}

// --------------------
// Note User
// --------------------

export class ConnectNotesUserAction implements Action {
  type = NoteActionTypes.CONNECT_NOTE_USER;

  constructor(public payload: { mysid: string; q: object[]; view?: string }) {}
}

export class DisconnectNotesUserAction implements Action {
  type = NoteActionTypes.DISCONNECT_NOTE_USER;

  constructor(public payload?: any) {}
}

export class CreateNoteUserAction implements Action {
  type = NoteActionTypes.CREATE_NOTE_USER;

  constructor(
    public payload: { mysid: string; refId: string; note: NoteValue }
  ) {}
}

export class CreateNoteUserSuccessAction implements Action {
  type = NoteActionTypes.CREATE_NOTE_USER_SUCCESS;
  message = new Message('FTR_NOTE_ADDED');

  constructor(public payload: { note: Note; mysid: string }) {}
}

export class CreateNoteUserFailureAction implements Action {
  type = NoteActionTypes.CREATE_NOTE_USER_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateNoteUserAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE_USER;

  constructor(public payload: { mysid: string; note: Note }) {}
}

export class UpdateNoteUserSuccessAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE_USER_SUCCESS;
  message = new Message('FTR_NOTE_UPDATED');

  constructor(public payload: { note: Note; mysid: string }) {}
}

export class UpdateNoteUserFailureAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE_USER_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteNoteUserAction implements Action {
  type = NoteActionTypes.DELETE_NOTE_USER;

  constructor(public payload: { id: string; mysid: string }) {}
}

export class DeleteNoteUserSuccessAction implements Action {
  type = NoteActionTypes.DELETE_NOTE_USER_SUCCESS;
  message = new Message('FTR_NOTE_REMOVED');

  constructor(public payload: { id: string; mysid: string }) {}
}

export class DeleteNoteUserFailureAction implements Action {
  type = NoteActionTypes.DELETE_NOTE_USER_FAILURE;

  constructor(public payload?: any) {}
}

export class GetNotesUserSuccessAction implements Action {
  type = NoteActionTypes.GET_NOTES_USER_SUCCESS;

  constructor(
    public payload: { mysid: string; notes: Note[]; meta: EntityMeta }
  ) {}
}

export class GetNotesUserFailureAction implements Action {
  type = NoteActionTypes.GET_NOTES_USER_FAILURE;

  constructor(public payload?: any) {}
}

// --------------------
// Note Company
// --------------------

export class ConnectNotesCompanyAction implements Action {
  type = NoteActionTypes.CONNECT_NOTE_COMPANY;

  constructor(public payload: { mysid: string; q: object[]; view?: string }) {}
}

export class DisconnectNotesCompanyAction implements Action {
  type = NoteActionTypes.DISCONNECT_NOTE_COMPANY;

  constructor(public payload?: any) {}
}

export class CreateNoteCompanyAction implements Action {
  type = NoteActionTypes.CREATE_NOTE_COMPANY;

  constructor(
    public payload: { mysid: string; refId: string; note: NoteValue }
  ) {}
}

export class CreateNoteCompanySuccessAction implements Action {
  type = NoteActionTypes.CREATE_NOTE_COMPANY_SUCCESS;
  message = new Message('FTR_NOTE_ADDED');

  constructor(public payload: { note: Note; mysid: string }) {}
}

export class CreateNoteCompanyFailureAction implements Action {
  type = NoteActionTypes.CREATE_NOTE_COMPANY_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateNoteCompanyAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE_COMPANY;

  constructor(public payload: { mysid: string; note: Note }) {}
}

export class UpdateNoteCompanySuccessAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE_COMPANY_SUCCESS;
  message = new Message('FTR_NOTE_UPDATED');

  constructor(public payload: { note: Note; mysid: string }) {}
}

export class UpdateNoteCompanyFailureAction implements Action {
  type = NoteActionTypes.UPDATE_NOTE_COMPANY_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteNoteCompanyAction implements Action {
  type = NoteActionTypes.DELETE_NOTE_COMPANY;

  constructor(public payload: { id: string; mysid: string }) {}
}

export class DeleteNoteCompanySuccessAction implements Action {
  type = NoteActionTypes.DELETE_NOTE_COMPANY_SUCCESS;
  message = new Message('FTR_NOTE_REMOVED');

  constructor(public payload: { id: string; mysid: string }) {}
}

export class DeleteNoteCompanyFailureAction implements Action {
  type = NoteActionTypes.DELETE_NOTE_COMPANY_FAILURE;

  constructor(public payload?: any) {}
}

export class GetNotesCompanySuccessAction implements Action {
  type = NoteActionTypes.GET_NOTES_COMPANY_SUCCESS;

  constructor(
    public payload: { mysid: string; notes: Note[]; meta: EntityMeta }
  ) {}
}

export class GetNotesCompanyFailureAction implements Action {
  type = NoteActionTypes.GET_NOTES_COMPANY_FAILURE;

  constructor(public payload?: any) {}
}

export type NoteActions =
  // not
  | ConnectNotesAction
  | DisconnectNotesAction
  | CreateNoteAction
  | CreateNoteSuccessAction
  | CreateNoteFailureAction
  | UpdateNoteAction
  | UpdateNoteSuccessAction
  | UpdateNoteFailureAction
  | DeleteNoteAction
  | DeleteNoteSuccessAction
  | DeleteNoteFailureAction
  | GetNotesSuccessAction
  | GetNotesFailureAction
  // notusr
  | ConnectNotesUserAction
  | DisconnectNotesUserAction
  | CreateNoteUserAction
  | CreateNoteUserSuccessAction
  | CreateNoteUserFailureAction
  | UpdateNoteUserAction
  | UpdateNoteUserSuccessAction
  | UpdateNoteUserFailureAction
  | DeleteNoteUserAction
  | DeleteNoteUserSuccessAction
  | DeleteNoteUserFailureAction
  | GetNotesUserSuccessAction
  | GetNotesUserFailureAction
  // notcmp
  | ConnectNotesCompanyAction
  | DisconnectNotesCompanyAction
  | CreateNoteCompanyAction
  | CreateNoteCompanySuccessAction
  | CreateNoteCompanyFailureAction
  | UpdateNoteCompanyAction
  | UpdateNoteCompanySuccessAction
  | UpdateNoteCompanyFailureAction
  | DeleteNoteCompanyAction
  | DeleteNoteCompanySuccessAction
  | DeleteNoteCompanyFailureAction
  | GetNotesCompanySuccessAction
  | GetNotesCompanyFailureAction;
