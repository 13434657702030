import {
  AuthEffects,
  MetaEffects,
  UserEffects,
  RelationsEffects,
  WebDomainsEffects,
} from './effects';

/**
 * Effects used in every application.
 */
export const COMMON_EFFECTS = [
  AuthEffects,
  MetaEffects,
  UserEffects,
  RelationsEffects,
  WebDomainsEffects,
];
