import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as DocumentMarkupActions from './document-markup.actions';
import { DocumentMarkupActionsTypes } from './document-markup.actions';
import { DocumentMarkupService } from './document-markup.service';

@Injectable()
export class DocumentMarkupEffects {
  constructor(private actions$: Actions, private documentMarkupService: DocumentMarkupService) {}

  connect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentMarkupActionsTypes.CONNECT_DOCUMENT_MARKUPS),
      switchMap(({ payload }: any) =>
        this.documentMarkupService
          .subscribe({ prms: payload })
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType<DocumentMarkupActions.DisconnectDocumentMarkupAction>(
                  DocumentMarkupActionsTypes.DISCONNECT_DOCUMENT_MARKUPS
                )
              )
            )
          )
      ),
      map((data: any) => {
        switch (data.op) {
          case 'query':
            return new DocumentMarkupActions.GetDocumentMarkupSuccessAction(data.res);
          case 'ins':
            return new DocumentMarkupActions.CreateDocumentMarkupSuccessAction(data.res);
          case 'upd':
            return new DocumentMarkupActions.UpdateDocumentMarkupSuccessAction(data.res);
          case 'del':
            return new DocumentMarkupActions.DeleteDocumentMarkupSuccessAction(data.res);
          default:
            return { type: 'NONE' };
        }
      })
    )
  );

  profiles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DocumentMarkupActions.GetDocumentMarkupAction>(DocumentMarkupActionsTypes.GET_DOCUMENT_MARKUPS),
        tap(({ payload }) => this.documentMarkupService.getMarkups(payload))
      ),
    { dispatch: false }
  );

  updateProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DocumentMarkupActions.UpdateDocumentMarkupAction>(DocumentMarkupActionsTypes.UPDATE_DOCUMENT_MARKUP),
        tap(({ payload }) => this.documentMarkupService.updateMarkup(payload))
      ),
    { dispatch: false }
  );

  deleteProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DocumentMarkupActions.DeleteDocumentMarkupAction>(DocumentMarkupActionsTypes.DELETE_DOCUMENT_MARKUP),
        tap(({ payload }) => this.documentMarkupService.deleteMarkup(payload))
      ),
    { dispatch: false }
  );

  createProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DocumentMarkupActions.CreateDocumentMarkupAction>(DocumentMarkupActionsTypes.CREATE_DOCUMENT_MARKUP),
        tap(({ payload }) => this.documentMarkupService.createMarkup(payload))
      ),
    { dispatch: false }
  );
}
