import { Agent } from '@qtek/shared/models';
import { AgentActionsTypes, AgentsAction } from '../actions';

export interface State {
  agents: Agent[];
}

const initialState: State = {
  agents: [],
};

export function reducer(state = initialState, action: AgentsAction): State {
  switch (action.type) {
    case AgentActionsTypes.GET_AGENTS_SUCCESS: {
      return {
        ...state,
        agents: action.payload || [],
      };
    }

    case AgentActionsTypes.UPDATE_AGENT_SUCCESS: {
      const index = state.agents.findIndex(
        profile => profile.agtId === action.payload.agtId
      );
      const newProfiles = state.agents.slice();
      newProfiles[index] = action.payload;
      return {
        ...state,
        agents: newProfiles,
      };
    }

    case AgentActionsTypes.DELETE_AGENT_SUCCESS: {
      return {
        ...state,
        agents: state.agents.filter(
          profile => profile.agtId !== action.payload.agtId
        ),
      };
    }

    case AgentActionsTypes.CREATE_AGENT_SUCCESS: {
      return {
        ...state,
        agents: [action.payload].concat(state.agents),
      };
    }

    default: {
      return state;
    }
  }
}

export const getAgents = (state: State) => state.agents;
