import { Action } from '@ngrx/store';

import {
  Category,
  CreateFolderStructureWS,
  Message,
  QtCalendarEvent,
} from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export class CategoryActionTypes {
  static readonly GET_CATEGORIES = type('[Category] Get categories');
  static readonly GET_CATEGORIES_SUCCESS = type(
    '[Category] Get categories Success'
  );
  static readonly GET_CATEGORIES_FAILURE = type(
    '[Category] Get categories Failure'
  );
  static readonly GET_CATEGORIES_DEMO_SUCCESS = type(
    '[Category] Get categories demo Success'
  );

  static readonly ADD_CATEGORY = type('[Category] Add category');
  static readonly ADD_CATEGORY_SUCCESS = type(
    '[Category] Add category success'
  );
  static readonly ADD_CATEGORY_FAILURE = type(
    '[Category] Add category failure'
  );

  static readonly UPDATE_CATEGORY = type('[Category] Update category');
  static readonly UPDATE_CATEGORY_SUCCESS = type(
    '[Category] Update category success'
  );
  static readonly UPDATE_CATEGORY_FAILURE = type(
    '[Category] Update category failure'
  );

  static readonly DELETE_CATEGORY = type('[Category] Delete category');
  static readonly DELETE_CATEGORY_SUCCESS = type(
    '[Category] Delete category success'
  );
  static readonly DELETE_CATEGORY_FAILURE = type(
    '[Category] Delete category failure'
  );

  // FOLDER
  static readonly CONNECT_FOLDER_LIST = type('[Category] Connect folder List');
  static readonly DISCONNECT_FOLDER_LIST = type(
    '[Category] Disconnect folder List'
  );

  static readonly ADD_FOLDER_WS = type('[Category] Add folder via WS');

  static readonly UPDATE_FOLDER_WS = type('[Category] Update folder via WS');

  static readonly DELETE_FOLDER_WS = type('[Category] Delete folder via WS');

  static readonly BULK_CREATE_FOLDER_LIST_WS = type(
    '[Category] Bulk Create folder list via WS'
  );
}

export class GetCategoriesAction implements Action {
  readonly type = CategoryActionTypes.GET_CATEGORIES;

  constructor(public payload?: any) {}
}

export class GetCategoriesSuccessAction implements Action {
  readonly type = CategoryActionTypes.GET_CATEGORIES_SUCCESS;

  constructor(public payload: Category[]) {}
}

export class GetCategoriesFailureAction implements Action {
  readonly type = CategoryActionTypes.GET_CATEGORIES_FAILURE;

  constructor(public payload: any) {}
}

export class GetCategoriesDemoSuccessAction implements Action {
  readonly type = CategoryActionTypes.GET_CATEGORIES_DEMO_SUCCESS;

  constructor(public payload: Category[]) {}
}

export class AddCategoryAction implements Action {
  readonly type = CategoryActionTypes.ADD_CATEGORY;

  constructor(public payload: QtCalendarEvent) {}
}

export class AddCategorySuccessAction implements Action {
  readonly type = CategoryActionTypes.ADD_CATEGORY_SUCCESS;
  message = new Message('MSG_CAL_CREATED');

  constructor(public payload: QtCalendarEvent) {}
}

export class AddCategoryFailureAction implements Action {
  readonly type = CategoryActionTypes.ADD_CATEGORY_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateCategoryAction implements Action {
  readonly type = CategoryActionTypes.UPDATE_CATEGORY;

  constructor(public payload: { id: string; event: QtCalendarEvent }) {}
}

export class UpdateCategorySuccessAction implements Action {
  readonly type = CategoryActionTypes.UPDATE_CATEGORY_SUCCESS;
  message = new Message('MSG_CAL_UPDATED');

  constructor(public payload: QtCalendarEvent) {}
}

export class UpdateCategoryFailureAction implements Action {
  readonly type = CategoryActionTypes.UPDATE_CATEGORY_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteCategoryAction implements Action {
  readonly type = CategoryActionTypes.DELETE_CATEGORY;

  constructor(public payload: string) {}
}

export class DeleteCategorySuccessAction implements Action {
  readonly type = CategoryActionTypes.DELETE_CATEGORY_SUCCESS;
  message = new Message('MSG_CAL_DELETED');

  constructor(public payload: QtCalendarEvent) {}
}

export class DeleteCategoryFailureAction implements Action {
  readonly type = CategoryActionTypes.DELETE_CATEGORY_FAILURE;

  constructor(public payload?: any) {}
}

// FOLDER

export class ConnectFolderListAction implements Action {
  readonly type = CategoryActionTypes.CONNECT_FOLDER_LIST;
  constructor(public payload?: any) {}
}

export class DisconnectFolderListAction implements Action {
  readonly type = CategoryActionTypes.DISCONNECT_FOLDER_LIST;

  constructor(public payload?: any) {}
}

export class AddFolderActionWS implements Action {
  readonly type = CategoryActionTypes.ADD_FOLDER_WS;

  constructor(public payload: any) {}
}

export class UpdateFolderActionWS implements Action {
  readonly type = CategoryActionTypes.UPDATE_FOLDER_WS;

  constructor(public payload: { id: string; payload: any }) {}
}

export class DeleteFolderActionWS implements Action {
  readonly type = CategoryActionTypes.DELETE_FOLDER_WS;

  constructor(public payload: { id: string; forceKeyword?: string }) {}
}

export class BulkAddFoldersActionWS implements Action {
  constructor(public payload: { res: CreateFolderStructureWS[]; id: string }) {}

  readonly type = CategoryActionTypes.BULK_CREATE_FOLDER_LIST_WS;
}

export type CategoryActions =
  | GetCategoriesAction
  | GetCategoriesSuccessAction
  | GetCategoriesFailureAction
  | GetCategoriesDemoSuccessAction
  | AddCategoryAction
  | AddCategorySuccessAction
  | AddCategoryFailureAction
  | UpdateCategoryAction
  | UpdateCategorySuccessAction
  | UpdateCategoryFailureAction
  | DeleteCategoryAction
  | DeleteCategoryFailureAction
  | DeleteCategorySuccessAction
  | AddFolderActionWS
  | UpdateFolderActionWS
  | DeleteFolderActionWS
  | BulkAddFoldersActionWS;
