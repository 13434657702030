import { RelationsActions, RelationsActionTypes } from './relations.actions';
import { Customer, Staff, Vendor } from '@qtek/shared/models';
import { ActionReducer } from '@ngrx/store';

export interface State {
  customers: Customer[];
  staff: Staff[];
  vendors: Vendor[];
}

const initialState: State = {
  customers: [],
  staff: [],
  vendors: [],
};

export const reducer: ActionReducer<State, RelationsActions> = (
  state = initialState,
  action: RelationsActions
): State => {
  switch (action.type) {
    case RelationsActionTypes.GET_STAFF_SUCCESS: {
      return {
        ...state,
        staff: action.payload || [],
      };
    }

    case RelationsActionTypes.CREATE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        staff: [action.payload].concat(state.staff),
      };
    }

    case RelationsActionTypes.DELETE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        staff: state.staff.filter(
          relation => relation.relId !== action.payload
        ),
      };
    }

    case RelationsActionTypes.UPDATE_EMPLOYEE_SUCCESS: {
      const newRelations = state.staff.slice();

      const index = newRelations.findIndex(
        relation => relation.prsId === action.payload.prsId
      );

      newRelations[index] = action.payload;

      return {
        ...state,
        staff: newRelations,
      };
    }

    case RelationsActionTypes.UPDATE_CONTACT_DETAILS_SUCCESS: {
      if (action.payload.type === 'staff') {
        const index = state.staff.findIndex(
          relation => relation.prsId === action.payload.data.prsId
        );

        const newRelations = state.staff.slice();

        if (index !== -1) {
          newRelations[index].prs = {
            ...newRelations[index].prs,
            ...action.payload.data,
          };
        }

        return {
          ...state,
          staff: newRelations,
        };
      } else {
        const index = state.customers.findIndex(
          relation => relation.prs.prsId === action.payload.data.prsId
        );

        const newRelations = state.customers.slice();

        if (index !== -1) {
          newRelations[index].prs = {
            ...newRelations[index].prs,
            ...action.payload.data,
          };
        }

        return {
          ...state,
          customers: newRelations,
        };
      }
    }

    case RelationsActionTypes.GET_RELATIONS_DEMO_SUCCESS: {
      return {
        ...state,
        customers: state.customers.concat(action.payload || []),
      };
    }

    default: {
      return state;
    }
  }
};

export const selectCustomers = (state: State) => state.customers;
export const selectStaff = (state: State) => state.staff;
export const selectVendors = (state: State) => state.vendors;
