import { ActionReducer } from '@ngrx/store';
import { WebDomain } from '@qtek/shared/models';
import { WebDomainsActions, WebDomainsActionTypes } from './webdomains.actions';

export interface State {
  webDomains: WebDomain[];
}

const initialState: State = {
  webDomains: [],
};

export const reducer: ActionReducer<State, WebDomainsActions> = (
  state = initialState,
  action: WebDomainsActions
): State => {
  switch (action.type) {
    case WebDomainsActionTypes.GET_WEBDOMAINS_SUCCESS: {
      return {
        ...state,
        webDomains: action.payload || [],
      };
    }

    case WebDomainsActionTypes.CREATE_WEBDOMAIN_SUCCESS: {
      return {
        ...state,
        webDomains: [action.payload].concat(state.webDomains),
      };
    }

    case WebDomainsActionTypes.DELETE_WEBDOMAIN_SUCCESS: {
      return {
        ...state,
        webDomains: state.webDomains.filter(
          webDomain => webDomain._id !== action.payload
        ),
      };
    }

    default: {
      return state;
    }
  }
};

export const getWebDomains = (state: State) => state.webDomains;
