import { Scopes } from './scopes.model';

export enum ParticipantViews {
  DEAL_BOOK = 'deal',
  INVESTORS = 'investor',
  SALES_PIPELINE = 'sales_pipeline',
}

export enum Statuses {
  UNKNOWN = 0,
  REQUEST_FOR_ACCESS = 5,
  APPROVED_FOR_ACCESS = 7,
  INTERESTED = 10,
  COMMITTED = 15,
  DELIVERED = 100,
  UNDERWRITING = 200,
  CREDIT_APPROVED = 300,
  SETTLED = 500,
  DECLINED = 9999,
}

export type Status = 0 | 5 | 7 | 10 | 15 | 100 | 200 | 300 | 500 | 9999;

export type InvestorRole = '1000' | '1010' | '1020' | '-1' | '0';

export const DEAL_ADMIN: InvestorRole = '1000';
export const DOCUMENT_MANAGER: InvestorRole = '1010';
export const DEAL_VIEWER: InvestorRole = '1020';

export const InvestorRoles = {
  DEAL_ADMIN,
  DOCUMENT_MANAGER,
  DEAL_VIEWER,
} as const;

export type Intro = 1 | 2;

export const REQUEST_FROM_PARTNER: Intro = 1;
export const INVITED_BY_LEAD: Intro = 2;

export const Intros = {
  REQUEST_FROM_PARTNER,
  INVITED_BY_LEAD,
} as const;

export interface Investor {
  _id: string;
  asset: {
    name: string;
    seqno: number;
    sts?: Status;
    _scopes?: Scopes;
  };
  investor?: {
    cmpId: string;
    name?: {
      name: string;
    };
    staff?: [
      {
        cmpId?: string;
        email?: string;
        prsId?: string;
      },
    ];
    logoImg?: string;
    url?: string;
    webId?: string;
  };
  role?: InvestorRole;
  sts?: Status;
  itmId?: string;
  _scopes?: Scopes;
  indicatedVal?: { a: number; c: string };
  committedVal?: { a: number; c: string };
  allocVal?: { a: number; c: string };
  vids: { _scopes?: Scopes; vnd: string; id?: string; ent?: string }[];
}
