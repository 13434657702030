import { EntityMeta, Item } from '@qtek/shared/models';
import { AgencyActions, AgencyActionTypes } from './agency.actions';

export interface State {
  allItems: Item[];
  itemsMeta: EntityMeta;
}

const initialState: State = {
  allItems: [],
  itemsMeta: new EntityMeta(),
};

export function reducer(
  state = initialState,
  { type, payload }: AgencyActions
): State {
  switch (type) {
    case AgencyActionTypes.GET_ITEMS_SUCCESS: {
      const { res, meta } = payload;
      return {
        ...state,
        allItems: res,
        itemsMeta: meta,
      };
    }

    case AgencyActionTypes.CREATE_ITEM_SUCCESS: {
      return {
        ...state,
        allItems: [payload].concat(state.allItems),
      };
    }

    case AgencyActionTypes.UPDATE_ITEM_SUCCESS: {
      const newArr = state.allItems.slice();
      const index = newArr.findIndex(
        element => element.seqno === payload.seqno
      );
      newArr[index] = payload;

      return {
        ...state,
        allItems: newArr,
      };
    }

    case AgencyActionTypes.DELETE_ITEM_SUCCESS: {
      return {
        ...state,
        allItems: state.allItems.filter(item => item.seqno !== payload.seqno),
      };
    }

    default: {
      return state;
    }
  }
}

export const getAgency = (state: State) => state.allItems;

export const getAgencyMeta = (state: State) => state.itemsMeta;
