import { Action } from '@ngrx/store';
import { type } from '@qtek/shared/utils';

export class DocumentMarkupActionsTypes {
  static readonly CONNECT_DOCUMENT_MARKUPS = type(
    '[DocumentMarkup] Connect document markups'
  );
  static readonly DISCONNECT_DOCUMENT_MARKUPS = type(
    '[DocumentMarkup] Disconnect document markups'
  );

  static readonly GET_DOCUMENT_MARKUPS = type(
    '[DocumentMarkup] Get document markups'
  );
  static readonly GET_DOCUMENT_MARKUPS_SUCCESS = type(
    '[DocumentMarkup] Get document markups success'
  );
  static readonly GET_DOCUMENT_MARKUPS_FAILURE = type(
    '[DocumentMarkup] Get document markups failure'
  );

  static readonly UPDATE_DOCUMENT_MARKUP = type(
    '[DocumentMarkup] Update document markup'
  );
  static readonly UPDATE_DOCUMENT_MARKUP_SUCCESS = type(
    '[DocumentMarkup] Update document markup success'
  );
  static readonly UPDATE_DOCUMENT_MARKUP_FAILURE = type(
    '[DocumentMarkup] Update document markup failure'
  );

  static readonly DELETE_DOCUMENT_MARKUP = type(
    '[DocumentMarkup] Delete document markup'
  );
  static readonly DELETE_DOCUMENT_MARKUP_SUCCESS = type(
    '[DocumentMarkup] Delete document markup success'
  );
  static readonly DELETE_DOCUMENT_MARKUP_FAILURE = type(
    '[DocumentMarkup] Delete document markup failure'
  );

  static readonly CREATE_DOCUMENT_MARKUP = type(
    '[DocumentMarkup] Create document markup'
  );
  static readonly CREATE_DOCUMENT_MARKUP_SUCCESS = type(
    '[DocumentMarkup] Create document markup success'
  );
  static readonly CREATE_DOCUMENT_MARKUP_FAILURE = type(
    '[DocumentMarkup] Create document markup failure'
  );
}

export class ConnectDocumentMarkupAction implements Action {
  constructor(public payload?: any) {}

  type = DocumentMarkupActionsTypes.CONNECT_DOCUMENT_MARKUPS;
}

export class DisconnectDocumentMarkupAction implements Action {
  constructor(public payload?: any) {}

  type = DocumentMarkupActionsTypes.DISCONNECT_DOCUMENT_MARKUPS;
}

export class GetDocumentMarkupAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.GET_DOCUMENT_MARKUPS;
}

export class GetDocumentMarkupSuccessAction implements Action {
  constructor(public payload: any[]) {}

  readonly type = DocumentMarkupActionsTypes.GET_DOCUMENT_MARKUPS_SUCCESS;
}

export class GetDocumentMarkupFailureAction implements Action {
  constructor(public payload: any[]) {}

  readonly type = DocumentMarkupActionsTypes.GET_DOCUMENT_MARKUPS_FAILURE;
}

export class UpdateDocumentMarkupAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.UPDATE_DOCUMENT_MARKUP;
}

export class UpdateDocumentMarkupSuccessAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.UPDATE_DOCUMENT_MARKUP_SUCCESS;
  // message = new Message('GNR_PROFILE_UPDATED');
}

export class UpdateDocumentMarkupFailureAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.UPDATE_DOCUMENT_MARKUP_FAILURE;
}

export class DeleteDocumentMarkupAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.DELETE_DOCUMENT_MARKUP;
}

export class DeleteDocumentMarkupSuccessAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.DELETE_DOCUMENT_MARKUP_SUCCESS;
  // message = new Message('GNR_PROFILE_DELETED');
}

export class DeleteDocumentMarkupFailureAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.DELETE_DOCUMENT_MARKUP_FAILURE;
}

export class CreateDocumentMarkupAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.CREATE_DOCUMENT_MARKUP;
}

export class CreateDocumentMarkupSuccessAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.CREATE_DOCUMENT_MARKUP_SUCCESS;
  // message = new Message('GNR_PROFILE_CREATED');
}

export class CreateDocumentMarkupFailureAction implements Action {
  constructor(public payload?: any) {}

  readonly type = DocumentMarkupActionsTypes.CREATE_DOCUMENT_MARKUP_FAILURE;
}

export type DocumentMarkupActions =
  | GetDocumentMarkupAction
  | GetDocumentMarkupSuccessAction
  | GetDocumentMarkupFailureAction
  | UpdateDocumentMarkupAction
  | UpdateDocumentMarkupSuccessAction
  | UpdateDocumentMarkupFailureAction
  | DeleteDocumentMarkupAction
  | DeleteDocumentMarkupSuccessAction
  | DeleteDocumentMarkupFailureAction
  | CreateDocumentMarkupAction
  | CreateDocumentMarkupSuccessAction
  | CreateDocumentMarkupFailureAction;
