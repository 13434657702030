import { Item, EntityMeta } from '@qtek/shared/models';
import { ItemActions, ItemActionTypes } from './item.actions';

export interface State {
  allItems: Record<string, Item[]>;
  itemsMeta: EntityMeta;
}

const initialState: State = {
  allItems: {},
  itemsMeta: new EntityMeta(),
};

export function reducer(
  state = initialState,
  { type, payload }: ItemActions
): State {
  switch (type) {
    case ItemActionTypes.GET_ITEMS_SUCCESS: {
      const { res, meta, mysid } = payload;
      return {
        ...state,
        allItems: {
          ...state.allItems,
          [mysid]: res,
        },
        itemsMeta: meta || { ...state.itemsMeta },
      };
    }

    case ItemActionTypes.GET_ITEM_SUCCESS: {
      const { res, mysid } = payload;
      return {
        ...state,
        allItems: {
          ...state.allItems,
          [mysid]: [...(state.allItems[mysid] || []), res],
        },
      };
    }

    case ItemActionTypes.CREATE_ITEM_SUCCESS: {
      const { res, mysid } = payload;
      return {
        ...state,
        allItems: {
          ...state.allItems,
          [mysid]: [...(state.allItems[mysid] || []), res],
        },
      };
    }

    case ItemActionTypes.UPDATE_ITEM_SUCCESS: {
      const { res, mysid } = payload;
      const newArr = state.allItems[mysid].slice();
      const index = newArr.findIndex((item: Item) => item._id === res._id);
      newArr[index] = res;

      return {
        ...state,
        allItems: {
          ...state.allItems,
          [mysid]: [].concat(newArr),
        },
      };
    }

    case ItemActionTypes.DELETE_ITEM_SUCCESS: {
      const { res, mysid } = payload;
      return {
        ...state,
        allItems: {
          ...state.allItems,
          [mysid]: [
            ...(state.allItems[mysid] || []).filter(
              item => item._id !== res._id
            ),
          ],
        },
      };
    }

    case ItemActionTypes.GET_ITEMS_DEMO_SUCCESS: {
      const { res, mysid } = payload;
      return {
        ...state,
        allItems: {
          ...state.allItems,
          [mysid]: res,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const getItems = (state: State) => state.allItems;
export const getItemsMeta = (state: State) => state.itemsMeta;
