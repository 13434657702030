import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WebsocketEntTypes,
  WebSocketService,
  WSEntityManager,
} from '@qtek/core/websockets-core';
import {
  BackendResponse,
  Investor,
  ParticipantViews,
} from '@qtek/shared/models';
import { Observable } from 'rxjs';
import { HttpEntityManager } from './../http-entity-manager';

@Injectable({
  providedIn: 'root',
})
export class ParticipantService extends HttpEntityManager<Investor, Investor> {
  entityName: WebsocketEntTypes = 'par';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  constructor(
    protected http: HttpClient,
    private websocketService: WebSocketService
  ) {
    super();
  }

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }

  getInvestors(payload: any): void {
    this.wsManager.query({
      id: payload.id,
      view: payload._v,
      mysid: ParticipantViews.INVESTORS,
    });
  }

  createInvestor(payload: any): void {
    this.wsManager.create({ ...payload });
  }

  addInvestors(payload: any): void {
    this.wsManager.bulkUpdate({ res: payload });
  }

  updateInvestor(id: string, payload: any, queryId: string): void {
    this.wsManager.update({
      res: payload,
      id,
      myid: `${ParticipantViews.INVESTORS}/${queryId}`,
      mysid: ParticipantViews.INVESTORS,
    });
  }

  deleteInvestor(id: string, queryId: string): void {
    this.wsManager.delete({
      id,
      myid: `${ParticipantViews.INVESTORS}/${queryId}`,
      mysid: ParticipantViews.INVESTORS,
    });
  }

  resentInvitation(id: string) {
    return this.http.post(
      `/api/v1/service/snd/par/${id}?_snd=email`,
      undefined,
      {
        withCredentials: true,
      }
    );
  }

  getDealBook(payload: any): void {
    const { type, ...rest } = payload;
    this.wsManager.query({
      prms: {
        ...(payload?.p && { p: payload?.p }),
      },
      mysid: ParticipantViews.DEAL_BOOK,
      view: payload?.type || ParticipantViews.DEAL_BOOK,
    });
  }

  addDealBook(payload: any) {
    return this.http.post<BackendResponse>('/api/v1/service/invest', payload, {
      withCredentials: true,
    });
  }

  updateDealBook(id: string, payload: any): void {
    this.wsManager.update({
      res: payload,
      id,
      myid: ParticipantViews.DEAL_BOOK,
      mysid: ParticipantViews.DEAL_BOOK,
    });
  }

  deleteDealBook(id: string): void {
    this.wsManager.delete({
      id,
      myid: ParticipantViews.DEAL_BOOK,
      mysid: ParticipantViews.DEAL_BOOK,
    });
  }

  getInvest() {
    return this.http.get('/api/v1/service/invest', {
      withCredentials: true,
    });
  }

  requestInvest(payload: any) {
    return this.http.post<BackendResponse>('/api/v1/service/invest', payload, {
      withCredentials: true,
    });
  }

  getDealBookWS(payload: any): void {
    this.wsManager.query({
      q: payload.query,
      view: ParticipantViews.DEAL_BOOK,
      mysid: `${ParticipantViews.DEAL_BOOK}_${payload.viewId}`,
    });
  }

  createDealBookWS(payload: any): void {
    this.wsManager.create({
      res: payload.res,
      mysid: `${ParticipantViews.DEAL_BOOK}_${payload.viewId}`,
    });
  }

  updateDealBookWS(payload: any): void {
    this.wsManager.update({
      res: payload.res,
      id: payload.id,
      mysid: `${ParticipantViews.DEAL_BOOK}_${payload.viewId}`,
    });
  }

  deleteDealBookWS(payload: any): void {
    this.wsManager.delete({
      id: payload.res.id,
      mysid: `${ParticipantViews.DEAL_BOOK}_${payload.viewId}`,
    });
  }

  getInvestorsWS(payload: any): void {
    this.wsManager.query({
      q: payload.query,
      view: ParticipantViews.INVESTORS,
      mysid: `${ParticipantViews.INVESTORS}_${payload.viewId}`,
    });
  }

  createInvestorWS(payload: any): void {
    this.wsManager.create({
      res: payload.res,
      mysid: `${ParticipantViews.INVESTORS}_${payload.viewId}`,
    });
  }

  updateInvestorWS(payload: any): void {
    this.wsManager.update({
      res: payload.res,
      id: payload.id,
      mysid: `${ParticipantViews.INVESTORS}_${payload.viewId}`,
    });
  }

  deleteInvestorWS(payload: any): void {
    this.wsManager.delete({
      id: payload.id,
      mysid: `${ParticipantViews.INVESTORS}_${payload.viewId}`,
      myid: `${ParticipantViews.INVESTORS}/${payload.queryId}`,
    });
  }

  addInvestorsWS(payload: any): void {
    this.wsManager.bulkUpdate({
      res: payload.res,
      mysid: `${ParticipantViews.INVESTORS}_${payload.viewId}`,
      myid: `${ParticipantViews.INVESTORS}/${payload.queryId}`,
    });
  }

  getSalesPipelineWS(payload: any): void {
    this.wsManager.query({
      q: payload.query,
      view: ParticipantViews.INVESTORS,
      mysid: `${ParticipantViews.SALES_PIPELINE}_${payload.viewId}`,
    });
  }

  createSalesPipelineWS(payload: any): void {
    this.wsManager.create({
      res: payload.res,
      mysid: `${ParticipantViews.SALES_PIPELINE}_${payload.viewId}`,
    });
  }

  updateSalesPipelineWS(payload: any): void {
    this.wsManager.update({
      res: payload.res,
      id: payload.id,
      mysid: `${ParticipantViews.SALES_PIPELINE}_${payload.viewId}`,
    });
  }

  deleteSalesPipelineWS(payload: any): void {
    this.wsManager.delete({
      id: payload.res.id,
      mysid: `${ParticipantViews.SALES_PIPELINE}_${payload.viewId}`,
    });
  }
}
