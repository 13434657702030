/** DO NOT EDIT THIS FILE! */
import { Version } from '@angular/core';
import { Bundle } from '@qtek/shared/models';

export const VERSION = new Version('1.34.9');

export const BUNDLE: Bundle = {
  date: 1729264402537,
  commitSha: 'a7466617103ae7100ad87891dac0b02b83be6830',
  version: VERSION,
};
