import { Action } from '@ngrx/store';

import {
  Message,
  Club,
  ClubResponse,
  ClubMember,
  ClubRoles,
  EntityMeta,
} from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const ClubActionTypes = {
  CONNECT_CLUBS: type('[Club] Connect events'),
  DISCONNECT_CLUBS: type('[Club] Disconnect events'),
  GET_CLUBS: type('[Club] Get Clubs'),
  GET_CLUBS_SUCCESS: type('[Club] Get Clubs Success'),
  CREATE_CLUB: type('[Club] Create Club'),
  CREATE_CLUB_SUCCESS: type('[Club] Create Club Success'),
  CREATE_CLUB_FAILURE: type('[Club] Create Club Failure'),
  UPDATE_CLUB: type('[Club] Update Club'),
  UPDATE_CLUB_WS_SUCCESS: type('[Club] Update Club WS Success'),
  UPDATE_CLUB_SUCCESS: type('[Club] Update Club Success'),
  UPDATE_CLUB_FAILURE: type('[Club] Update Club Failure'),
  DELETE_CLUB: type('[Club] Delete Club'),
  DELETE_CLUB_SUCCESS: type('[Club] Delete Club Success'),

  CONNECT_CLUB_MEMBERS: type('[Club] Connect Club Members events'),
  DISCONNECT_CLUB_MEMBERS: type('[Club] Disconnect Club Members events'),
  GET_CLUB_MEMBERS: type('[Club] Get Club Members'),
  GET_CLUB_MEMBERS_SUCCESS: type('[Club] Get Club Members Success'),
  CREATE_CLUB_MEMBER: type('[Club] Create Club Member Club'),
  CREATE_CLUB_MEMBER_SUCCESS: type('[Club] Create Club Member Success'),
  UPDATE_CLUB_MEMBER: type('[Club] Update Club Member'),
  UPDATE_CLUB_MEMBER_SUCCESS: type('[Club] Update Club Member Success'),
  DELETE_CLUB_MEMBER: type('[Club] Delete Club Member'),
  DELETE_CLUB_MEMBER_SUCCESS: type('[Club] Delete Club Member Success'),
};

//   ---------------------- Club ----------------------
export class ConnectClubsAction implements Action {
  type = ClubActionTypes.CONNECT_CLUBS;

  constructor(public payload?: any) {}
}

export class DisconnectClubsAction implements Action {
  type = ClubActionTypes.DISCONNECT_CLUBS;

  constructor(public payload?: any) {}
}

export class GetClubsAction implements Action {
  type = ClubActionTypes.GET_CLUBS;

  constructor(public role?: ClubRoles) {}
}

export class GetClubsSuccessAction implements Action {
  type = ClubActionTypes.GET_CLUBS_SUCCESS;

  constructor(public payload: ClubResponse[], public meta?: EntityMeta) {}
}

export class CreateClubAction implements Action {
  type = ClubActionTypes.CREATE_CLUB;

  constructor(public payload: Club) {}
}

export class CreateClubSuccessAction implements Action {
  type = ClubActionTypes.CREATE_CLUB_SUCCESS;
  message = new Message('GNR_DEAL_CLUB_CREATED');

  constructor(public payload: ClubResponse) {}
}

export class CreateClubFailureAction implements Action {
  type = ClubActionTypes.CREATE_CLUB_FAILURE;

  constructor() {}
}

export class UpdateClubAction implements Action {
  type = ClubActionTypes.UPDATE_CLUB;

  constructor(public payload: Club) {}
}

export class UpdateClubWSSuccessAction implements Action {
  type = ClubActionTypes.UPDATE_CLUB_WS_SUCCESS;
  message = new Message('GNR_DEAL_CLUB_UPDATED');

  constructor(public payload: ClubResponse) {}
}

export class UpdateClubSuccessAction implements Action {
  type = ClubActionTypes.UPDATE_CLUB_SUCCESS;
  message = new Message('GNR_DEAL_CLUB_UPDATED');

  constructor(public payload: Club) {}
}

export class UpdateClubFailureAction implements Action {
  type = ClubActionTypes.UPDATE_CLUB_FAILURE;

  constructor() {}
}

export class DeleteClubAction implements Action {
  type = ClubActionTypes.DELETE_CLUB;

  constructor(public payload: string) {}
}

export class DeleteClubSuccessAction implements Action {
  type = ClubActionTypes.DELETE_CLUB_SUCCESS;
  message = new Message('GNR_DEAL_CLUB_DELETED');

  constructor(public payload: { clbId: string }) {}
}

//   ---------------------- Club Member ----------------------\

export class ConnectClubMembersAction implements Action {
  type = ClubActionTypes.CONNECT_CLUB_MEMBERS;

  constructor(public payload?: any) {}
}

export class DisconnectClubMembersAction implements Action {
  type = ClubActionTypes.DISCONNECT_CLUB_MEMBERS;

  constructor(public payload?: any) {}
}

export class GetClubMembersAction implements Action {
  type = ClubActionTypes.GET_CLUB_MEMBERS;

  constructor(public payload?: any) {}
}

export class GetClubMembersSuccessAction implements Action {
  type = ClubActionTypes.GET_CLUB_MEMBERS_SUCCESS;

  constructor(public payload: ClubMember[]) {}
}

export class CreateClubMemberAction implements Action {
  type = ClubActionTypes.CREATE_CLUB_MEMBER;

  constructor(public payload: ClubMember) {}
}

export class CreateClubMemberSuccessAction implements Action {
  type = ClubActionTypes.CREATE_CLUB_MEMBER_SUCCESS;
  message = new Message('GNR_CLBMBR_CREATED');

  constructor(public payload: ClubMember) {}
}

export class UpdateClubMemberAction implements Action {
  type = ClubActionTypes.UPDATE_CLUB_MEMBER;

  constructor(public payload: { id: string; res: any }) {}
}

export class UpdateClubMemberSuccessAction implements Action {
  type = ClubActionTypes.UPDATE_CLUB_MEMBER_SUCCESS;
  message = new Message('GNR_CLBMBR_UPDATED');

  constructor(public payload: ClubMember) {}
}

export class DeleteClubMemberAction implements Action {
  type = ClubActionTypes.DELETE_CLUB_MEMBER;

  constructor(public payload: string) {}
}

export class DeleteClubMemberSuccessAction implements Action {
  type = ClubActionTypes.DELETE_CLUB_MEMBER_SUCCESS;
  message = new Message('GNR_CLBMBR_DELETED');

  constructor(public payload: any) {}
}

export type ClubActions =
  | ConnectClubsAction
  | DisconnectClubsAction
  | GetClubsAction
  | GetClubsSuccessAction
  | CreateClubAction
  | CreateClubSuccessAction
  | CreateClubFailureAction
  | UpdateClubAction
  | UpdateClubSuccessAction
  | UpdateClubWSSuccessAction
  | UpdateClubFailureAction
  | DeleteClubAction
  | DeleteClubSuccessAction
  | ConnectClubMembersAction
  | DisconnectClubMembersAction
  | GetClubMembersAction
  | GetClubMembersSuccessAction
  | CreateClubMemberAction
  | CreateClubMemberSuccessAction
  | UpdateClubMemberAction
  | UpdateClubMemberSuccessAction
  | DeleteClubMemberAction
  | DeleteClubMemberSuccessAction;
