import { Routes } from '@angular/router';
import { DialogNames, LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { defaultDialogConfig } from './default-config';

export const bookingDialogRoutes: Routes = [
  {
    path: DialogNames.loginDialog,
    canActivate: [LazyDialogLoader],
    data: {
      dialogConfig: {
        ...defaultDialogConfig,
        width: '500px',
      },
    },
    loadComponent: () =>
      import('./standalone-dialogs/login-dialog/login-dialog.component').then(
        c => c.LoginDialogComponent
      ),
  },
];
