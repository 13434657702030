import {
  DocNode,
  DOCUMENT_UPLOAD_CREATED,
  DocumentType,
  PreUploadState,
} from '@qtek/shared/models';
import { DocumentActionTypes, DocumentsAction } from './documents.actions';

export interface State {
  companyLogo?: string;
  companyLogoDoc?: DocNode[];
  avatar?: string;
  entityDocuments?: DocNode[];
  dataRoomEntityDocuments?: DocNode[];
  invoiceAttachmentDocument?: DocNode[];
  estimateAttachmentDocument?: DocNode[];
  wcoAttachmentDocument?: DocNode[];
  creatorLogos?: string[];
  meetingDocuments?: DocNode[];
  personalDocuments?: DocNode[];
  bookingDocuments?: DocNode[];
  menuDocuments?: DocNode[];
  extrasDocuments?: DocNode[];
  bookingQr?: DocNode[];
  preUploadDocuments?: PreUploadState[];
}

const initialState: State = {
  companyLogo: '',
  companyLogoDoc: [],
  avatar: '',
  entityDocuments: [],
  dataRoomEntityDocuments: [],
  invoiceAttachmentDocument: [],
  estimateAttachmentDocument: [],
  wcoAttachmentDocument: [],
  creatorLogos: [],
  meetingDocuments: [],
  personalDocuments: [],
  bookingDocuments: [],
  menuDocuments: [],
  extrasDocuments: [],
  bookingQr: [],
  preUploadDocuments: [],
};

export function reducer(state = initialState, action: DocumentsAction): State {
  switch (action.type) {
    case DocumentActionTypes.GET_LOGOS_SUCCESS: {
      return {
        ...state,
        creatorLogos: action.payload || state.creatorLogos,
      };
    }

    case DocumentActionTypes.UPDATE_COMPANY_LOGO_SUCCESS: {
      return {
        ...state,
        companyLogo:
          action.payload && action.payload._id
            ? action.payload._id
            : state.companyLogo,
      };
    }

    case DocumentActionTypes.GET_ENTITY_DOCUMENTS_WS_SUCCESS: {
      return {
        ...state,
        dataRoomEntityDocuments: action.payload ? action.payload : [],
      };
    }

    case DocumentActionTypes.PREUPLOAD_DOCUMENT_SUCCESS: {
      const {
        res: { tkn, docIds, refId },
        file,
      } = action.payload;
      return {
        ...state,
        preUploadDocuments: state.preUploadDocuments.concat({
          tkn,
          docId: docIds[0],
          refId,
          status: DOCUMENT_UPLOAD_CREATED,
          filename: file.name,
          filesize: file.size,
        }),
      };
    }
    case DocumentActionTypes.PREUPLOAD_DOCUMENT_DELETE_ALL_BY_IDS: {
      return {
        ...state,
        preUploadDocuments: state.preUploadDocuments.filter(
          document => !action.payload.includes(document['docId'])
        ),
      };
    }

    case DocumentActionTypes.PREUPLOAD_DOCUMENT_DELETE_ALL_BY_REF: {
      return {
        ...state,
        preUploadDocuments: state.preUploadDocuments.filter(
          document => document['refId'] != action.payload
        ),
      };
    }

    case DocumentActionTypes.PREUPLOAD_DOCUMENT_UPDATE: {
      return {
        ...state,
        preUploadDocuments: state.preUploadDocuments.map(document =>
          document['tkn'] == action.payload.tkn
            ? {
                ...document,
                ...action?.payload?.toUpdate,
              }
            : document
        ),
      };
    }

    case DocumentActionTypes.UPLOAD_DOCUMENTS_WS_SUCCESS: {
      return {
        ...state,
        dataRoomEntityDocuments: state.dataRoomEntityDocuments.concat({
          ...action.payload,
        }),
      };
    }

    case DocumentActionTypes.UPDATE_DOCUMENT_WS_SUCCESS: {
      return {
        ...state,
        dataRoomEntityDocuments: state.dataRoomEntityDocuments.map(document =>
          document['_id'] == action.payload._id
            ? {
                ...action.payload,
              }
            : document
        ),
      };
    }

    case DocumentActionTypes.DELETE_DOCUMENT_WS_SUCCESS: {
      return {
        ...state,
        dataRoomEntityDocuments: state.dataRoomEntityDocuments.filter(
          (item: any) => item._id !== action.payload._id
        ),
        preUploadDocuments: state.preUploadDocuments.filter(
          item => item.docId !== action.payload._id
        ),
      };
    }

    // Folder start

    case DocumentActionTypes.CREATE_FOLDER_SUCCESS_WS: {
      return {
        ...state,
        dataRoomEntityDocuments: state.dataRoomEntityDocuments.concat(
          action.payload
        ),
      };
    }

    case DocumentActionTypes.UPDATE_FOLDER_SUCCESS_WS: {
      return {
        ...state,
        dataRoomEntityDocuments: state.dataRoomEntityDocuments.map(folder =>
          folder['_id'] == action.payload._id ? { ...action.payload } : folder
        ),
      };
    }

    case DocumentActionTypes.DELETE_FOLDER_SUCCESS_WS: {
      return {
        ...state,
        dataRoomEntityDocuments: state.dataRoomEntityDocuments.filter(
          (folder: any) => folder._id !== action.payload._id
        ),
      };
    }

    // Folder ends

    // case DocumentActionTypes.BULK_DELETE_SUCCESS: {
    //   console.log(action);
    //   return {
    //     ...state,
    //     dataRoomEntityDocuments: state.dataRoomEntityDocuments.filter(
    //       (folder: any) => !action.payload.includes(folder._id)
    //     ),
    //   };
    // }

    case DocumentActionTypes.DISCONNECT_DOCUMENTS_LIST: {
      return {
        ...state,
        dataRoomEntityDocuments: [],
      };
    }

    case DocumentActionTypes.UPDATE_PERSONAL_AVATAR_SUCCESS: {
      return {
        ...state,
        avatar:
          action.payload && action.payload._id
            ? action.payload._id
            : state.avatar,
      };
    }

    case DocumentActionTypes.DELETE_DOCUMENT_SUCCESS: {
      const documents = state.entityDocuments.filter(
        document => document._id !== action.payload.docId
      );

      switch (action.payload.attr) {
        case DocumentType.COMPANY_LOGO: {
          return {
            ...state,
            companyLogo: '',
            companyLogoDoc: state.companyLogoDoc.filter(
              document => document._id !== action.payload.docId
            ),
          };
        }
        case DocumentType.INVOICE_ATTACHMENT_DOCUMENT: {
          return {
            ...state,
            invoiceAttachmentDocument: state.invoiceAttachmentDocument.filter(
              document => document._id !== action.payload.docId
            ),
            entityDocuments: documents,
          };
        }

        case DocumentType.ESTIMATE_ATTACHMENT_DOCUMENT: {
          return {
            ...state,
            estimateAttachmentDocument: state.estimateAttachmentDocument.filter(
              document => document._id !== action.payload.docId
            ),
            entityDocuments: documents,
          };
        }

        case DocumentType.WCO_ATTACHMENT_DOCUMENT: {
          return {
            ...state,
            wcoAttachmentDocument: state.wcoAttachmentDocument.filter(
              document => document._id !== action.payload.docId
            ),
            entityDocuments: documents,
          };
        }

        case DocumentType.PERSONAL_AVATAR: {
          return {
            ...state,
            avatar: '',
          };
        }

        case DocumentType.MEETING_ATTACHMENT_DOCUMENT: {
          return {
            ...state,
            meetingDocuments: state.meetingDocuments.filter(
              document => document._id !== action.payload.docId
            ),
            entityDocuments: documents,
          };
        }

        case DocumentType.PERSONAL_DOCUMENTS: {
          return {
            ...state,
            personalDocuments: state.personalDocuments.filter(
              document => document._id !== action.payload.docId
            ),
            entityDocuments: documents,
          };
        }

        case DocumentType.COMPANY_BOOKING_BACKGROUND: {
          return {
            ...state,
            bookingDocuments: state.bookingDocuments.filter(
              document => document._id !== action.payload.docId
            ),
            entityDocuments: documents,
          };
        }

        case DocumentType.FOOD_MENU_ATTACHMENT_DOCUMENT: {
          return {
            ...state,
            menuDocuments: state.menuDocuments.filter(
              document => document._id !== action.payload.docId
            ),
            entityDocuments: documents,
          };
        }

        case DocumentType.SERVICE_IMAGE: {
          return {
            ...state,
            entityDocuments: documents,
          };
        }

        case DocumentType.SERVICE_EXTRA_IMAGE: {
          return {
            ...state,
            entityDocuments: documents,
            extrasDocuments: state.extrasDocuments.filter(
              document => document._id !== action.payload.docId
            ),
          };
        }

        case DocumentType.ITEM_DOCUMENT: {
          return {
            ...state,
            entityDocuments: state.entityDocuments.filter(
              document => document._id !== action.payload.docId
            ),
          };
        }

        default: {
          return state;
        }
      }
    }

    case DocumentActionTypes.UPLOAD_DOCUMENTS_SUCCESS: {
      if (Array.isArray(action.payload)) {
        switch (action.payload[0].attr) {
          case DocumentType.INVOICE_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              invoiceAttachmentDocument: state.invoiceAttachmentDocument.concat(
                action.payload
              ),
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.ESTIMATE_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              estimateAttachmentDocument:
                state.estimateAttachmentDocument.concat(action.payload),
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.WCO_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              wcoAttachmentDocument: state.wcoAttachmentDocument.concat(
                action.payload
              ),
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.COMPANY_LOGO: {
            return {
              ...state,
              companyLogo: action.payload[0]._id,
              companyLogoDoc: action.payload,
            };
          }

          case DocumentType.PERSONAL_AVATAR: {
            return {
              ...state,
              avatar: action.payload[0]._id,
            };
          }

          case DocumentType.MEETING_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              meetingDocuments: state.meetingDocuments.concat(action.payload),
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.PERSONAL_DOCUMENTS: {
            return {
              ...state,
              personalDocuments: state.personalDocuments.concat(action.payload),
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.COMPANY_BOOKING_BACKGROUND: {
            return {
              ...state,
              bookingDocuments: state.bookingDocuments.concat(action.payload),
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.FOOD_MENU_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              menuDocuments: state.menuDocuments.concat(action.payload),
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.SERVICE_IMAGE: {
            return {
              ...state,
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.SERVICE_EXTRA_IMAGE: {
            return {
              ...state,
              extrasDocuments: state.extrasDocuments.concat(action.payload),
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }

          case DocumentType.ITEM_DOCUMENT: {
            return {
              ...state,
              entityDocuments: state.entityDocuments.concat(action.payload),
            };
          }
          default: {
            return state;
          }
        }
      } else {
        return state;
      }
    }

    case DocumentActionTypes.GET_ENTITY_DOCUMENTS_SUCCESS: {
      if (Array.isArray(action.payload)) {
        switch (action.payload[0].attr) {
          case DocumentType.COMPANY_LOGO: {
            return {
              ...state,
              companyLogo: action.payload[0]._id,
              companyLogoDoc: action.payload,
              // entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.PERSONAL_AVATAR: {
            return {
              ...state,
              avatar: action.payload[0]._id,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.INVOICE_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              invoiceAttachmentDocument: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.ESTIMATE_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              estimateAttachmentDocument: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.WCO_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              wcoAttachmentDocument: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.MEETING_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              meetingDocuments: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.PERSONAL_DOCUMENTS: {
            return {
              ...state,
              personalDocuments: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.COMPANY_BOOKING_BACKGROUND: {
            return {
              ...state,
              bookingDocuments: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.COMPANY_BOOKING_QR: {
            return {
              ...state,
              bookingQr: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.FOOD_MENU_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              menuDocuments: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          case DocumentType.SERVICE_EXTRA_IMAGE: {
            return {
              ...state,
              extrasDocuments: action.payload,
              entityDocuments: action.payload ? action.payload : [],
            };
          }

          default: {
            return {
              ...state,
              entityDocuments: action.payload ? action.payload : [],
            };
          }
        }
      } else {
        return state;
      }
    }

    default: {
      return state;
    }
  }
}

export const getCompanyLogo = (state: State) => state.companyLogo;
export const getPersonalAvatar = (state: State) => state.avatar;
export const getCompanyLogoDocs = (state: State) => state.companyLogoDoc;
export const getCreatorLogos = (state: State) => state.creatorLogos;
export const getEntityDocuments = (state: State) => state.entityDocuments;
export const getDataRoomEntityDocuments = (state: State) =>
  state.dataRoomEntityDocuments;
export const getPersonalDocuments = (state: State) => state.personalDocuments;
export const getBookingDocuments = (state: State) => state.bookingDocuments;
export const getBookingQr = (state: State) => state.bookingQr;
export const getMenuDocuments = (state: State) => state.menuDocuments;
export const getInvoiceAttachments = (state: State) =>
  state.invoiceAttachmentDocument;
export const getExtrasDocuments = (state: State) => state.extrasDocuments;
export const getPreuploadDocuments = (state: State) => state.preUploadDocuments;
