import { ClubResponse, ClubMember, EntityMeta } from '@qtek/shared/models';
import { ClubActions, ClubActionTypes } from './club.actions';

export interface State {
  dealClubs: ClubResponse[];
  dealClubMeta: EntityMeta;
  dealClubMembers: ClubMember[];
}

const initialState: State = {
  dealClubs: [],
  dealClubMeta: new EntityMeta(),
  dealClubMembers: [],
};

export function reducer(state = initialState, action: ClubActions): State {
  switch (action.type) {
    case ClubActionTypes.GET_CLUBS_SUCCESS: {
      return {
        ...state,
        dealClubs: action.payload,
        dealClubMeta: action.meta || new EntityMeta(),
      };
    }
    case ClubActionTypes.CREATE_CLUB_SUCCESS: {
      return {
        ...state,
        dealClubs: [...state.dealClubs, action.payload],
      };
    }
    case ClubActionTypes.UPDATE_CLUB_WS_SUCCESS: {
      const index = state.dealClubs.findIndex(
        club => club.clb._id === action.payload.clb._id
      );
      const newClubs = state.dealClubs.slice();
      newClubs[index] = action.payload;
      return {
        ...state,
        dealClubs: newClubs,
      };
    }
    case ClubActionTypes.UPDATE_CLUB_SUCCESS: {
      const index = state.dealClubs.findIndex(
        club => club.clb._id === action.payload._id
      );
      const newClubs = state.dealClubs.slice();
      newClubs[index] = { ...newClubs[index], clb: action.payload };
      return {
        ...state,
        dealClubs: newClubs,
      };
    }
    case ClubActionTypes.DELETE_CLUB_SUCCESS: {
      return {
        ...state,
        dealClubs: state.dealClubs.filter(
          club => club.clb._id !== action.payload.clbId
        ),
      };
    }
    // Club Members

    case ClubActionTypes.DISCONNECT_CLUB_MEMBERS: {
      return {
        ...state,
        dealClubMembers: [],
      };
    }

    case ClubActionTypes.GET_CLUB_MEMBERS_SUCCESS: {
      return {
        ...state,
        dealClubMembers: action.payload,
      };
    }
    case ClubActionTypes.CREATE_CLUB_MEMBER_SUCCESS: {
      return {
        ...state,
        dealClubMembers: [...state.dealClubMembers, action.payload],
      };
    }
    case ClubActionTypes.UPDATE_CLUB_MEMBER_SUCCESS: {
      const index = state.dealClubMembers.findIndex(
        member => member.acnId === action.payload.acnId
      );
      const newMembers = state.dealClubMembers.slice();
      newMembers[index] = action.payload;
      return {
        ...state,
        dealClubMembers: newMembers,
      };
    }
    case ClubActionTypes.DELETE_CLUB_MEMBER_SUCCESS: {
      return {
        ...state,
        dealClubMembers: state.dealClubMembers.filter(
          member => member.acnId !== action.payload.acnId
        ),
      };
    }

    default: {
      return state;
    }
  }
}

export const getDealClubs = (state: State) => state.dealClubs;
export const getDealClubMeta = (state: State) => state.dealClubMeta;
export const getDealClubMembers = (state: State) => state.dealClubMembers;
