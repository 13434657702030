import { Action } from '@ngrx/store';
import { type } from '@qtek/shared/utils';
import { EntityMeta, Message } from '@qtek/shared/models';

export const DealActionTypes = {
  // entity - 'not'

  CONNECT_DEAL: type('[Deal] Connect Deal'),
  DISCONNECT_DEAL: type('[Deal] Disconnect Deal'),

  CREATE_DEAL: type('[Deal] Create Deal'),
  CREATE_DEAL_FAILURE: type('[Deal] Create Deal Failure'),
  CREATE_DEAL_SUCCESS: type('[Deal] Create Deal Success'),

  GET_DEALS: type('[Deal] Get Deals'),
  GET_DEALS_FAILURE: type('[Deal] Get Deals Failure'),
  GET_DEALS_SUCCESS: type('[Deal] Get Deals Success'),

  UPDATE_DEAL: type('[Deal] Update Deal'),
  UPDATE_DEAL_FAILURE: type('[Deal] Update Deal Failure'),
  UPDATE_DEAL_SUCCESS: type('[Deal] Update Deal Success'),

  DELETE_DEAL: type('[Deal] Delete Deal'),
  DELETE_DEAL_FAILURE: type('[Deal] Delete Deal Failure'),
  DELETE_DEAL_SUCCESS: type('[Deal] Delete Deal Success'),
};

export class ConnectDealsAction implements Action {
  type = DealActionTypes.CONNECT_DEAL;

  constructor(
    public payload: {
      mysid: string;
      ent?: string;
      prms?: object;
      view?: string;
      s?: string;
    }
  ) {}
}

export class DisconnectDealsAction implements Action {
  type = DealActionTypes.DISCONNECT_DEAL;

  constructor(public payload?: any) {}
}

export class CreateDealAction implements Action {
  type = DealActionTypes.CREATE_DEAL;

  constructor(public payload: { mysid: string; refId: string; deal: any }) {}
}

export class CreateDealSuccessAction implements Action {
  type = DealActionTypes.CREATE_DEAL_SUCCESS;
  message = new Message('FTR_DEAL_ADDED');

  constructor(public payload: { deal: any; mysid: string }) {}
}

export class CreateDealFailureAction implements Action {
  type = DealActionTypes.CREATE_DEAL_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateDealAction implements Action {
  type = DealActionTypes.UPDATE_DEAL;

  constructor(public payload: { mysid: string; deal: any }) {}
}

export class UpdateDealSuccessAction implements Action {
  type = DealActionTypes.UPDATE_DEAL_SUCCESS;
  message = new Message('FTR_DEAL_UPDATED');

  constructor(public payload: { deal: any; mysid: string }) {}
}

export class UpdateDealFailureAction implements Action {
  type = DealActionTypes.UPDATE_DEAL_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteDealAction implements Action {
  type = DealActionTypes.DELETE_DEAL;

  constructor(public payload: { id: string; mysid: string }) {}
}

export class DeleteDealSuccessAction implements Action {
  type = DealActionTypes.DELETE_DEAL_SUCCESS;
  message = new Message('FTR_DEAL_REMOVED');

  constructor(public payload: { id: string; mysid: string }) {}
}

export class DeleteDealFailureAction implements Action {
  type = DealActionTypes.DELETE_DEAL_FAILURE;

  constructor(public payload?: any) {}
}

export class GetDealAction implements Action {
  type = DealActionTypes.GET_DEALS;

  constructor(public payload: { id: string; ent: string; view: string }) {}
}

export class GetDealsSuccessAction implements Action {
  type = DealActionTypes.GET_DEALS_SUCCESS;

  constructor(
    public payload: { mysid: string; deals: any[]; meta: EntityMeta }
  ) {}
}

export class GetDealsFailureAction implements Action {
  type = DealActionTypes.GET_DEALS_FAILURE;

  constructor(public payload?: any) {}
}

export type DealsActions =
  // not
  | ConnectDealsAction
  | DisconnectDealsAction
  | CreateDealAction
  | CreateDealSuccessAction
  | CreateDealFailureAction
  | UpdateDealAction
  | UpdateDealSuccessAction
  | UpdateDealFailureAction
  | DeleteDealAction
  | DeleteDealSuccessAction
  | DeleteDealFailureAction
  | GetDealAction
  | GetDealsSuccessAction
  | GetDealsFailureAction;
