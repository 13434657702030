import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  AccountNameOutput,
  BackendResponse,
  BookingMeeting,
  BookingSuggestionsRequest,
  EventSuggestion,
  ExistingBookingLeadUser,
  LogoMeta,
  MyInvestment,
  NewBookingLeadUser,
  NftFrameModel,
  QtCalendarEvent,
  RequestAccess,
} from '@qtek/shared/models';
import { Observable } from 'rxjs';

export interface CompanyLogoOutput {
  lgo?: LogoMeta;
  lgoImg?: string;
  name?: AccountNameOutput;
}

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(protected http: HttpClient) {}

  checkId(webId: string) {
    let params = new HttpParams();
    params = params.append('webId', webId);
    return this.http.get<{ exist: boolean }>('/api/v1/model/acn', {
      withCredentials: true,
      params,
    });
  }

  sendLink(
    phoneNumber: string,
    language: any,
    type: string,
    subUsr?: string,
    qr?: boolean
  ) {
    let params = new HttpParams()
      .append('lng', language)
      .append(
        'dst',
        btoa(phoneNumber.startsWith('+') ? phoneNumber.slice(1) : phoneNumber)
      );

    if (subUsr) {
      params = params.append('subUsr', subUsr);
    }

    if (qr) {
      params = params.append('cg', 'bookingQR');
    }

    return this.http.post<BackendResponse>(
      `/snd/api/v1/${qr ? type ?? 'bookingQR' : 'webinvite'}`,
      undefined,
      {
        withCredentials: true,
        params,
      }
    );
  }

  getStaff(id: string) {
    const params = new HttpParams().append('meta', 'staff');

    return this.http.get<BackendResponse>(`/api/v1/service/book/${id}`, {
      params,
    });
  }

  getClubByWebId(payload: { id: string }) {
    return this.http.get<BackendResponse>(`/api/v1/service/clb/${payload.id}`, {
      withCredentials: true,
    });
  }

  createLeadUser(
    payload: NewBookingLeadUser | ExistingBookingLeadUser,
    webId: string,
    gmtOffset?: string,
    tzGuess?: string
  ) {
    let params = new HttpParams().append('webId', webId);

    if (gmtOffset) {
      params = params.append('gmtOffset', gmtOffset);
    }

    if (tzGuess) {
      params = params.append('tzGuess', tzGuess);
    }

    return this.http.put<BackendResponse>('/api/v1/service/lead', payload, {
      params,
    });
  }

  createLeadUserNew({
    leadUser,
    webId,
    gmtOffset,
    tzGuess,
  }: {
    leadUser: NewBookingLeadUser | ExistingBookingLeadUser;
    webId: string;
    gmtOffset?: string;
    tzGuess?: string;
  }) {
    let params = new HttpParams().append('webId', webId);

    if (gmtOffset) {
      params = params.append('gmtOffset', gmtOffset);
    }

    if (tzGuess) {
      params = params.append('tzGuess', tzGuess);
    }

    return this.http.put<BackendResponse>('/api/v1/service/lead', leadUser, {
      params,
    });
  }

  getSuggestions(
    id: string,
    payload: BookingSuggestionsRequest
  ): Observable<BackendResponse<{ mtgs: EventSuggestion[] }>> {
    return this.http.post<BackendResponse>(
      `/api/v1/service/book/${id}`,
      payload
    );
  }

  saveBooking(
    payload: BookingMeeting,
    webId: string
  ): Observable<BackendResponse<{ url: string }>> {
    return this.http.put<BackendResponse>(
      `/api/v1/service/mtg/${webId}`,
      payload
    );
  }

  saveRebooking(
    payload: BookingMeeting,
    mtgId: string
  ): Observable<BackendResponse<{ url: string }>> {
    return this.http.post<BackendResponse>(
      `/api/v1/service/mtg/${mtgId}`,
      payload
    );
  }

  getBookingMeeting(
    mtgId: string,
    rid: string
  ): Observable<BackendResponse<QtCalendarEvent>> {
    const params = new HttpParams().append('rid', rid);

    return this.http.get<BackendResponse>(`/api/v1/service/mtg/${mtgId}`, {
      params,
    });
  }

  getCompanyLogo(webId: string): Observable<CompanyLogoOutput> {
    const params = new HttpParams().append('_attrv', 'lgo');

    return this.http.get<CompanyLogoOutput>(
      `/api/v1/model/acn?webId=${webId}`,
      {
        params,
      }
    );
  }

  getNftFrames(): Observable<BackendResponse<NftFrameModel[]>> {
    return this.http.get<BackendResponse<NftFrameModel[]>>('/api/v1/e/agts', {
      withCredentials: true,
    });
  }

  invest({
    investment,
    webId,
  }: {
    investment: MyInvestment;
    webId: string;
  }): Observable<BackendResponse<MyInvestment>> {
    return this.http.put<BackendResponse<MyInvestment>>(
      `/api/v1/service/invest`,
      investment,
      {
        withCredentials: true,
      }
    );
  }

  requestAccess({
    webId,
    request,
  }: {
    webId: string;
    request: RequestAccess;
  }): Observable<BackendResponse> {
    return this.http.put<BackendResponse<MyInvestment>>(
      `/api/v1/service/invest/${webId}`,
      request,
      {
        withCredentials: true,
      }
    );
  }
}
