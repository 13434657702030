import { Platform } from '@angular/cdk/platform';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { Message } from '@qtek/shared/models';
import { EMPTY, Observable, fromEvent, merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnlineService {
  private change$: Observable<boolean>;

  constructor(
    private platform: Platform,
    private ngZone: NgZone,
    private dialogLoader: LazyDialogLoader,
    private matDialog: MatDialog
  ) {
    this.change$ = this.createChangeObservable();
  }

  /**
   * Returns stream of boolean values that represent online status of the application.
   *
   * @param startWirthActiveMode Whether or not start with current online mode.
   * @param fallback Fallback value for starting mode if platform is different then browser.
   */
  change(startWirthActiveMode?: boolean, fallback = true): Observable<boolean> {
    if (startWirthActiveMode) {
      /**
       * online and offline events don't emit immediately,
       * so we must provide starting value.
       */
      return this.change$.pipe(startWith(this.isOnline(fallback)));
    }

    return this.change$;
  }

  /**
   * Synchronous function which determines if applicaiton is in offline or online mode.
   *
   * @param fallback Fallback value if platform is different then browser.
   */
  isOnline(fallback = true): boolean {
    if (!this.platform.isBrowser) {
      return fallback;
    }

    return window.navigator.onLine;
  }

  /**
   * Create observable that emits when online or offline event occurs.
   */
  private createChangeObservable(): Observable<boolean> {
    if (!this.platform.isBrowser) {
      /**  Don't emit anything on platforms different then browser. */
      return EMPTY;
    }

    /**
     * We run these event handlers outside of angular, because we don't
     * want to trigger change detection unnecessarily.
     */
    return this.ngZone.runOutsideAngular(() => {
      const online$ = fromEvent(window, 'online').pipe(map(() => true));
      const offline$ = fromEvent(window, 'offline').pipe(map(() => false));

      return merge(online$, offline$);
    });
  }

  public notifyUser() {
    /**
     * Check if alert dialog is not displayed alredy.
     *
     * NOTE: is this enough? What about scenario when some other alert is displayed.
     * User will not get notified about offline state.
     */
    const alertDialog = this.matDialog.openDialogs.find(
      dialog =>
        dialog.componentInstance.constructor.name === 'AlertDialogComponent'
    );

    if (!alertDialog) {
      console.log('opens alerts');
      return this.dialogLoader
        .open('alert', {
          title: 'GNR_ERROR',
          message: new Message('ERR_OFFLINE'),
        })
        .subscribe();
    }

    return alertDialog;
  }
}
