import { type } from '@qtek/shared/utils';
import { Action } from '@ngrx/store';
import { Agent, Message } from '@qtek/shared/models';

export class AgentActionsTypes {
  static readonly CONNECT_AGENTS = type('[Agent] Connect Agents');
  static readonly DISCONNECT_AGENTS = type('[Agent] Disconnect Agents');

  static readonly GET_AGENTS = type('[Agent] Get Agents');
  static readonly GET_AGENTS_SUCCESS = type('[Agent] Get Agents success');
  static readonly GET_AGENTS_FAILURE = type('[Agent] Get Agents failure');

  static readonly UPDATE_AGENT = type('[Agent] Update Agent');
  static readonly UPDATE_AGENT_SUCCESS = type('[Agent] Update Agent success');
  static readonly UPDATE_AGENT_FAILURE = type('[Agent] Update Agent failure');

  static readonly DELETE_AGENT = type('[Agent] Delete Agent');
  static readonly DELETE_AGENT_SUCCESS = type('[Agent] Delete Agent success');
  static readonly DELETE_AGENT_FAILURE = type('[Agent] Delete Agent failure');

  static readonly CREATE_AGENT = type('[Agent] Create Agent');
  static readonly CREATE_AGENT_SUCCESS = type('[Agent] Create Agent success');
  static readonly CREATE_AGENT_FAILURE = type('[Agent] Create Agent failure');
}

export class ConnectAgentsAction implements Action {
  type = AgentActionsTypes.CONNECT_AGENTS;

  constructor(public payload?: any) {}
}

export class DisconnectAgentsAction implements Action {
  type = AgentActionsTypes.DISCONNECT_AGENTS;

  constructor(public payload?: any) {}
}

export class GetAgentsAction implements Action {
  readonly type = AgentActionsTypes.GET_AGENTS;

  constructor(public payload?: any) {}
}

export class GetAgentsActionSuccess implements Action {
  readonly type = AgentActionsTypes.GET_AGENTS_SUCCESS;

  constructor(public payload: Agent[]) {}
}

export class GetAgentsActionFailure implements Action {
  readonly type = AgentActionsTypes.GET_AGENTS_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateAgentAction implements Action {
  readonly type = AgentActionsTypes.UPDATE_AGENT;

  constructor(public payload: Agent, public print?: boolean) {}
}

export class UpdateAgentActionSuccess implements Action {
  readonly type = AgentActionsTypes.UPDATE_AGENT_SUCCESS;
  message = new Message('GNR_AGENT_UPDATED');

  constructor(public payload: Agent) {}
}

export class UpdateAgentActionFailure implements Action {
  readonly type = AgentActionsTypes.UPDATE_AGENT_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteAgentAction implements Action {
  readonly type = AgentActionsTypes.DELETE_AGENT;

  constructor(public payload: string) {}
}

export class DeleteAgentActionSuccess implements Action {
  readonly type = AgentActionsTypes.DELETE_AGENT_SUCCESS;
  message = new Message('GNR_AGENT_DELETED');

  constructor(public payload: Agent) {}
}

export class DeleteAgentActionFailure implements Action {
  readonly type = AgentActionsTypes.DELETE_AGENT_FAILURE;

  constructor(public payload?: any) {}
}

export class CreateAgentAction implements Action {
  readonly type = AgentActionsTypes.CREATE_AGENT;

  constructor(public payload: Agent, public print?: boolean) {}
}

export class CreateAgentActionSuccess implements Action {
  readonly type = AgentActionsTypes.CREATE_AGENT_SUCCESS;
  message = new Message('GNR_AGENT_CREATED');

  constructor(public payload: Agent) {}
}

export class CreateAgentActionFailure implements Action {
  readonly type = AgentActionsTypes.CREATE_AGENT_FAILURE;

  constructor(public payload?: any) {}
}

export type AgentsAction =
  | GetAgentsAction
  | GetAgentsActionSuccess
  | GetAgentsActionFailure
  | UpdateAgentAction
  | UpdateAgentActionSuccess
  | UpdateAgentActionFailure
  | DeleteAgentAction
  | DeleteAgentActionSuccess
  | DeleteAgentActionFailure
  | CreateAgentAction
  | CreateAgentActionSuccess
  | CreateAgentActionFailure;
