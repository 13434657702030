import { Injectable } from '@angular/core';

import {
  BaseWebsocketMessage,
  SubWebsocketMessageRequest,
  WSEntityManager,
  WebSocketService,
  WebsocketEntTypes,
} from '@qtek/core/websockets-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentMarkupService {
  entityName: WebsocketEntTypes = 'docmrp';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, false);
  }
  constructor(private websocketService: WebSocketService) {}

  createMarkup(payload: any) {
    this.wsManager.create({ ...payload });
  }

  updateMarkup(payload: any) {
    this.wsManager.update({ ...payload });
  }

  deleteMarkup(payload: any) {
    this.wsManager.delete({ prms: payload, id: undefined });
  }

  getMarkups(params?: any) {
    this.wsManager.query({ prms: params });
  }
}
