import { NgModule } from '@angular/core';
import { VERSION, environment } from '@env';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';
import {
  APP_ENVIRONMENT,
  APP_VERSION,
  ENVIRONMENT_APP_NAME,
  HTTP_BASE_URL,
} from '@qtek/core/api-core';
import { WEBSOCKET_BASE_URL } from '@qtek/core/websockets-core';
import { QtCoreModule } from '@qtek/libs/app-component';
import {
  APP_REDUCERS,
  AppState,
  BookingActions,
  CategoryActions,
  CoreActions,
  DocumentMarkupActions,
  DocumentsAction,
  ItemActions,
  ParticipantActions,
  ProductSchemasActions,
  UserActions,
} from '@qtek/libs/store';
import * as fromBooking from '@qtek/libs/store/booking/booking.reducer';
import * as fromCategory from '@qtek/libs/store/category/category.reducer';
import { COMMON_EFFECTS } from '@qtek/libs/store/common';
import * as fromCore from '@qtek/libs/store/core/core.reducer';
import * as fromDocumentMarkups from '@qtek/libs/store/document-markup/document-markup.reducer';
import * as fromDocuments from '@qtek/libs/store/documents/documents.reducer';
import {
  BookingAuthEffects,
  BookingEffects,
  CategoryEffects,
  CoreEffects,
  DocumentMarkupEffects,
  DocumentsEffects,
  ItemEffects,
  ParticipantsEffects,
  ProductSchemasEffects,
} from '@qtek/libs/store/effects';
import * as fromItems from '@qtek/libs/store/item/item.reducer';
import * as fromParticipants from '@qtek/libs/store/participants/participants.reducer';
import * as fromProductSchemas from '@qtek/libs/store/product-schemes/product-schemas.reducer';
import { DebugTranslationsDI } from '@qtek/libs/translation-core';

const ENVIRONMENT_APP_NAME_VALUE = environment.appName;
const APP_ENVIRONMENT_VALUE = environment;
const APP_VERSION_VALUE = VERSION;
const debugTranslation = environment.debugTranslations;

const rest = environment.rest;
export const HTTP_BASE_URL_VALUE =
  rest.protocol +
  '://' +
  rest.hostname +
  (rest.port ? ':' + rest.port : '') +
  (rest.path ? rest.path : '');

// WS
// export const WEBSOCKET_BASE_URL = new InjectionToken<string>('WEBSOCKET_BASE_URL');

const websocket = environment.websocket;

export const WEBSOCKET_BASE_URL_VALUE = websocket
  ? websocket.protocol +
    '://' +
    websocket.hostname +
    (websocket.port ? ':' + websocket.port : '') +
    (websocket.path ? websocket.path : '')
  : '';

const appReducers: Partial<ActionReducerMap<AppState, any>> = {
  booking: fromBooking.reducer,
  documents: fromDocuments.reducer,
  category: fromCategory.reducer,
  item: fromItems.reducer,
  productSchemas: fromProductSchemas.reducer,
  documentMarkups: fromDocumentMarkups.reducer,
  participants: fromParticipants.reducer,
  core: fromCore.reducer,
};

const appEffects = [
  BookingEffects,
  DocumentsEffects,
  CategoryEffects,
  BookingAuthEffects,
  ItemEffects,
  ProductSchemasEffects,
  DocumentMarkupEffects,
  ParticipantsEffects,
  CoreEffects,
];

@NgModule({
  imports: [
    QtCoreModule,
    EffectsModule.forRoot([...COMMON_EFFECTS, ...appEffects]),
  ],
  exports: [QtCoreModule],
  providers: [
    {
      provide: HTTP_BASE_URL,
      useValue: HTTP_BASE_URL_VALUE,
    },
    {
      provide: WEBSOCKET_BASE_URL,
      useValue: WEBSOCKET_BASE_URL_VALUE,
    },
    {
      provide: APP_REDUCERS,
      useValue: appReducers,
    },
    {
      provide: ENVIRONMENT_APP_NAME,
      useValue: ENVIRONMENT_APP_NAME_VALUE,
    },
    {
      provide: APP_ENVIRONMENT,
      useValue: APP_ENVIRONMENT_VALUE,
    },
    {
      provide: APP_VERSION,
      useValue: APP_VERSION_VALUE,
    },
    {
      provide: DebugTranslationsDI,
      useValue: debugTranslation ?? false,
    },
  ],
})
export class CoreModule {}
