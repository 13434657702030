import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { WebId } from '@qtek/shared/models';
import { Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AppState, getBookingClub } from '../../';
import {
  BookingActionTypes,
  CreateClubItemSuccessAction,
  DeleteClubItemSuccessAction,
  GetClubItemsSuccessAction,
  InitBookingAction,
  InitBookingFailureAction,
  InitBookingSuccessAction,
  InvestAction,
  InvestActionFailure,
  InvestActionSuccess,
  SaveBookingItemAction,
  SaveBookingItemFailureAction,
  SaveBookingItemSuccessAction,
  SendBookingAction,
  SendBookingFailureAction,
  SendBookingQrCodeAction,
  SendBookingQrCodeFailureAction,
  SendBookingQrCodeSuccessAction,
  SendBookingSuccessAction,
  UpdateClubItemSuccessAction,
  WarningBookingAction,
} from './booking.actions';
import { BookingService } from './booking.service';
import { ClubItemsService } from './club-items.service';

@Injectable()
export class BookingEffects {
  public constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private bookingService: BookingService,
    private clubItemsService: ClubItemsService
  ) {}

  connectClub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActionTypes.SUBSCRIBE_CLUB),
      mergeMap(() => this.store.select(getBookingClub)),
      switchMap(({ _id }) =>
        this.clubItemsService
          .subscribe({ id: _id, prms: { _v: 'marketplace', clubId: _id } })
          .pipe(
            takeUntil(
              this.actions$.pipe(ofType(BookingActionTypes.DISCONNECT_CLUB))
            )
          )
      ),
      map((data: any) => {
        switch (data.op) {
          case 'query':
            return new GetClubItemsSuccessAction(data.res || []);
          case 'ins':
            return new CreateClubItemSuccessAction(data.res);
          case 'upd':
            return new UpdateClubItemSuccessAction(data.res);
          case 'del':
            return new DeleteClubItemSuccessAction(data.res);
          default:
            return { type: 'NOT_EXIST' };
        }
      })
    )
  );

  public getClubItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingActionTypes.GET_CLUB_ITEMS),
        switchMap(() => this.store.select(getBookingClub)),
        tap(({ _id }) => {
          this.clubItemsService.getClubItems({
            id: _id,
            prms: { _v: 'marketplace' },
          });
        })
      ),
    { dispatch: false }
  );

  public sendBooking$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SendBookingAction>(BookingActionTypes.SEND_BOOKING),
      switchMap(({ payload }) =>
        this.bookingService
          .sendLink(payload.destination, payload.language, payload.subUsr)
          .pipe(
            map(({ res }) => new SendBookingSuccessAction(res)),
            catchError(({ sts }) => of(new SendBookingFailureAction(sts)))
          )
      )
    )
  );

  public sendBookingQrCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SendBookingQrCodeAction>(BookingActionTypes.SEND_BOOKING_QR),
      switchMap(({ payload }) =>
        this.bookingService
          .sendLink(payload.destination, payload.language, null, null, true)
          .pipe(
            map(({ res }) => new SendBookingQrCodeSuccessAction(res)),
            catchError(({ sts }) => of(new SendBookingQrCodeFailureAction(sts)))
          )
      )
    )
  );

  public initBooking$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<InitBookingAction>(BookingActionTypes.INIT_BOOKING),
      switchMap(({ payload }) =>
        this.bookingService.getClubByWebId(payload).pipe(
          map(response => {
            if (response.sts && response.sts.sts === 'WARN') {
              return new WarningBookingAction(response.sts);
            } else if (response.res) {
              return new InitBookingSuccessAction(response.res as WebId);
            } else {
              return new InitBookingFailureAction();
            }
          }),
          catchError(({ sts }) => {
            return of(new InitBookingFailureAction(sts));
          })
        )
      )
    )
  );
  public saveItem$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveBookingItemAction>(BookingActionTypes.SAVE_BOOKING_ITEM),
      switchMap(({ payload }) =>
        this.bookingService.getSuggestions(payload.id, payload.data).pipe(
          map(
            ({ res }) =>
              new SaveBookingItemSuccessAction(res ? res.mtgs : undefined)
          ),
          catchError(({ sts }) => of(new SaveBookingItemFailureAction(sts)))
        )
      )
    )
  );

  public invest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<InvestAction>(BookingActionTypes.INVEST),
      concatMap(({ payload }) =>
        this.bookingService.invest(payload).pipe(
          map(({ res }) => new InvestActionSuccess(res)),
          catchError(({ sts }) => of(new InvestActionFailure(sts)))
        )
      )
    )
  );
}
