import { Inject, InjectionToken } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap } from '@ngrx/store';
import { createSelector } from 'reselect';

import { getScopesFromMenuConfigByUrl } from '@qtek/shared/utils';

import * as fromAgency from './agency/agency.reducer';
import * as fromAgents from './agents/agents.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromBooking from './booking/booking.reducer';
import * as fromCalendar from './calendar/calendar.reducer';
import * as fromCategory from './category/category.reducer';
import * as FromClubs from './club/club.reducer';
import * as fromCore from './core/core.reducer';
import * as fromDealTeams from './deal-team/deal-team.reducer';
import * as fromDocumentActivity from './document-activity/document-activity.reducer';
import * as fromDocumentMarkups from './document-markup/document-markup.reducer';
import * as fromDocuments from './documents/documents.reducer';
import * as fromIntegrations from './integrations/integrations.reducer';
import * as fromItem from './item/item.reducer';
import * as fromMenu from './menu/menu.reducer';
import * as fromMeta from './meta/meta.reducer';
import * as fromNcino from './ncino/ncino.reducer';
import * as fromNotes from './note/note.reducer';
import * as fromParticipants from './participants/participants.reducer';
import * as fromPaymentPlan from './payment-plan/payment-plan.reducer';
import * as fromProductSchemas from './product-schemes/product-schemas.reducer';
import * as fromRelations from './relations/relations.reducer';
import * as fromTransactions from './transaction/transaction.reducer';
import * as fromUploadCenter from './upload-center/upload-center.reducer';
import * as fromUser from './user/user.reducer';
import * as fromWebDomains from './webdomains/webdomains.reducer';
import * as fromWizard from './wizard/wizard.reducer';
import * as fromDeals from './deals/deals.reducer';

export * from './meta-reducers';

export interface AppState {
  auth: fromAuth.State;
  user: fromUser.State;
  menu: fromMenu.State;
  meta: fromMeta.State;
  core: fromCore.State;
  relations: fromRelations.State;
  documents: fromDocuments.State;
  category: fromCategory.State;
  item: fromItem.State;
  wizard: fromWizard.State;
  calendar: fromCalendar.State;
  booking: fromBooking.State;
  integrations: fromIntegrations.State;
  paymentPlan: fromPaymentPlan.State;
  agents: fromAgents.State;
  clubs: FromClubs.State;
  productSchemas: fromProductSchemas.State;
  documentMarkups: fromDocumentMarkups.State;
  participants: fromParticipants.State;
  ncino: fromNcino.State;
  notes: fromNotes.NoteState;
  webDomains: fromWebDomains.State;
  dealTeams: fromDealTeams.DealTeamState;
  documentActivity: fromDocumentActivity.State;
  agency: fromAgency.State;
  transaction: fromTransactions.State;
  uploadCenter: fromUploadCenter.State;
  deals: fromDeals.DealsState;
}

export type BookingAppState = Pick<
    AppState,
    'booking' | 'auth' | 'user' | 'meta' | 'productSchemas'
  >

/**
 * Reducers used in every application.
 */
export const COMMON_REDUCERS: ActionReducerMap<Partial<AppState>> = {
  auth: fromAuth.reducer as ActionReducer<any, Action>,
  user: fromUser.reducer as ActionReducer<any, Action>,
  meta: fromMeta.reducer as ActionReducer<any, Action>,
  relations: fromRelations.reducer as ActionReducer<any, Action>,
  webDomains: fromWebDomains.reducer as ActionReducer<any, Action>,
};

/**
 * Combine default and application specyfic reducers. Use {@link APP_REDUCERS} token
 * to add reducers.
 */
export function getReducers(appReducers: Partial<ActionReducerMap<AppState>>) {
  return {
    ...COMMON_REDUCERS,
    ...appReducers,
  };
}

/**
 * Represent main reducer.
 */
export const REDUCERS = new InjectionToken<ActionReducerMap<Partial<AppState>>>(
  'REDUCERS'
);

/**
 * Application specific reducers.
 */
export const APP_REDUCERS = new InjectionToken<
  ActionReducerMap<Partial<AppState>>
>('APP_REDUCERS');

/**
 * Sets up base store providers.
 */
export const STORE_PROVIDERS = [
  {
    provide: APP_REDUCERS,
    useValue: {},
  },
  {
    provide: REDUCERS,
    useFactory: getReducers,
    deps: [[new Inject(APP_REDUCERS)]],
  },
];

/**
 * Selectors
 */

export const getAuthState = (state: AppState) => state.auth;
export const getWsToken = createSelector(getAuthState, fromAuth.getWsToken);

// Menu
export const getMenuState = (state: AppState) => state.menu;
export const getMainMenu = createSelector(getMenuState, fromMenu.getMainMenu);
export const getPageScopesFromMenu = (url: string) =>
  createSelector(getMainMenu, menu => getScopesFromMenuConfigByUrl(menu, url));
export const getWizardMenu = createSelector(
  getMenuState,
  fromMenu.getWizardMenu
);
export const getSetupMenu = createSelector(getMenuState, fromMenu.getSetupMenu);
export const getDashboardMenu = createSelector(
  getMenuState,
  fromMenu.getDashboardMenu
);
export const getWebsocketDatasets = createSelector(
  getMenuState,
  fromMenu.getWebsocketDatasets
);
export const getWebsocketDataset = (id: string) =>
  createSelector(getWebsocketDatasets, datasets => {
    return datasets[id];
  });
export const getWizardStepMenu = createSelector(
  getMenuState,
  fromMenu.getWizardStepMenu
);

// User
export const getUserState = (state: AppState) => state.user;
export const isAuthorized = createSelector(getUserState, fromUser.isAuthorized);
export const getCurrentPerson = createSelector(
  getUserState,
  fromUser.getCurrentPerson
);
export const getUserRelation = createSelector(
  getUserState,
  fromUser.getUserRelation
);
export const getMainCompany = createSelector(
  getUserState,
  fromUser.getMainCompany
);
export const getPersonProgress = createSelector(
  getUserState,
  fromUser.getPersonProgress
);
export const getDemoDataProgress = createSelector(
  getUserState,
  fromUser.getDemoDataProgress
);
export const getCompanyProgress = createSelector(
  getUserState,
  fromUser.getCompanyProgress
);
export const getTutorialProgress = createSelector(
  getUserState,
  fromUser.getTutorialProgress
);
export const getCurrentPersonLanguage = createSelector(
  getUserState,
  fromUser.getCurrentPersonLanguage
);
export const getCurrentPersonTz = createSelector(
  getUserState,
  fromUser.getCurrentPersonTz
);

export const getRelationType = createSelector(
  getUserState,
  fromUser.getRelationType
);
export const getUserCompanies = createSelector(
  getUserState,
  fromUser.getUserCompanies
);
export const isPersonalAccount = createSelector(
  getUserState,
  fromUser.isPersonalAccount
);

export const getUserActions = createSelector(
  getUserState,
  fromUser.getUserActions
);

// Meta
export const getMetaState = (state: AppState | BookingAppState) => state.meta;
export const getUnits = createSelector(getMetaState, fromMeta.getUnits);
export const getFrequencies = createSelector(
  getMetaState,
  fromMeta.getFrequencies
);
export const getUserRoles = createSelector(getMetaState, fromMeta.getUserRoles);
export const getCustomerRoles = createSelector(
  getMetaState,
  fromMeta.getCustomerRoles
);
export const getStaffRoles = createSelector(
  getMetaState,
  fromMeta.getStaffRoles
);
export const getVendorRoles = createSelector(
  getMetaState,
  fromMeta.getVendorRoles
);
export const getLanguages = createSelector(getMetaState, fromMeta.getLanguages);
export const getTitle = createSelector(getMetaState, fromMeta.getTitle);
export const getMobileTitle = createSelector(
  getMetaState,
  fromMeta.getMobileTitle
);
export const getCountryCode = createSelector(
  getMetaState,
  fromMeta.getCountryCode
);
export const getGuiDomain = createSelector(getMetaState, fromMeta.getGuiDomain);
export const getGuiOnlineBookDomain = createSelector(
  getMetaState,
  fromMeta.getGuiOnlineBookDomain
);
export const getGuiShrDomain = createSelector(
  getMetaState,
  fromMeta.getGuiShrDomain
);
export const getRoles = createSelector(getMetaState, fromMeta.getRoles);
export const getTimezones = createSelector(getMetaState, fromMeta.getTimezones);
export const getWsPingPong = createSelector(
  getMetaState,
  fromMeta.getWsPingPong
);
export const getCurrencies = createSelector(
  getMetaState,
  fromMeta.getCurrencies
);
export const getLicenceCmpId = createSelector(
  getMetaState,
  fromMeta.getLicenceCmpId
);
export const getLicenceWebId = createSelector(
  getMetaState,
  fromMeta.getLicenceWebId
);
export const getCountries = createSelector(getMetaState, fromMeta.getCountries);
export const getCountryInfo = createSelector(
  getMetaState,
  fromMeta.getCountryInfo
);
export const getFoodTypes = createSelector(getMetaState, fromMeta.getFoodTypes);
export const getStates = createSelector(getMetaState, fromMeta.getStates);
export const getToken = createSelector(getMetaState, fromMeta.getToken);
export const getUiTheme = createSelector(getMetaState, fromMeta.getUiTheme);
export const getPSMeta = createSelector(
  getMetaState,
  fromMeta.getPowerSearchMeta
);
export const getPSMetaByMID = (mid: string) =>
  createSelector(getPSMeta, psMeta => {
    return psMeta[mid];
  });

// Relations
export const getRelationsState = (state: AppState) => state.relations;
export const getCustomers = createSelector(
  getRelationsState,
  fromRelations.selectCustomers
);
export const getStaff = createSelector(
  getRelationsState,
  fromRelations.selectStaff
);
export const getVendors = createSelector(
  getRelationsState,
  fromRelations.selectVendors
);

// Core
export const getCoreState = (state: AppState) => state.core;
export const getLoadingDisplay = createSelector(
  getCoreState,
  fromCore.getLoadingDisplay
);
export const getLoadingAnimation = createSelector(
  getCoreState,
  fromCore.getLoadingAnimation
);

// Web Domains
export const getWebDomainsState = (state: AppState) => state.webDomains;
export const getWebDomains = createSelector(
  getWebDomainsState,
  fromWebDomains.getWebDomains
);

// Documents
export const getDocumentState = (state: AppState) => state.documents;
export const getCompanyLogo = createSelector(
  getDocumentState,
  fromDocuments.getCompanyLogo
);
export const getCompanyLogoDocs = createSelector(
  getDocumentState,
  fromDocuments.getCompanyLogoDocs
);
export const getPersonalAvatar = createSelector(
  getDocumentState,
  fromDocuments.getPersonalAvatar
);
export const getCreatorLogos = createSelector(
  getDocumentState,
  fromDocuments.getCreatorLogos
);
export const getInvoiceAttachments = createSelector(
  getDocumentState,
  fromDocuments.getInvoiceAttachments
);
export const getPersonalDocuments = createSelector(
  getDocumentState,
  fromDocuments.getPersonalDocuments
);
export const getEntityDocuments = createSelector(
  getDocumentState,
  fromDocuments.getEntityDocuments
);
export const getDataRoomEntityDocuments = createSelector(
  getDocumentState,
  fromDocuments.getDataRoomEntityDocuments
);
export const getBookingDocuments = createSelector(
  getDocumentState,
  fromDocuments.getBookingDocuments
);
export const getBookingQr = createSelector(
  getDocumentState,
  fromDocuments.getBookingQr
);
export const getFoodMenuDocuments = createSelector(
  getDocumentState,
  fromDocuments.getMenuDocuments
);
export const getExtrasDocuments = createSelector(
  getDocumentState,
  fromDocuments.getExtrasDocuments
);
export const getPreuploadDocuments = createSelector(
  getDocumentState,
  fromDocuments.getPreuploadDocuments
);

// Categories
export const getCategoryState = (state: AppState) => state.category;
export const getCategories = createSelector(
  getCategoryState,
  fromCategory.getCategories
);

// Item
export const getItemState = (state: AppState) => state.item;
export const getItems = createSelector(getItemState, fromItem.getItems);
export const getItemsByMysid = (mysid: string) =>
  createSelector(getItems, items => {
    return items[mysid] || [];
  });
export const getItemsMeta = createSelector(getItemState, fromItem.getItemsMeta);

// Note
export const getNoteState = (state: AppState) => state.notes;
export const getNotes = createSelector(getNoteState, fromNotes.getNotes);
export const getItemNotes = (refId: string) =>
  createSelector(getNotes, notes => {
    return notes[refId];
  });
export const getNotesMeta = createSelector(
  getNoteState,
  fromNotes.getNotesMeta
);

export const getDealTeamState = (state: AppState) => state.dealTeams;
export const getDealTeams = createSelector(
  getDealTeamState,
  fromDealTeams.getDealTeams
);
export const getItemDealTeams = (refId: string) =>
  createSelector(getDealTeams, dealTeams => dealTeams[refId]);

export const getDealsState = (state: AppState) => state.deals;
export const getDeals = createSelector(getDealsState, fromDeals.getDeals);
export const getDealsMeta = createSelector(
  getDealsState,
  fromDeals.getDealsMeta
);

// Wizard
export const getWizardState = (state: AppState) => state.wizard;
export const getIsWizardDone = createSelector(
  getWizardState,
  fromWizard.getIsWizardDone
);
export const getSteps = createSelector(getWizardState, fromWizard.getSteps);
export const getDemoSteps = createSelector(
  getWizardState,
  fromWizard.getDemoSteps
);
export const getMaxPersonProgress = createSelector(
  getWizardState,
  fromWizard.getMaxPersonProgress
);
export const getMaxCompanyProgress = createSelector(
  getWizardState,
  fromWizard.getMaxCompanyProgress
);
export const getMaxDemoDataProgress = createSelector(
  getWizardState,
  fromWizard.getMaxDemoDataProgress
);
export const isWizardLoading = createSelector(
  getWizardState,
  fromWizard.isWizardLoading
);

/* tslint:disable: no-bitwise */
const isPersonDone = createSelector(
  getPersonProgress,
  getMaxPersonProgress,
  (personProgress, maxPersonProgress) =>
    (personProgress & maxPersonProgress) === maxPersonProgress
);
const isCompanyDone = createSelector(
  getCompanyProgress,
  getMaxCompanyProgress,
  (companyProgress, maxCompanyProgress) =>
    (companyProgress & maxCompanyProgress) === maxCompanyProgress
);
export const isDemoDataDone = createSelector(
  getDemoDataProgress,
  getMaxDemoDataProgress,
  (demoDataProgress, maxDemoDataProgress) =>
    (demoDataProgress & maxDemoDataProgress) === maxDemoDataProgress
);
/* tslint:enable: no-bitwise */
export const isWizardDone = createSelector(
  isPersonDone,
  isCompanyDone,
  (person, company) => company && person
);

// Calendar
export const getCalendarState = (state: AppState) => state.calendar;
export const getEvents = createSelector(
  getCalendarState,
  fromCalendar.getEvents
);
export const getCurrentEvent = createSelector(
  getCalendarState,
  fromCalendar.getCurrentEvent
);

// Document markups
export const getDocumentMarkupState = (state: AppState) =>
  state.documentMarkups;
export const getAllDocumentMarkups = createSelector(
  getDocumentMarkupState,
  fromDocumentMarkups.getDocumentMarkups
);

// Booking
export const getBookingState = (state: AppState | BookingAppState) =>
  state.booking;
export const getBookingOwner = createSelector(
  getBookingState,
  fromBooking.getBookingOwner
);
export const getBookingOwnerName = createSelector(
  getBookingState,
  fromBooking.getBookingOwnerName
);
export const getBookingOwnerAvatarImage = createSelector(
  getBookingState,
  fromBooking.getBookingOwnerAvatarImage
);
export const getBookingOwnerBackgroundImage = createSelector(
  getBookingState,
  fromBooking.getBookingOwnerBackgroundImage
);
export const getBookingOwnerPhone = createSelector(
  getBookingState,
  fromBooking.getBookingOwnerPhone
);
export const getBookingOwnerPic = createSelector(
  getBookingState,
  fromBooking.getBookingOwnerPic
);
export const getBookingItems = createSelector(
  getBookingState,
  fromBooking.getBookingItems
);
export const getBookingCart = createSelector(
  getBookingState,
  fromBooking.getBookingCart
);
export const getLead = createSelector(getBookingState, fromBooking.getLead);
export const getBookingClub = createSelector(
  getBookingState,
  fromBooking.getClub
);

export const getCompanyInfo = createSelector(
  getBookingState,
  fromBooking.getCompanyInfo
);
export const getLocation = createSelector(
  getBookingState,
  fromBooking.getLocation
);
export const getItemType = createSelector(
  getBookingState,
  fromBooking.getItemType
);
export const getDeliveryTime = createSelector(
  getBookingState,
  fromBooking.getDeliveryTime
);
export const getBookingUser = createSelector(
  getBookingState,
  fromBooking.getUserData
);

// Integrations
export const getIntegrationsState = (state: AppState) => state.integrations;
export const getAvailableCalendars = createSelector(
  getIntegrationsState,
  fromIntegrations.getAvailableCalendars
);
export const getGoogleCalendar = createSelector(
  getIntegrationsState,
  fromIntegrations.getGoogleCalendar
);
export const getAppleCalendar = createSelector(
  getIntegrationsState,
  fromIntegrations.getAppleCalendar
);
export const getMicrosoft = createSelector(
  getIntegrationsState,
  fromIntegrations.getMicrosoft
);
export const getDebtX = createSelector(
  getIntegrationsState,
  fromIntegrations.getDebtX
);
export const getClover = createSelector(
  getIntegrationsState,
  fromIntegrations.getClover
);
export const getSalesforce = createSelector(
  getIntegrationsState,
  fromIntegrations.getSalesforce
);

// Payment plan
export const getPaymentPlanState = (state: AppState) => state.paymentPlan;
export const getPaymentPlans = createSelector(
  getPaymentPlanState,
  fromPaymentPlan.getPlans
);
export const getActivePlan = createSelector(
  getPaymentPlanState,
  fromPaymentPlan.getActivePlan
);

// Agents
export const getAgentsState = (state: AppState) => state.agents;
export const getAgents = createSelector(getAgentsState, fromAgents.getAgents);

// Clubs
export const getDealClubsState = (state: AppState) => state.clubs;
export const getDealClubs = createSelector(
  getDealClubsState,
  FromClubs.getDealClubs
);
export const getDealClubMeta = createSelector(
  getDealClubsState,
  FromClubs.getDealClubMeta
);
export const getDealClubMembers = createSelector(
  getDealClubsState,
  FromClubs.getDealClubMembers
);

// ProductSchemas
export const getProductSchemasState = (state: AppState | BookingAppState) =>
  state.productSchemas;
export const getProductSchemaViews = createSelector(
  getProductSchemasState,
  fromProductSchemas.getSchemaViews
);
export const getSchemaSids = createSelector(
  getProductSchemasState,
  fromProductSchemas.getSchemasSids
);
export const getProductSchemasMeta = createSelector(
  getProductSchemasState,
  fromProductSchemas.getSchemasMeta
);

// Participants
export const getParticipantsState = (state: AppState) => state.participants;
export const getInvestors = createSelector(
  getParticipantsState,
  fromParticipants.getInvestors
);
export const getDealBook = createSelector(
  getParticipantsState,
  fromParticipants.getDealBook
);
export const getInvests = createSelector(
  getParticipantsState,
  fromParticipants.getInvests
);
export const getInvitations = createSelector(
  getParticipantsState,
  fromParticipants.getInvitations
);
export const getInvitationsMeta = createSelector(
  getParticipantsState,
  fromParticipants.getInvitationsMeta
);
export const getDealBookMeta = createSelector(
  getParticipantsState,
  fromParticipants.getDealBookMeta
);

// Ncino
export const getNcinoState = (state: AppState) => state.ncino;
export const getNcino = createSelector(getNcinoState, fromNcino.getNcino);

// Document Activity
export const getDocumentActivityState = (state: AppState) =>
  state.documentActivity;
export const getDocumentActivity = createSelector(
  getDocumentActivityState,
  fromDocumentActivity.getDocumentActivity
);

// Agancies
export const getAgencyState = (state: AppState) => {
  return state.agency;
};
export const getAgency = createSelector(getAgencyState, fromAgency.getAgency);
export const getAgencyMeta = createSelector(
  getAgencyState,
  fromAgency.getAgencyMeta
);

// Transactions
export const getTransactionState = (state: AppState) => {
  return state.transaction;
};
export const getTransactions = createSelector(
  getTransactionState,
  fromTransactions.getTransactions
);
export const getTransactionsById = (mysid: string) =>
  createSelector(getTransactions, transactiions => {
    return transactiions[mysid] || [];
  });

// Upload Center

export const getUploadCenterState = (state: AppState) => {
  return state.uploadCenter;
};
export const getUploadCenterDocuments = createSelector(
  getUploadCenterState,
  fromUploadCenter.getUploadCenterDocuments
);
export const getUploadCenterDocumentsById = (mysid: string) =>
  createSelector(getUploadCenterDocuments, documents => {
    return documents[mysid] || [];
  });
export const getUploadCenterETLMap = createSelector(
  getUploadCenterState,
  fromUploadCenter.getUploadCenterETLMap
);
