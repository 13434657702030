import { CalendarActions, CalendarActionTypes } from './calendar.actions';
import { QtCalendarEvent } from '@qtek/shared/models';

export interface State {
  events: QtCalendarEvent[];
  event: QtCalendarEvent;
}

const initialState: State = {
  events: [],
  event: undefined,
};

export function reducer(state = initialState, action: CalendarActions): State {
  switch (action.type) {
    case CalendarActionTypes.GET_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.payload || [],
      };
    }
    case CalendarActionTypes.GET_EVENT_SUCCESS: {
      return {
        ...state,
        event: action.payload || state.event,
      };
    }
    case CalendarActionTypes.ADD_EVENT_SUCCESS: {
      return {
        ...state,
        events: [action.payload].concat(state.events),
      };
    }
    case CalendarActionTypes.DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        events: state.events.filter(e => e._id !== action.payload._id),
      };
    }
    case CalendarActionTypes.UPDATE_EVENT_SUCCESS: {
      const index = state.events.findIndex(e => e._id === action.payload._id);
      const newEvents = state.events.slice();

      if (index > -1) {
        newEvents[index] = {
          ...action.payload,
        };
      }

      return {
        ...state,
        events: newEvents,
        event: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const getEvents = (state: State) => state.events;
export const getCurrentEvent = (state: State) => state.event;
