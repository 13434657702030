import { Observable, filter, mergeMap, of, repeat, retry, take } from 'rxjs';

import {
  BaseWebsocketMessage,
  DelWebsocketMessageRequest,
  GetWebsocketMessageRequest,
  InsWebsocketMessageRequest,
  QueryWebsocketMessageRequest,
  SubWebsocketMessageRequest,
  UnsubWebsocketMessageRequest,
  UpdWebsocketMessageRequest,
  UpdsWebsocketMessageRequest,
  WebsocketEntTypes,
  passWhenAlive,
} from '../models/web-socket.model';
import { WebSocketService } from '../services/web-socket.service';

export class WSEntityManager {
  private readonly webSocketIsAlive$ = this.wsService.getWebSocketIsAlive$();

  constructor(private wsService: WebSocketService, public entityName: WebsocketEntTypes) {
    if (!entityName) {
      throw new Error(`[qtek] WS Entity Manager - entity name is not provided but required`);
    }
  }

  public subscribeEntity(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>,
    withQuery = false
  ): Observable<BaseWebsocketMessage> {
    const subMessage: SubWebsocketMessageRequest = {
      op: 'sub',
      ent: this.entityName,
      cmd: withQuery ? 'query' : undefined,
      ...payload,
    };
    const unsubMessage: UnsubWebsocketMessageRequest = {
      op: 'unsub',
      ent: this.entityName,
      mysid: payload?.mysid,
    };

    return of(true).pipe(
      passWhenAlive(this.webSocketIsAlive$),
      mergeMap(() => {
        return this.wsService.getWebSocketSubject().pipe(
          mergeMap((socket) =>
            socket.multiplex(
              () => subMessage,
              () => unsubMessage,
              (message) => message.ent === this.entityName && message.mysid === payload?.mysid && Boolean(message.op)
            )
          )
        );
      }),
      repeat({ delay: 1000 }),
      retry({ delay: 1000 })
    );
  }

  public get(payload: Omit<GetWebsocketMessageRequest, 'ent' | 'op'>): void {
    const message: GetWebsocketMessageRequest = {
      op: 'get',
      ent: this.entityName,
      ...payload,
    };
    this.sendWsMessage(message);
  }

  public query(payload: Omit<QueryWebsocketMessageRequest, 'ent' | 'op'> = {}): void {
    const message: QueryWebsocketMessageRequest = {
      op: 'query',
      ent: this.entityName,
      ...payload,
    };
    this.sendWsMessage(message);
  }

  public create(payload: Omit<InsWebsocketMessageRequest, 'ent' | 'op'>): void {
    const message: InsWebsocketMessageRequest = {
      op: 'ins',
      ent: this.entityName,
      ...payload,
    };
    this.sendWsMessage(message);
  }

  public update(payload: Omit<UpdWebsocketMessageRequest, 'ent' | 'op'>): void {
    const message: UpdWebsocketMessageRequest = {
      op: 'upd',
      ent: this.entityName,
      ...payload,
    };
    this.sendWsMessage(message);
  }

  public bulkUpdate(payload: Omit<UpdsWebsocketMessageRequest, 'ent' | 'op'>): void {
    const message: UpdsWebsocketMessageRequest = {
      op: 'upds',
      ent: this.entityName,
      ...payload,
    };
    this.sendWsMessage(message);
  }

  public delete(payload: Omit<DelWebsocketMessageRequest, 'ent' | 'op'>): void {
    const message: DelWebsocketMessageRequest = {
      op: 'del',
      ent: this.entityName,
      ...payload,
    };
    this.sendWsMessage(message);
  }

  public sendWsMessage(message: any) {
    this.webSocketIsAlive$
      .pipe(
        filter((isAlive) => isAlive),
        take(1),
        mergeMap(() =>
          this.wsService.getWebSocketSubject().pipe(
            mergeMap((socket) => {
              socket.next(message);

              return of(true);
            })
          )
        ),
        take(1)
      )
      .subscribe();
  }
}
