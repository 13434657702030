import {
  ParticipantActions,
  ParticipantActionTypes,
} from './participants.actions';
import { EntityMeta, Investor } from '@qtek/shared/models';

export interface State {
  investors: Investor[];
  dealBook: any[];
  invests: any[];
  invitations: any[];
  invitationsMeta: EntityMeta;
  dealBookMeta: EntityMeta;
}

const initialState: State = {
  investors: [],
  dealBook: [],
  invests: [],
  invitations: [],
  invitationsMeta: new EntityMeta(),
  dealBookMeta: new EntityMeta(),
};

export function reducer(
  state = initialState,
  action: ParticipantActions
): State {
  switch (action.type) {
    // case ParticipantActionTypes.GET_INVESTOR_WS_SUCCESS:
    case ParticipantActionTypes.GET_INVESTOR_LIST_SUCCESS: {
      return {
        ...state,
        investors: action.payload || [],
      };
    }

    case ParticipantActionTypes.ADD_INVESTOR_SUCCESS:
    case ParticipantActionTypes.ADD_INVESTORS_WS_SUCCESS: {
      return {
        ...state,
        investors: state.investors.concat(action.payload),
      };
    }

    case ParticipantActionTypes.UPDATE_INVESTOR_SUCCESS:
    case ParticipantActionTypes.UPDATE_INVESTOR_WS_SUCCESS: {
      return {
        ...state,
        investors: state.investors.map(investor => {
          if (investor._id !== action.payload._id) {
            return investor;
          }
          const newInvestor = { ...action.payload };
          return newInvestor;
        }),
      };
    }

    case ParticipantActionTypes.DELETE_INVESTOR_SUCCESS:
    case ParticipantActionTypes.DELETE_INVESTOR_WS_SUCCESS: {
      return {
        ...state,
        investors: state.investors.filter(
          investor => investor._id !== action.payload._id
        ),
      };
    }

    // case ParticipantActionTypes.DISCONNECT_INVESTOR_LIST:
    case ParticipantActionTypes.UNSUBSCRIBE_INVESTOR_LIST: {
      return {
        ...state,
        investors: [],
      };
    }

    case ParticipantActionTypes.GET_SALES_PIPELINE_LIST_SUCCESS: {
      return {
        ...state,
        invitations: action.res ?? [],
        invitationsMeta: action.meta ?? new EntityMeta(),
      };
    }

    case ParticipantActionTypes.CREATE_SALES_PIPELINE_SUCCESS: {
      const newInvitation = action.payload;
      return {
        ...state,
        invitations: [
          ...state.invitations,
          ...(newInvitation ? [newInvitation] : []),
        ],
      };
    }

    case ParticipantActionTypes.UPDATE_SALES_PIPELINE_SUCCESS: {
      return {
        ...state,
        invitations: state.invitations.map(deal => {
          if (deal?._id !== action.payload._id) {
            return deal;
          }
          return action.payload;
        }),
      };
    }

    case ParticipantActionTypes.DELETE_SALES_PIPELINE_SUCCESS: {
      return {
        ...state,
        invitations: state.invitations.filter(
          deal => deal?._id !== action.payload._id
        ),
      };
    }

    case ParticipantActionTypes.UNSUBSCRIBE_SALES_PIPELINE_LIST: {
      return {
        ...state,
        invitations: [],
      };
    }

    case ParticipantActionTypes.GET_DEALBOOK_SUCCESS:
    case ParticipantActionTypes.GET_DEAL_BOOK_LIST_SUCCESS: {
      return {
        ...state,
        dealBook: action.payload ?? [],
        dealBookMeta: action.meta ?? new EntityMeta(),
      };
    }

    case ParticipantActionTypes.CREATE_DEAL_BOOK_SUCCESS:
    case ParticipantActionTypes.CREATE_DEALBOOK_SUCCESS: {
      const newDealBook = action.payload;
      return {
        ...state,
        dealBook: [...state.dealBook, ...(newDealBook ? [newDealBook] : [])],
      };
    }

    case ParticipantActionTypes.UPDATE_DEAL_BOOK_SUCCESS:
    case ParticipantActionTypes.UPDATE_DEALBOOK_SUCCESS: {
      return {
        ...state,
        dealBook: state.dealBook.map(deal => {
          if (deal?._id !== action.payload._id) {
            return deal;
          }
          return action.payload;
        }),
      };
    }

    case ParticipantActionTypes.DELETE_DEAL_BOOK_SUCCESS:
    case ParticipantActionTypes.DELETE_DEALBOOK_SUCCESS: {
      return {
        ...state,
        dealBook: state.dealBook.filter(
          deal => deal?._id !== action.payload._id
        ),
      };
    }

    case ParticipantActionTypes.UNSUBSCRIBE_DEAL_BOOK_LIST: {
      return {
        ...state,
        dealBook: [],
      };
    }

    case ParticipantActionTypes.GET_INVESTS_SUCCESS: {
      return {
        ...state,
        invests: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getInvestors = (state: State) => state.investors;
export const getDealBook = (state: State) => state.dealBook;
export const getDealBookMeta = (state: State) => state.dealBookMeta;
export const getInvests = (state: State) => state.invests;
export const getInvitations = (state: State) => state.invitations;
export const getInvitationsMeta = (state: State) => state.invitationsMeta;
