import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BaseWebsocketMessage,
  DelWebsocketMessageRequest,
  GetWebsocketMessageRequest,
  InsWebsocketMessageRequest,
  QueryWebsocketMessageRequest,
  SubWebsocketMessageRequest,
  UpdWebsocketMessageRequest,
  WebsocketEntTypes,
  WebSocketService,
  WSEntityManager,
} from '@qtek/core/websockets-core';
import { BackendResponse } from '@qtek/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  constructor(
    private websocketService: WebSocketService,
    protected http: HttpClient
  ) {}

  entityName: WebsocketEntTypes = 'itm';
  wsManager = new WSEntityManager(this.websocketService, this.entityName);

  subscribe(
    payload: Omit<SubWebsocketMessageRequest, 'ent' | 'op'>
  ): Observable<BaseWebsocketMessage> {
    return this.wsManager.subscribeEntity(payload, true);
  }

  createItem(payload: Omit<InsWebsocketMessageRequest, 'ent' | 'op'>) {
    this.wsManager.create(payload);
  }

  updateItem(payload: Omit<UpdWebsocketMessageRequest, 'ent' | 'op'>) {
    this.wsManager.update(payload);
  }

  deleteItem(payload: Omit<DelWebsocketMessageRequest, 'ent' | 'op'>) {
    this.wsManager.delete(payload);
  }

  getItems(payload: Omit<QueryWebsocketMessageRequest, 'ent' | 'op'>) {
    this.wsManager.query(payload);
  }

  getItemById(payload: Omit<GetWebsocketMessageRequest, 'ent' | 'op'>) {
    this.wsManager.get(payload);
  }

  mintItem(seqno: number): Observable<BackendResponse<any>> {
    return this.http.post<BackendResponse>(
      '/pt/api/v1/mint',
      { seqno },
      { withCredentials: true }
    );
  }
}
