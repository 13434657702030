import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  NgZone,
  Renderer2,
  Version,
  ViewContainerRef,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  APP_ENVIRONMENT,
  APP_VERSION,
  ApiCoreService,
} from '@qtek/core/api-core';
import { LocalStorageManagementService } from '@qtek/core/local-storage-management';
import { ThemeManagementService } from '@qtek/core/theme-management';
import { WebSocketService } from '@qtek/core/websockets-core';
import {
  APP_COMPONENT_DEFAULT_STYLES,
  APP_COMPONENT_DEFAULT_TEMPLATE,
  QtAppComponent,
  QtIntl,
} from '@qtek/libs/app-component';
import { LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { SnackBarService } from '@qtek/libs/snack-bar-core';
import {
  AppState,
  BookingActionTypes,
  SendBookingSuccessAction,
} from '@qtek/libs/store';
import { TranslationCoreService } from '@qtek/libs/translation-core';
import {
  BOOKING_DELIVERY_STATUS,
  DeliveryStatusType,
  DeliveryType,
  Environment,
  Message,
} from '@qtek/shared/models';
import { switchMap } from 'rxjs';

@Component({
  selector: 'qt-lfe-root',
  template: APP_COMPONENT_DEFAULT_TEMPLATE,
  styles: [APP_COMPONENT_DEFAULT_STYLES],
})
export class AppComponent extends QtAppComponent {
  constructor(
    ngZone: NgZone,
    renderer: Renderer2,
    matIconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    protected router: Router,
    protected actions$: Actions,
    store: Store<AppState>,
    protected dialogLoader: LazyDialogLoader,
    snackBar: MatSnackBar,
    @Inject(DOCUMENT) document: any,
    @Inject(QtIntl) intls: QtIntl[],
    @Inject(APP_ENVIRONMENT) environment: Environment,
    @Inject(APP_VERSION) version: Version,
    protected route: ActivatedRoute,
    viewContainerRef: ViewContainerRef,
    translateService: TranslationCoreService,
    overlay: OverlayContainer,
    protected apiCoreService: ApiCoreService,
    protected webSocketService: WebSocketService,
    protected localStorageManagementService: LocalStorageManagementService,
    protected themeManagementService: ThemeManagementService,
    protected snackBarService: SnackBarService
  ) {
    super(
      ngZone,
      renderer,
      matIconRegistry,
      sanitizer,
      router,
      actions$,
      store,
      snackBar,
      document,
      intls,
      route,
      viewContainerRef,
      translateService,
      overlay,
      apiCoreService,
      webSocketService,
      localStorageManagementService,
      themeManagementService,
      snackBarService,
      environment,
      version
    );
  }

  override onInitCallback() {
    this.route.queryParams.subscribe(params => {
      const { err } = params;

      if (err) {
        const errorCode = atob(err);

        this.dialogLoader
          .open('alert', { message: new Message(errorCode) })
          .subscribe();

        // Remove query param
        this.router.navigate([], {
          queryParams: {
            ...params,
            err: null,
          },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }
    });

    // Show notifiation when booking url was sent successfully (or not).

    this.actions$
      .pipe(
        ofType<SendBookingSuccessAction>(
          BookingActionTypes.SEND_BOOKING_SUCCESS
        ),
        switchMap(({ payload }) => {
          payload = payload || { sts: 100 };

          return this.dialogLoader.open('delivery-dialog', {
            ...BOOKING_DELIVERY_STATUS[payload.sts as DeliveryStatusType],
            endpoints: payload.endpoints || [],
            deliveryType: DeliveryType.BOOKING_DELIVERY,
          });
        })
      )
      .subscribe();
  }
}
