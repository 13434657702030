import { ErrorHandler, importProvidersFrom, NgModule } from '@angular/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { provideStore, StoreModule } from '@ngrx/store';

import { ApiCoreModule, ApiCoreService } from '@qtek/core/api-core';
import {
  LocalStorageManagementModule,
  LocalStorageManagementService,
} from '@qtek/core/local-storage-management';
import {
  ThemeManagementModule,
  ThemeManagementService,
} from '@qtek/core/theme-management';
import {
  WebsocketsCoreModule,
  WebSocketService,
} from '@qtek/core/websockets-core';
import { AuthUserModule } from '@qtek/libs/auth-user';
import { DialogLoaderModule } from '@qtek/libs/dialog-loader';
import { HealthcheckCoreModule } from '@qtek/libs/healthcheck-core';
import { HttpInterceptorsModule } from '@qtek/libs/http-interceptors';
import { MetaCoreModule } from '@qtek/libs/meta-core';
import { metaReducers, REDUCERS, STORE_PROVIDERS } from '@qtek/libs/store';
import {
  TranslationCoreModule,
  TranslationCoreService,
} from '@qtek/libs/translation-core';
import { NgxIconPickerModule } from '@qtek/third-party-libs/ngx-icon-picker';
import { QtErrorHandler } from './error-handler';
import { APP_INITIALIZER_PROVIDERS } from './init';
import { INTL_PROVIDERS } from './intl';
import { LoaderComponent } from './loader/loader.component';

/**
 * This NgModule is core of **every** portal, it contains providers that will be used in every app.
 * Each portal should create its own core module based on this(to add additional providers etc).
 */
@NgModule({
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    StoreModule.forRoot(REDUCERS, { metaReducers }),
    HttpInterceptorsModule,
    AuthUserModule,
    LocalStorageManagementModule,
    ApiCoreModule,
    TranslationCoreModule,
    WebsocketsCoreModule,
    HealthcheckCoreModule,
    MetaCoreModule,
    ThemeManagementModule,
    DialogLoaderModule,
    LoaderComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: QtErrorHandler,
    },
    ...APP_INITIALIZER_PROVIDERS,
    ...STORE_PROVIDERS,
    ...INTL_PROVIDERS,
    provideStore(REDUCERS, { metaReducers }),
    importProvidersFrom(NgxIconPickerModule),
    WebSocketService,
    ApiCoreService,
    LocalStorageManagementService,
    ThemeManagementService,
    TranslationCoreService,
  ],
})
export class QtCoreModule {}
