import { Action } from '@ngrx/store';

import { EntityMeta, Item, Message } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const AgencyActionTypes = {
  SUBSCRIBE_ITEM: type('[Agency] Subscribe Item'),
  UNSUBSCRIBE_ITEM: type('[Agency] Unsubscribe Item'),
  CREATE_ITEM: type('[Agency] Create Item'),
  CREATE_ITEM_FAILURE: type('[Agency] Create Item Failure'),
  CREATE_ITEM_SUCCESS: type('[Agency] Create Item Success'),
  GET_ITEMS: type('[Agency] Get Items'),
  GET_ITEMS_FAILURE: type('[Agency] Get Items Failure'),
  GET_ITEMS_SUCCESS: type('[Agency] Get Items Success'),
  GET_ITEM: type('[Agency] Get Item by id'),
  GET_ITEM_FAILURE: type('[Agency] Get Item by id Failure'),
  GET_ITEM_SUCCESS: type('[Agency] Get Item by id Success'),
  UPDATE_ITEM: type('[Agency] Update Item'),
  UPDATE_ITEM_FAILURE: type('[Agency] Update Item Failure'),
  UPDATE_ITEM_SUCCESS: type('[Agency] Update Item Success'),
  DELETE_ITEM: type('[Agency] Delete Item'),
  DELETE_ITEM_FAILURE: type('[Agency] Delete Item Failure'),
  DELETE_ITEM_SUCCESS: type('[Agency] Delete Item Success'),
};

export class SubscribeAgenciesAction implements Action {
  type = AgencyActionTypes.SUBSCRIBE_ITEM;

  constructor(public payload?: { q?: any[]; view?: string; mysid?: string }) {}
}

export class UnsubscribeAgenciesAction implements Action {
  type = AgencyActionTypes.UNSUBSCRIBE_ITEM;

  constructor(public payload?: { mysid?: string }) {}
}

export class GetAgenciesAction implements Action {
  type = AgencyActionTypes.GET_ITEMS;

  constructor(public payload?: any) {}
}

export class GetAgenciesFailureAction implements Action {
  type = AgencyActionTypes.GET_ITEMS_FAILURE;

  constructor(public payload?: any) {}
}

export class GetAgenciesSuccessAction implements Action {
  type = AgencyActionTypes.GET_ITEMS_SUCCESS;

  constructor(public payload: { res: Item[]; meta: EntityMeta }) {}
}

export class GetAgencyAction implements Action {
  type = AgencyActionTypes.GET_ITEM;

  constructor(public payload: string) {}
}

export class GetAgencyFailureAction implements Action {
  type = AgencyActionTypes.GET_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class GetAgencySuccessAction implements Action {
  type = AgencyActionTypes.GET_ITEM_SUCCESS;

  constructor(public payload: Item) {}
}

export class CreateAgencyAction implements Action {
  type = AgencyActionTypes.CREATE_ITEM;

  constructor(public payload: Item) {}
}

export class CreateAgencyFailureAction implements Action {
  type = AgencyActionTypes.CREATE_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class CreateAgencySuccessAction implements Action {
  type = AgencyActionTypes.CREATE_ITEM_SUCCESS;
  message = new Message('GNR_ITM_CREATED');

  constructor(public payload: Item) {}
}

export class UpdateAgencyAction implements Action {
  type = AgencyActionTypes.UPDATE_ITEM;

  constructor(public payload: Item) {}
}

export class UpdateAgencyFailureAction implements Action {
  type = AgencyActionTypes.UPDATE_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateAgencySuccessAction implements Action {
  type = AgencyActionTypes.UPDATE_ITEM_SUCCESS;
  message = new Message('GNR_ITM_UPDATED');

  constructor(public payload: Item) {}
}

export class DeleteAgencyAction implements Action {
  type = AgencyActionTypes.DELETE_ITEM;

  constructor(public payload: string) {}
}

export class DeleteAgencyFailureAction implements Action {
  type = AgencyActionTypes.DELETE_ITEM_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteAgencySuccessAction implements Action {
  type = AgencyActionTypes.DELETE_ITEM_SUCCESS;
  message = new Message('GNR_ITM_DELETED');

  constructor(public payload: Item) {}
}

export type AgencyActions =
  | GetAgenciesAction
  | GetAgenciesFailureAction
  | GetAgenciesSuccessAction
  | GetAgencyAction
  | GetAgencyFailureAction
  | GetAgencySuccessAction
  | CreateAgencyAction
  | CreateAgencyFailureAction
  | CreateAgencySuccessAction
  | UpdateAgencyAction
  | UpdateAgencyFailureAction
  | UpdateAgencySuccessAction
  | DeleteAgencyAction
  | DeleteAgencyFailureAction
  | DeleteAgencySuccessAction;
