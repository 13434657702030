import { WizardActions, WizardActionTypes } from './wizard.actions';
import { Step } from '@qtek/shared/models';

export interface State {
  steps: Step[];
  demoSteps?: any;
  maxPersonProgress?: number;
  maxCompanyProgress?: number;
  loading: boolean;
  wizardDone: boolean;
}

const initialState: State = {
  steps: [],
  loading: false,
  wizardDone: false,
};

export function reducer(state = initialState, action: WizardActions): State {
  switch (action.type) {
    case WizardActionTypes.IS_WIZARD_DONE: {
      return {
        ...state,
        wizardDone: true,
      };
    }

    case WizardActionTypes.GET_STEPS_SUCCESS: {
      return {
        ...state,
        steps: action.payload,
      };
    }

    case WizardActionTypes.GET_DEMO_STEPS_SUCCESS: {
      return {
        ...state,
        demoSteps: action.payload,
      };
    }

    case WizardActionTypes.SET_MAX_PERSON_PROGRESS: {
      return {
        ...state,
        maxPersonProgress: action.payload,
      };
    }

    case WizardActionTypes.SET_MAX_COMPANY_PROGRESS: {
      return {
        ...state,
        maxCompanyProgress: action.payload,
      };
    }

    case WizardActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsWizardDone = (state: State) => state.wizardDone;
export const getSteps = (state: State) => state.steps;
export const getDemoSteps = (state: State) => state.demoSteps;
export const getMaxPersonProgress = (state: State) => state.maxPersonProgress;
export const getMaxCompanyProgress = (state: State) => state.maxCompanyProgress;
export const isWizardLoading = (state: State) => state.loading;
/* tslint:disable: no-bitwise */
export const getMaxDemoDataProgress = (state: State) =>
  state.demoSteps
    ? Object.keys(state.demoSteps).reduce(
        (prev, curr) => prev | Number(state.demoSteps[curr]),
        0
      )
    : 0;
