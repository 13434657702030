import { Action } from '@ngrx/store';

import {
  DashboardTileDataset,
  DashboardTiles,
  MenuNode,
} from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const MenuActionTypes = {
  LOAD_MAIN: type('[Menu] Main Load'),
  LOAD_MAIN_SUCCESS: type('[Menu] Main Load Success'),
  LOAD_WIZARD: type('[Menu] Wizard Load'),
  LOAD_WIZARD_SUCCESS: type('[Menu] Wizard Load Success'),
  LOAD_SETUP: type('[Menu] Setup Load'),
  LOAD_SETUP_SUCCESS: type('[Menu] Setup Load Success'),
  LOAD_DASHBOARD: type('[Menu] Dashboard Load'),
  LOAD_DASHBOARD_SUCCESS: type('[Menu] Dashboard Load Success'),
  CONNECT_DASHBOARD: type('[Menu] Dashboard connect'),
  DISCONNECT_DASHBOARD: type('[Menu] Dashboard disconnect'),
  UPDATE_CHART_DATASET: type('[Menu] Update chart dataset'),
  WIZARD_STEP: type('[Menu] Wizard Step Load'),
};

export class LoadMainAction implements Action {
  type = MenuActionTypes.LOAD_MAIN;

  constructor(public payload?: any) {}
}

export class LoadMainSuccessAction implements Action {
  type = MenuActionTypes.LOAD_MAIN_SUCCESS;

  constructor(public payload: MenuNode[]) {}
}

export class LoadWizardAction implements Action {
  type = MenuActionTypes.LOAD_WIZARD;

  constructor(public payload?: any) {}
}

export class LoadWizardSuccessAction implements Action {
  type = MenuActionTypes.LOAD_WIZARD_SUCCESS;

  constructor(public payload: MenuNode[]) {}
}

export class LoadSetupAction implements Action {
  type = MenuActionTypes.LOAD_SETUP;

  constructor(public payload?: any) {}
}

export class LoadSetupSuccessAction implements Action {
  type = MenuActionTypes.LOAD_SETUP_SUCCESS;

  constructor(public payload: MenuNode[]) {}
}

export class LoadDashboardAction implements Action {
  type = MenuActionTypes.LOAD_DASHBOARD;

  constructor(public payload?: any) {}
}

export class ConnectDashboardAction implements Action {
  type = MenuActionTypes.CONNECT_DASHBOARD;

  constructor(public payload: string) {}
}

export class DisconnectDashboardAction implements Action {
  type = MenuActionTypes.DISCONNECT_DASHBOARD;

  constructor(public payload: string) {}
}

export class UpdateChartDataset implements Action {
  type = MenuActionTypes.UPDATE_CHART_DATASET;

  constructor(public payload: { data: DashboardTileDataset[]; id: string }) {}
}

export class LoadDashboardSuccessAction implements Action {
  type = MenuActionTypes.LOAD_DASHBOARD_SUCCESS;

  constructor(public payload: DashboardTiles) {}
}

export class WizardStepAction implements Action {
  type = MenuActionTypes.WIZARD_STEP;

  constructor(public payload: string) {}
}

export type MenuActions =
  | LoadMainAction
  | LoadMainSuccessAction
  | LoadWizardAction
  | LoadWizardSuccessAction
  | LoadSetupAction
  | LoadSetupSuccessAction
  | LoadDashboardAction
  | LoadDashboardSuccessAction
  | WizardStepAction
  | ConnectDashboardAction
  | DisconnectDashboardAction
  | UpdateChartDataset;
