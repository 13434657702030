import { Action } from '@ngrx/store';

import {
  EntityMeta,
  Investor,
  Message,
  DealBookEntry,
  WebsocketQuery,
} from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export class ParticipantActionTypes {
  static readonly CONNECT_INVESTOR_LIST = type(
    '[Participant] Connect investor List'
  );
  static readonly DISCONNECT_INVESTOR_LIST = type(
    '[Participant] Disconnect investor List'
  );

  static readonly GET_INVESTOR_WS = type('[Participant] Get investor via WS');
  static readonly GET_INVESTOR_WS_SUCCESS = type(
    '[Participant] Get investor via WS Success'
  );
  static readonly GET_INVESTOR_WS_FAILURE = type(
    '[Participant] Get investor via WS Failure'
  );

  static readonly ADD_INVESTOR_WS = type('[Participant] Add investor via WS');
  static readonly ADD_INVESTOR_WS_SUCCESS = type(
    '[Participant] Add investor via WS Success'
  );
  static readonly ADD_INVESTOR_WS_FAILURE = type(
    '[Participant] Add investor via WS Failure'
  );

  static readonly ADD_INVESTORS_WS = type('[Participant] Add investors via WS');
  static readonly ADD_INVESTORS_WS_SUCCESS = type(
    '[Participant] Add investors via WS Success'
  );
  static readonly ADD_INVESTORS_WS_FAILURE = type(
    '[Participant] Add investors via WS Failure'
  );

  static readonly UPDATE_INVESTOR_WS = type(
    '[Participant] Update investor via WS'
  );
  static readonly UPDATE_INVESTOR_WS_SUCCESS = type(
    '[Participant] Update investor via WS Success'
  );
  static readonly UPDATE_INVESTOR_WS_FAILURE = type(
    '[Participant] Update investor via WS Failure'
  );

  static readonly DELETE_INVESTOR_WS = type(
    '[Participant] Delete investor via WS'
  );
  static readonly DELETE_INVESTOR_WS_SUCCESS = type(
    '[Participant] Delete investor via WS Success'
  );
  static readonly DELETE_INVESTOR_WS_FAILURE = type(
    '[Participant] Delete investor via WS Failure'
  );

  static readonly RESENT_INVESTOR_INVITE = type(
    '[Participant] Resent investor invite'
  );
  static readonly RESENT_INVESTOR_INVITE_SUCCESS = type(
    '[Participant] Resent investor invite Success'
  );
  static readonly RESENT_INVESTOR_INVITE_FAILURE = type(
    '[Participant] Resent investor invite Failure'
  );

  static readonly CONNECT_DEALBOOK_LIST = type(
    '[Participant] Connect deal book List'
  );
  static readonly DISCONNECT_DEALBOOK_LIST = type(
    '[Participant] Disconnect deal book List'
  );

  static readonly GET_DEALBOOK = type('[Participant] Get DealBook');
  static readonly GET_DEALBOOK_SUCCESS = type(
    '[Participant] Get DealBook Success'
  );
  static readonly GET_DEALBOOK_FAILURE = type(
    '[Participant] Get DealBook Failure'
  );

  static readonly CREATE_DEALBOOK = type('[Participant] Create DealBook');
  static readonly CREATE_DEALBOOK_SUCCESS = type(
    '[Participant] Create DealBook Success'
  );
  static readonly CREATE_DEALBOOK_FAILURE = type(
    '[Participant] Create DealBook Failure'
  );

  static readonly UPDATE_DEALBOOK = type('[Participant] Update DealBook');
  static readonly UPDATE_DEALBOOK_SUCCESS = type(
    '[Participant] Update DealBook Success'
  );
  static readonly UPDATE_DEALBOOK_FAILURE = type(
    '[Participant] Update DealBook Failure'
  );

  static readonly DELETE_DEALBOOK = type('[Participant] Delete DealBook');
  static readonly DELETE_DEALBOOK_SUCCESS = type(
    '[Participant] Delete DealBook Success'
  );
  static readonly DELETE_DEALBOOK_FAILURE = type(
    '[Participant] Delete DealBook Failure'
  );

  static readonly GET_INVESTS = type('[Participant] Get Invests');
  static readonly GET_INVESTS_SUCCESS = type(
    '[Participant] Get Invests Success'
  );
  static readonly GET_INVESTS_FAILURE = type(
    '[Participant] Get Invests Failure'
  );

  static readonly REQUEST_INVESTS = type('[Participant] Request Invests');
  static readonly REQUEST_INVESTS_SUCCESS = type(
    '[Participant] Request Invests Success'
  );
  static readonly REQUEST_INVESTS_FAILURE = type(
    '[Participant] Request Invests Failure'
  );

  // --------------- New Actions Types ---------------
  static readonly SUBSCRIBE_DEAL_BOOK_LIST = type(
    '[Participant] Subscribe Deal Book List'
  );
  static readonly UNSUBSCRIBE_DEAL_BOOK_LIST = type(
    '[Participant] Unsubscribe Deal Book List'
  );

  static readonly GET_DEAL_BOOK_LIST = type('[Participant] Get Deal Book List');
  static readonly GET_DEAL_BOOK_LIST_SUCCESS = type(
    '[Participant] Get Deal Book List Success'
  );
  static readonly GET_DEAL_BOOK_LIST_FAILURE = type(
    '[Participant] Get Deal Book List Failure'
  );

  static readonly CREATE_DEAL_BOOK = type('[Participant] Create Deal Book');
  static readonly CREATE_DEAL_BOOK_SUCCESS = type(
    '[Participant] Create Deal Book Success'
  );
  static readonly CREATE_DEAL_BOOK_FAILURE = type(
    '[Participant] Create Deal Book Failure'
  );

  static readonly UPDATE_DEAL_BOOK = type('[Participant] Update Deal Book');
  static readonly UPDATE_DEAL_BOOK_SUCCESS = type(
    '[Participant] Update Deal Book Success'
  );
  static readonly UPDATE_DEAL_BOOK_FAILURE = type(
    '[Participant] Update Deal Book Failure'
  );

  static readonly DELETE_DEAL_BOOK = type('[Participant] Delete Deal Book');
  static readonly DELETE_DEAL_BOOK_SUCCESS = type(
    '[Participant] Delete Deal Book Success'
  );
  static readonly DELETE_DEAL_BOOK_FAILURE = type(
    '[Participant] Delete Deal Book Failure'
  );

  static readonly SUBSCRIBE_INVESTOR_LIST = type(
    '[Participant] Subscribe Investor List'
  );
  static readonly UNSUBSCRIBE_INVESTOR_LIST = type(
    '[Participant] Unsubscribe Investor List'
  );

  static readonly GET_INVESTOR_LIST = type('[Participant] Get Investor List');
  static readonly GET_INVESTOR_LIST_SUCCESS = type(
    '[Participant] Get Investor List Success'
  );
  static readonly GET_INVESTOR_LIST_FAILURE = type(
    '[Participant] Get Investor List Failure'
  );

  static readonly ADD_INVESTOR = type('[Participant] Add Investor');
  static readonly ADD_INVESTOR_SUCCESS = type(
    '[Participant] Add Investor Success'
  );
  static readonly ADD_INVESTOR_FAILURE = type(
    '[Participant] Add Investor Failure'
  );

  static readonly ADD_INVESTORS = type('[Participant] Add Investors');
  static readonly ADD_INVESTORS_SUCCESS = type(
    '[Participant] Add Investors Success'
  );
  static readonly ADD_INVESTORS_FAILURE = type(
    '[Participant] Add Investors Failure'
  );

  static readonly UPDATE_INVESTOR = type('[Participant] Update Investor');
  static readonly UPDATE_INVESTOR_SUCCESS = type(
    '[Participant] Update Investor Success'
  );
  static readonly UPDATE_INVESTOR_FAILURE = type(
    '[Participant] Update Investor Failure'
  );

  static readonly DELETE_INVESTOR = type('[Participant] Delete Investor');
  static readonly DELETE_INVESTOR_SUCCESS = type(
    '[Participant] Delete Investor Success'
  );
  static readonly DELETE_INVESTOR_FAILURE = type(
    '[Participant] Delete Investor Failure'
  );

  // static readonly RESENT_INVESTOR_INVITE = type('[Participant] Resent Investor Invite');
  // static readonly RESENT_INVESTOR_INVITE_SUCCESS = type('[Participant] Resent Investor Invite Success');
  // static readonly RESENT_INVESTOR_INVITE_FAILURE = type('[Participant] Resent Investor Invite Failure');
  static readonly SUBSCRIBE_SALES_PIPELINE_LIST = type(
    '[Participant] Subscribe Sales Pipeline List'
  );
  static readonly UNSUBSCRIBE_SALES_PIPELINE_LIST = type(
    '[Participant] Unsubscribe Sales Pipeline List'
  );

  static readonly GET_SALES_PIPELINE_LIST = type(
    '[Participant] Get Sales Pipeline List'
  );
  static readonly GET_SALES_PIPELINE_LIST_SUCCESS = type(
    '[Participant] Get Sales Pipeline List Success'
  );
  static readonly GET_SALES_PIPELINE_LIST_FAILURE = type(
    '[Participant] Get Sales Pipeline List Failure'
  );

  static readonly CREATE_SALES_PIPELINE = type(
    '[Participant] Create Sales Pipeline'
  );
  static readonly CREATE_SALES_PIPELINE_SUCCESS = type(
    '[Participant] Create Sales Pipeline Success'
  );
  static readonly CREATE_SALES_PIPELINE_FAILURE = type(
    '[Participant] Create Sales Pipeline Failure'
  );

  static readonly UPDATE_SALES_PIPELINE = type(
    '[Participant] Update Sales Pipeline'
  );
  static readonly UPDATE_SALES_PIPELINE_SUCCESS = type(
    '[Participant] Update Sales Pipeline Success'
  );
  static readonly UPDATE_SALES_PIPELINE_FAILURE = type(
    '[Participant] Update Sales Pipeline Failure'
  );

  static readonly DELETE_SALES_PIPELINE = type(
    '[Participant] Delete Sales Pipeline'
  );
  static readonly DELETE_SALES_PIPELINE_SUCCESS = type(
    '[Participant] Delete Sales Pipeline Success'
  );
  static readonly DELETE_SALES_PIPELINE_FAILURE = type(
    '[Participant] Delete Sales Pipeline Failure'
  );
}
// --------------- Deal book participants Actions (new) ---------------
export class SubscribeDealBookListWS implements Action {
  constructor(public payload: { query: WebsocketQuery[]; viewId: string }) {}

  readonly type = ParticipantActionTypes.SUBSCRIBE_DEAL_BOOK_LIST;
}

export class UnsubscribeDealBookListWS implements Action {
  constructor(public payload: { viewId: string }) {}

  readonly type = ParticipantActionTypes.UNSUBSCRIBE_DEAL_BOOK_LIST;
}

export class GetDealBookListWS implements Action {
  constructor(public payload: { query: WebsocketQuery[]; viewId: string }) {}

  readonly type = ParticipantActionTypes.GET_DEAL_BOOK_LIST;
}

export class GetDealBookListSuccessWS implements Action {
  constructor(public payload: DealBookEntry[], public meta: EntityMeta) {}

  readonly type = ParticipantActionTypes.GET_DEAL_BOOK_LIST_SUCCESS;
}

export class GetDealBookListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.GET_DEAL_BOOK_LIST_FAILURE;
}

export class CreateDealBookOnListWS implements Action {
  constructor(public payload: any[]) {}

  readonly type = ParticipantActionTypes.CREATE_DEAL_BOOK;
}

export class CreateDealBookOnListSuccessWS implements Action {
  constructor(public payload: DealBookEntry) {}

  readonly type = ParticipantActionTypes.CREATE_DEAL_BOOK_SUCCESS;
  readonly message = new Message('GNR_DEAL_BOOK_CREATED');
}

export class CreateDealBookOnListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.CREATE_DEAL_BOOK_FAILURE;
}

export class UpdateDealBookOnListBookWS implements Action {
  constructor(
    public payload: { res: DealBookEntry; viewId: string; id: string }
  ) {}

  readonly type = ParticipantActionTypes.UPDATE_DEAL_BOOK;
}

export class UpdateDealBookOnListSuccessWS implements Action {
  constructor(public payload: DealBookEntry) {}

  readonly type = ParticipantActionTypes.UPDATE_DEAL_BOOK_SUCCESS;
  readonly message = new Message('GNR_DEAL_BOOK_UPDATED');
}

export class UpdateDealBookOnListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.UPDATE_DEAL_BOOK_FAILURE;
}

export class DeleteDealBookOnListWS implements Action {
  constructor(public payload: { res: DealBookEntry; viewId: string }) {}

  readonly type = ParticipantActionTypes.DELETE_DEAL_BOOK;
}

export class DeleteDealBookOnListSuccessWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.DELETE_DEAL_BOOK_SUCCESS;
  readonly message = new Message('GNR_DEAL_BOOK_DELETED');
}

export class DeleteDealBookOnListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.DELETE_DEAL_BOOK_FAILURE;
}

export class SubscribeInvestorListWS implements Action {
  constructor(public payload: { query: WebsocketQuery[]; viewId: string }) {}

  readonly type = ParticipantActionTypes.SUBSCRIBE_INVESTOR_LIST;
}

export class UnsubscribeInvestorListWS implements Action {
  constructor(public payload: { viewId: string }) {}

  readonly type = ParticipantActionTypes.UNSUBSCRIBE_INVESTOR_LIST;
}

export class GetInvestorListWS implements Action {
  constructor(public payload: { query: WebsocketQuery[]; viewId: string }) {}

  readonly type = ParticipantActionTypes.GET_INVESTOR_LIST;
}

export class GetInvestorListSuccessWS implements Action {
  constructor(public payload: DealBookEntry[]) {}

  readonly type = ParticipantActionTypes.GET_INVESTOR_LIST_SUCCESS;
}

export class GetInvestorListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.GET_INVESTOR_LIST_FAILURE;
}

export class AddInvestorToListWS implements Action {
  constructor(
    public payload: { res: any[]; queryId: string; viewId: string }
  ) {}

  readonly type = ParticipantActionTypes.ADD_INVESTOR;
}

export class AddInvestorToListSuccessWS implements Action {
  constructor(public payload: DealBookEntry) {}

  readonly type = ParticipantActionTypes.ADD_INVESTOR_SUCCESS;
}

export class AddInvestorToListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.ADD_INVESTOR_FAILURE;
}

export class UpdateInvestorOnListWS implements Action {
  constructor(
    public payload: { res: any; queryId: string; viewId: string; id: string }
  ) {}

  readonly type = ParticipantActionTypes.UPDATE_INVESTOR;
}

export class UpdateInvestorOnListSuccessWS implements Action {
  constructor(public payload: DealBookEntry) {}

  readonly type = ParticipantActionTypes.UPDATE_INVESTOR_SUCCESS;
}

export class UpdateInvestorOnListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.UPDATE_INVESTOR_FAILURE;
}

export class DeleteInvestorOnListWS implements Action {
  constructor(
    public payload: { queryId: string; viewId: string; id: string }
  ) {}

  readonly type = ParticipantActionTypes.DELETE_INVESTOR;
}

export class DeleteInvestorOnListSuccessWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.DELETE_INVESTOR_SUCCESS;
  readonly message = new Message('GNR_DEAL_BOOK_DELETED');
}

export class DeleteInvestorOnListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.DELETE_INVESTOR_FAILURE;
}

export class SubscribeSalesPipelineListWS implements Action {
  constructor(public payload: { query: WebsocketQuery[]; viewId: string }) {}

  readonly type = ParticipantActionTypes.SUBSCRIBE_SALES_PIPELINE_LIST;
}

export class UnsubscribeSalesPipelineListWS implements Action {
  constructor(public payload: { viewId: string }) {}

  readonly type = ParticipantActionTypes.UNSUBSCRIBE_SALES_PIPELINE_LIST;
}

export class GetSalesPipelineListWS implements Action {
  constructor(public payload: { query: WebsocketQuery[]; viewId: string }) {}

  readonly type = ParticipantActionTypes.GET_SALES_PIPELINE_LIST;
}

export class GetSalesPipelineListSuccessWS implements Action {
  constructor(public res: DealBookEntry[], public meta?: EntityMeta) {}

  readonly type = ParticipantActionTypes.GET_SALES_PIPELINE_LIST_SUCCESS;
}

export class GetSalesPipelineListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.GET_SALES_PIPELINE_LIST_FAILURE;
}

export class CreateSalesPipelineOnListWS implements Action {
  constructor(public payload: any[]) {}

  readonly type = ParticipantActionTypes.CREATE_SALES_PIPELINE;
}

export class CreateSalesPipelineOnListSuccessWS implements Action {
  constructor(public payload: DealBookEntry) {}

  readonly type = ParticipantActionTypes.CREATE_SALES_PIPELINE_SUCCESS;
}

export class CreateSalesPipelineOnListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.CREATE_SALES_PIPELINE_FAILURE;
}

export class UpdateSalesPipelineOnListBookWS implements Action {
  constructor(
    public payload: { res: DealBookEntry; viewId: string; id: string }
  ) {}

  readonly type = ParticipantActionTypes.UPDATE_SALES_PIPELINE;
}

export class UpdateSalesPipelineOnListSuccessWS implements Action {
  constructor(public payload: DealBookEntry) {}

  readonly type = ParticipantActionTypes.UPDATE_SALES_PIPELINE_SUCCESS;
}

export class UpdateSalesPipelineOnListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.UPDATE_SALES_PIPELINE_FAILURE;
}

export class DeleteSalesPipelineOnListWS implements Action {
  constructor(public payload: { res: DealBookEntry; viewId: string }) {}

  readonly type = ParticipantActionTypes.DELETE_SALES_PIPELINE;
}

export class DeleteSalesPipelineOnListSuccessWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.DELETE_SALES_PIPELINE_SUCCESS;
}

export class DeleteSalesPipelineOnListFailureWS implements Action {
  constructor(public payload: any) {}

  readonly type = ParticipantActionTypes.DELETE_SALES_PIPELINE_FAILURE;
}

// --------------- Investors for item (deal) ---------------
// export class ConnectInvestorList implements Action {
//   readonly type = ParticipantActionTypes.CONNECT_INVESTOR_LIST;
//
//   constructor(public payload?: { itmId: string; _v: ParticipantViews }) {}
// }
//
// export class DisconnectInvestorList implements Action {
//   readonly type = ParticipantActionTypes.DISCONNECT_INVESTOR_LIST;
//
//   constructor(public payload?: any) {}
// }
//
// export class GetInvestorsWS implements Action {
//   readonly type = ParticipantActionTypes.GET_INVESTOR_WS;
//
//   constructor(public payload?: { id: string; _v: ParticipantViews }) {}
// }

// export class GetInvestorsSuccessWS implements Action {
//   readonly type = ParticipantActionTypes.GET_INVESTOR_WS_SUCCESS;
//
//   constructor(public payload?: Investor[]) {}
// }
//
// export class GetInvestorsFailureWS implements Action {
//   readonly type = ParticipantActionTypes.GET_INVESTOR_WS_FAILURE;
//
//   constructor(public payload?: any) {}
// }

export class AddInvestorWS implements Action {
  readonly type = ParticipantActionTypes.ADD_INVESTOR_WS;

  constructor(
    public payload?: {
      res: { email: string; itmId: string; sts: number; role: string };
      prms: { snd: boolean };
    }
  ) {}
}

export class AddInvestorSuccessWS implements Action {
  readonly type = ParticipantActionTypes.ADD_INVESTOR_WS_SUCCESS;
  message = new Message('GNR_INVESTOR_INVITED');

  constructor(public payload?: Investor) {}
}

export class AddInvestorFailureWS implements Action {
  readonly type = ParticipantActionTypes.ADD_INVESTOR_WS_FAILURE;

  constructor(public payload?: any) {}
}

export class AddInvestorsWS implements Action {
  readonly type = ParticipantActionTypes.ADD_INVESTORS_WS;

  constructor(
    public payload?: [
      { asset: { _id: string }; contacts: Array<{ refId: string; tp: 100 }> }
    ]
  ) {}
}

export class AddInvestorsSuccessWS implements Action {
  readonly type = ParticipantActionTypes.ADD_INVESTORS_WS_SUCCESS;
  message = new Message('GNR_LENDER_INVITED');

  constructor(public payload?: Investor) {}
}

export class AddInvestorsFailureWS implements Action {
  readonly type = ParticipantActionTypes.ADD_INVESTORS_WS_FAILURE;

  constructor(payload?: any) {}
}

export class UpdateInvestorWS implements Action {
  readonly type = ParticipantActionTypes.UPDATE_INVESTOR_WS;

  constructor(public payload: { id: string; payload: any; queryId: string }) {}
}

export class UpdateInvestorSuccessWS implements Action {
  readonly type = ParticipantActionTypes.UPDATE_INVESTOR_WS_SUCCESS;
  message = new Message('GNR_LENDER_UPDATED');

  constructor(public payload?: Investor) {}
}

export class UpdateInvestorFailureWS implements Action {
  readonly type = ParticipantActionTypes.UPDATE_INVESTOR_WS_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteInvestorWS implements Action {
  readonly type = ParticipantActionTypes.DELETE_INVESTOR_WS;

  constructor(public payload?: { id: string; queryId: string }) {}
}

export class DeleteInvestorSuccessWS implements Action {
  readonly type = ParticipantActionTypes.DELETE_INVESTOR_WS_SUCCESS;
  message = new Message('GNR_LENDER_DELETED');

  constructor(public payload?: Investor) {}
}

export class DeleteInvestorFailureWS implements Action {
  readonly type = ParticipantActionTypes.DELETE_INVESTOR_WS_FAILURE;

  constructor(public payload?: any) {}
}

export class ResentInvestorInvite implements Action {
  readonly type = ParticipantActionTypes.RESENT_INVESTOR_INVITE;

  constructor(public payload?: any) {}
}

export class ResentInvestorInviteSuccess implements Action {
  readonly type = ParticipantActionTypes.RESENT_INVESTOR_INVITE_SUCCESS;
  message = new Message('GNR_INVESTOR_INV_SENT');

  constructor(public payload?: any) {}
}

export class ResentInvestorInviteFailure implements Action {
  readonly type = ParticipantActionTypes.RESENT_INVESTOR_INVITE_FAILURE;

  constructor(public payload?: any) {}
}

// --------------- Deal book participants ---------------

export class ConnectDealBookList implements Action {
  readonly type = ParticipantActionTypes.CONNECT_DEALBOOK_LIST;

  constructor(public payload?: 'investor') {}
}

export class DisconnectDealBookList implements Action {
  readonly type = ParticipantActionTypes.DISCONNECT_DEALBOOK_LIST;

  constructor(public payload?: any) {}
}

export class GetDealBookAction implements Action {
  type = ParticipantActionTypes.GET_DEALBOOK;

  constructor(public payload?: { p?: string; type?: string }) {}
}

export class GetDealBookSuccessAction implements Action {
  type = ParticipantActionTypes.GET_DEALBOOK_SUCCESS;

  constructor(public payload: any, public meta: EntityMeta) {}
}

export class GetDealBookFailureAction implements Action {
  type = ParticipantActionTypes.GET_DEALBOOK_FAILURE;

  constructor(public payload: any) {}
}

export class CreateDealBookAction implements Action {
  type = ParticipantActionTypes.CREATE_DEALBOOK;

  constructor(public payload: any) {}
}

export class CreateDealBookSuccessAction implements Action {
  type = ParticipantActionTypes.CREATE_DEALBOOK_SUCCESS;
  message = new Message('GNR_DEAL_BOOK_CREATED');

  constructor(public payload: any) {}
}

export class CreateDealBookFailureAction implements Action {
  type = ParticipantActionTypes.CREATE_DEALBOOK_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateDealBookAction implements Action {
  type = ParticipantActionTypes.UPDATE_DEALBOOK;

  constructor(public payload: any) {}
}

export class UpdateDealBookSuccessAction implements Action {
  type = ParticipantActionTypes.UPDATE_DEALBOOK_SUCCESS;
  message = new Message('GNR_DEAL_BOOK_UPDATED');

  constructor(public payload: any) {}
}

export class UpdateDealBookFailureAction implements Action {
  type = ParticipantActionTypes.UPDATE_DEALBOOK_FAILURE;

  constructor(public payload: any) {}
}

export class DeleteDealBookAction implements Action {
  type = ParticipantActionTypes.DELETE_DEALBOOK;

  constructor(public payload: any) {}
}

export class DeleteDealBookSuccessAction implements Action {
  type = ParticipantActionTypes.DELETE_DEALBOOK_SUCCESS;
  message = new Message('GNR_DEAL_BOOK_DELETED');

  constructor(public payload: any) {}
}

export class DeleteDealBookFailureAction implements Action {
  type = ParticipantActionTypes.DELETE_DEALBOOK_FAILURE;

  constructor(public payload: any) {}
}

export class GetInvestsAction implements Action {
  readonly type = ParticipantActionTypes.GET_INVESTS;

  constructor(public payload?: any) {}
}

export class GetInvestsSuccessAction implements Action {
  readonly type = ParticipantActionTypes.GET_INVESTS_SUCCESS;

  constructor(public payload?: any) {}
}

export class GetInvestsFailureAction implements Action {
  readonly type = ParticipantActionTypes.GET_INVESTS_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestInvests implements Action {
  readonly type = ParticipantActionTypes.REQUEST_INVESTS;

  constructor(public payload?: any) {}
}

export class RequestInvestsSuccess implements Action {
  readonly type = ParticipantActionTypes.REQUEST_INVESTS_SUCCESS;
  message = new Message('GNR_INVESTS_REQUEST');

  constructor(public payload?: any) {}
}

export class RequestInvestsFailure implements Action {
  readonly type = ParticipantActionTypes.REQUEST_INVESTS_FAILURE;

  constructor(public payload?: any) {}
}

export type ParticipantActions =
  // | ConnectInvestorList
  // | DisconnectInvestorList
  // | GetInvestorsWS
  // | GetInvestorsSuccessWS
  // | GetInvestorsFailureWS
  | AddInvestorWS
  | AddInvestorSuccessWS
  | AddInvestorFailureWS
  | UpdateInvestorWS
  | UpdateInvestorSuccessWS
  | UpdateInvestorFailureWS
  | DeleteInvestorWS
  | DeleteInvestorSuccessWS
  | DeleteInvestorFailureWS
  | ResentInvestorInvite
  | ResentInvestorInviteSuccess
  | ResentInvestorInviteFailure
  | ConnectDealBookList
  | DisconnectDealBookList
  | GetDealBookAction
  | GetDealBookSuccessAction
  | GetDealBookFailureAction
  | CreateDealBookAction
  | CreateDealBookSuccessAction
  | CreateDealBookFailureAction
  | UpdateDealBookAction
  | UpdateDealBookSuccessAction
  | UpdateDealBookFailureAction
  | DeleteDealBookAction
  | DeleteDealBookSuccessAction
  | DeleteDealBookFailureAction
  | GetInvestsAction
  | GetInvestsSuccessAction
  | GetInvestsFailureAction
  | RequestInvests
  | RequestInvestsSuccess
  | RequestInvestsFailure
  | AddInvestorsWS
  | AddInvestorsSuccessWS
  | AddInvestorsFailureWS
  // new
  | SubscribeDealBookListWS
  | UnsubscribeDealBookListWS
  | GetDealBookListWS
  | GetDealBookListSuccessWS
  | GetDealBookListFailureWS
  | CreateDealBookOnListWS
  | CreateDealBookOnListSuccessWS
  | CreateDealBookOnListFailureWS
  | UpdateDealBookOnListBookWS
  | UpdateDealBookOnListSuccessWS
  | UpdateDealBookOnListFailureWS
  | DeleteDealBookOnListWS
  | DeleteDealBookOnListSuccessWS
  | DeleteDealBookOnListFailureWS
  | SubscribeInvestorListWS
  | UnsubscribeInvestorListWS
  | GetInvestorListWS
  | GetInvestorListSuccessWS
  | GetInvestorListFailureWS
  | AddInvestorToListWS
  | AddInvestorToListSuccessWS
  | AddInvestorToListFailureWS
  | UpdateInvestorOnListWS
  | UpdateInvestorOnListSuccessWS
  | UpdateInvestorOnListFailureWS
  | DeleteInvestorOnListWS
  | DeleteInvestorOnListSuccessWS
  | DeleteInvestorOnListFailureWS
  | SubscribeSalesPipelineListWS
  | UnsubscribeSalesPipelineListWS
  | GetSalesPipelineListWS
  | GetSalesPipelineListSuccessWS
  | GetSalesPipelineListFailureWS
  | CreateSalesPipelineOnListWS
  | CreateSalesPipelineOnListSuccessWS
  | CreateSalesPipelineOnListFailureWS
  | UpdateSalesPipelineOnListBookWS
  | UpdateSalesPipelineOnListSuccessWS
  | UpdateSalesPipelineOnListFailureWS
  | DeleteSalesPipelineOnListWS
  | DeleteSalesPipelineOnListSuccessWS
  | DeleteSalesPipelineOnListFailureWS;
