import { ActionReducer } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export type Action = AuthActions.All;

export interface State {
  isLoading: boolean;
  wsInitToken: string;
}

const initialState: State = {
  isLoading: false,
  wsInitToken: null,
};

export const reducer: ActionReducer<State, Action> = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case AuthActions.GET_WS_INIT_TOKEN_OK: {
      return {
        ...state,
        wsInitToken: action.payload,
      };
    }

    case AuthActions.GET_WS_INIT_TOKEN_ERROR: {
      return {
        ...state,
        wsInitToken: null,
      };
    }

    case AuthActions.GET_WS_INIT_TOKEN: {
      return {
        ...state,
        wsInitToken: null,
      };
    }

    case AuthActions.LOGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case AuthActions.LOGIN_OK:
    case AuthActions.LOGIN_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export const getWsToken = (state: State) => state.wsInitToken;
