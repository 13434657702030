import { Action } from '@ngrx/store';
import { ETLProcessor } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export const UploadCenterActionTypes = {
  // ETL MAP

  SUBSCRIBE_ETL_MAP: type(
    '[Upload-Center ETL Map] Subscribe Upload-Center ETL Map'
  ),
  UNSUBSCRIBE_ETL_MAP: type(
    '[Upload-Center ETL Map] Unsubscribe Upload-Center ETL Map'
  ),

  GET_ETL_MAP: type('[Upload-Center ETL Map] GET Upload-Center ETL Map'),
  GET_ETL_MAP_SUCCESS: type(
    '[Upload-Center ETL Map] GET Upload-Center ETL Map Success'
  ),
  GET_ETL_MAP_FAILURE: type(
    '[Upload-Center ETL Map] GET Upload-Center ETL Map Failure'
  ),

  // DOCS

  SUBSCRIBE_DOCUMENTS: type(
    '[Upload-Center Documents] Subscribe Upload-Center Documents'
  ),
  UNSUBSCRIBE_DOCUMENTS: type(
    '[Upload-Center Documents] Unsubscribe Upload-Center Documents'
  ),

  UPLOAD_DOCUMENTS: type('[Upload-Center Documents] Upload documents'),
  UPLOAD_DOCUMENTS_SUCCESS: type(
    '[Upload-Center Documents] Upload documents Success'
  ),
  UPLOAD_DOCUMENTS_FAILURE: type(
    '[Upload-Center Documents] Upload documents Failure'
  ),

  GET_DOCUMENTS: type('[Upload-Center Documents] Get documents'),
  GET_DOCUMENTS_SUCCESS: type(
    '[Upload-Center Documents] Get documents Success'
  ),
  GET_DOCUMENTS_FAILURE: type(
    '[Upload-Center Documents] Get documents Failure'
  ),

  UPDATE_DOCUMENTS: type('[Upload-Center Documents] Update documents'),
  UPDATE_DOCUMENTS_SUCCESS: type(
    '[Upload-Center Documents] Update documents Success'
  ),
  UPDATE_DOCUMENTS_FAILURE: type(
    '[Upload-Center Documents] Update documents Failure'
  ),

  DELETE_DOCUMENT: type('[Upload-Center Documents] Delete document'),
  DELETE_DOCUMENT_SUCCESS: type(
    '[Upload-Center Documents] Delete document Success'
  ),
  DELETE_DOCUMENT_FAILURE: type(
    '[Upload-Center Documents] Delete document Failure'
  ),
};

export class SubscribeETLAction implements Action {
  readonly type = UploadCenterActionTypes.SUBSCRIBE_ETL_MAP;

  constructor(public payload?: any) {}
}

export class UnsubscribeETLAction implements Action {
  readonly type = UploadCenterActionTypes.UNSUBSCRIBE_ETL_MAP;

  constructor(public payload?: any) {}
}

export class GetETLAction implements Action {
  readonly type = UploadCenterActionTypes.GET_ETL_MAP;

  constructor(public payload?: any) {}
}

export class GetETLSuccessAction implements Action {
  readonly type = UploadCenterActionTypes.GET_ETL_MAP_SUCCESS;

  constructor(public payload?: ETLProcessor[]) {}
}

export class GetETLFailureAction implements Action {
  readonly type = UploadCenterActionTypes.GET_ETL_MAP_FAILURE;

  constructor(public payload?: any) {}
}

export class SubscribeUploadCenterDocumentsAction implements Action {
  readonly type = UploadCenterActionTypes.SUBSCRIBE_DOCUMENTS;

  constructor(public payload: { mysid: string; q: any[] }) {}
}

export class UnsubscribeUploadCenterDocumentsAction implements Action {
  readonly type = UploadCenterActionTypes.UNSUBSCRIBE_DOCUMENTS;

  constructor(public payload: { mysid: string }) {}
}

export class UploadUploadCenterDocumentsAction implements Action {
  readonly type = UploadCenterActionTypes.UPLOAD_DOCUMENTS;

  constructor(public payload?: any) {}
}

export class UploadUploadCenterDocumentsSuccessAction implements Action {
  readonly type = UploadCenterActionTypes.UPLOAD_DOCUMENTS_SUCCESS;

  constructor(public payload?: { res: any; mysid: string }) {}
}

export class UploadUploadCenterDocumentsFailureAction implements Action {
  readonly type = UploadCenterActionTypes.UPLOAD_DOCUMENTS_FAILURE;

  constructor(public payload?: any) {}
}

export class GetUploadCenterDocumentsAction implements Action {
  readonly type = UploadCenterActionTypes.GET_DOCUMENTS;

  constructor(public payload?: any) {}
}

export class GetUploadCenterDocumentsSuccessAction implements Action {
  readonly type = UploadCenterActionTypes.GET_DOCUMENTS_SUCCESS;

  constructor(public payload?: { res: any; mysid: string }) {}
}

export class GetUploadCenterDocumentsFailureAction implements Action {
  readonly type = UploadCenterActionTypes.GET_DOCUMENTS_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateUploadCenterDocumentsAction implements Action {
  readonly type = UploadCenterActionTypes.UPDATE_DOCUMENTS;

  constructor(public payload?: any) {}
}

export class UpdateUploadCenterDocumentsSuccessAction implements Action {
  readonly type = UploadCenterActionTypes.UPDATE_DOCUMENTS_SUCCESS;

  constructor(public payload?: { res: any; mysid: string }) {}
}

export class UpdateUploadCenterDocumentsFailureAction implements Action {
  readonly type = UploadCenterActionTypes.UPDATE_DOCUMENTS_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteUploadCenterDocumentAction implements Action {
  readonly type = UploadCenterActionTypes.DELETE_DOCUMENT;

  constructor(public payload?: any) {}
}

export class DeleteUploadCenterDocumentSuccessAction implements Action {
  readonly type = UploadCenterActionTypes.DELETE_DOCUMENT_SUCCESS;

  constructor(public payload?: { id: any; mysid: string }) {}
}

export class DeleteUploadCenterDocumentFailureAction implements Action {
  readonly type = UploadCenterActionTypes.DELETE_DOCUMENT_FAILURE;

  constructor(public payload?: any) {}
}

export type UploadCenterActions =
  | SubscribeUploadCenterDocumentsAction
  | UnsubscribeUploadCenterDocumentsAction
  | UploadUploadCenterDocumentsAction
  | UploadUploadCenterDocumentsSuccessAction
  | UploadUploadCenterDocumentsFailureAction
  | GetUploadCenterDocumentsAction
  | GetUploadCenterDocumentsSuccessAction
  | GetUploadCenterDocumentsFailureAction
  | UpdateUploadCenterDocumentsAction
  | UpdateUploadCenterDocumentsSuccessAction
  | UpdateUploadCenterDocumentsFailureAction
  | DeleteUploadCenterDocumentAction
  | DeleteUploadCenterDocumentSuccessAction
  | DeleteUploadCenterDocumentFailureAction
  | SubscribeETLAction
  | UnsubscribeETLAction
  | GetETLAction
  | GetETLSuccessAction
  | GetETLFailureAction;
