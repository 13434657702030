export class EntityMeta {
  constructor(
    public ops: MetaOperators = {},
    public properties: EntityMetaProperty[] = [],
    public search: any[] = [],
    public sort: SortMeta = new SortMeta()
  ) {}
}

export type MetaPropertyOperator = string;

export interface MetaOperators {
  [key: MetaPropertyOperator]: MetaOperatorValue;
}

export class MetaOperatorValue {
  /**
   *
   * @param label label of operator
   * @param icon icon or symbol for operator
   */
  constructor(public label: string, public icon: string) {}
}
export class EntityMetaProperty {
  /**
   *
   * @param id property id
   * @param subId property subId
   * @param label propeerty label
   * @param description propeerty description (fallback if there is no label)
   * @param s propeerty icon name
   * @param type property type
   * @param o array of operators
   * @param v array of posible values
   * @param filter boolean if use to filter
   * @param sort boolean if use to sort
   */
  constructor(
    public id: string,
    public subId?: string,
    public i?: boolean,
    public icon?: string,
    public label?: string,
    public type?: string,
    public description?: string,
    public filter?: boolean,
    public sort?: boolean,
    public groupBy?: boolean,
    public o?: Array<MetaPropertyOperator>,
    public v?: Array<MetaPropertyValue>,
    public disabledOperations?: any[]
  ) {}
}

export class MetaPropertyValue {
  /**
   *
   * @param value value to be used for sorting
   * @param label label of value
   * @param icon icon of value
   * @param color color of value
   */
  constructor(
    public value?: string | number,
    public label?: string,
    public icon?: string,
    public color?: string,
    public id?: string,
    public description?: string,
    public priority?: number
  ) {}
}

export class SortMeta {
  constructor(public defSort: SortCriteria[] = []) {}
}

export class SortCriteria {
  /**
   * @param attr attribute name for sorting
   * @param type sort direction (asc/desc)
   */
  constructor(public attr: string, public type: string) {}
}

export interface SortMetaData {
  properties: EntityMetaProperty[];
  sort: SortMeta;
}
