import { Action } from '@ngrx/store';

import { Step } from '@qtek/shared/models';
import { type } from '@qtek/shared/utils';

export class WizardActionTypes {
  static readonly IS_WIZARD_DONE = type('[Wizard] Is wizard done');
  static readonly GET_STEPS = type('[Wizard] Get Steps');
  static readonly GET_STEPS_SUCCESS = type('[Wizard] Get Steps Success');

  static readonly GET_DEMO_STEPS = type('[Wizard] Get Demo Steps');
  static readonly GET_DEMO_STEPS_SUCCESS = type(
    '[Wizard] Get Demo Steps Success'
  );

  static readonly STEP_PRS_1 = type('[Wizard] Step Prs 1');
  static readonly STEP_CMP_1 = type('[Wizard] Step Cmp 1');
  static readonly STEP_CMP_2 = type('[Wizard] Step Cmp 2');
  static readonly STEP_CMP_8 = type('[Wizard] Step Cmp 8');
  static readonly STEP_CMP_64 = type('[Wizard] Step Cmp 64');
  static readonly STEP_CMP_260 = type('[Wizard] Step Cmp 260');
  static readonly STEP_CMP_1536 = type('[Wizard] Step Cmp 1536');
  static readonly STEP_CMP_2048 = type('[Wizard] Step Cmp 2048');
  static readonly STEP_CMP_4096 = type('[Wizard] Step Cmp 4096');

  static readonly SET_MAX_PERSON_PROGRESS = type(
    '[Wizard] Set Max Person Progress'
  );
  static readonly SET_MAX_COMPANY_PROGRESS = type(
    '[Wizard] Set Max Company Progress'
  );

  static readonly SET_LOADING = type('[Wizard] Set Loading');
  static readonly SAVE_PROMO = type('[Wizard] Save promo');
  static readonly SAVE_PROMO_SUCCESS = type('[Wizard] Save promo success');
  static readonly SAVE_PROMO_FAILURE = type('[Wizard] Save promo failure');
}

export class SetWizardIsDone implements Action {
  readonly type = WizardActionTypes.IS_WIZARD_DONE;

  constructor(public payload?: unknown) {}
}

export class SetLoadingAction implements Action {
  readonly type = WizardActionTypes.SET_LOADING;

  constructor(public payload: boolean) {}
}

export class GetStepsAction implements Action {
  readonly type = WizardActionTypes.GET_STEPS;

  constructor(public payload?: any) {}
}

export class GetStepsSuccessAction implements Action {
  readonly type = WizardActionTypes.GET_STEPS_SUCCESS;

  constructor(public payload: Step[]) {}
}

export class GetDemoStepsAction implements Action {
  readonly type = WizardActionTypes.GET_DEMO_STEPS;

  constructor(public payload?: any) {}
}

export class GetDemoStepsSuccessAction implements Action {
  readonly type = WizardActionTypes.GET_DEMO_STEPS_SUCCESS;

  constructor(public payload: any) {}
}

export class SetMaxCompanyProgressAction implements Action {
  readonly type = WizardActionTypes.SET_MAX_COMPANY_PROGRESS;

  constructor(public payload: number) {}
}

export class SetMaxPersonProgressAction implements Action {
  readonly type = WizardActionTypes.SET_MAX_PERSON_PROGRESS;

  constructor(public payload: number) {}
}

export class StepPrs1Action implements Action {
  readonly type = WizardActionTypes.STEP_PRS_1;

  constructor(public payload: any) {}
}

export class StepCmp1Action implements Action {
  readonly type = WizardActionTypes.STEP_CMP_1;

  constructor(public payload: any) {}
}

export class StepCmp2Action implements Action {
  readonly type = WizardActionTypes.STEP_CMP_2;

  constructor(public payload: any) {}
}

export class StepCmp8Action implements Action {
  readonly type = WizardActionTypes.STEP_CMP_8;

  constructor(public payload: any) {}
}

export class StepCmp64Action implements Action {
  readonly type = WizardActionTypes.STEP_CMP_64;

  constructor(public payload: any) {}
}

export class StepCmp260Action implements Action {
  readonly type = WizardActionTypes.STEP_CMP_260;

  constructor(public payload: any) {}
}

export class StepCmp1536Action implements Action {
  readonly type = WizardActionTypes.STEP_CMP_1536;

  constructor(public payload: any) {}
}

export class StepCmp2048Action implements Action {
  readonly type = WizardActionTypes.STEP_CMP_2048;

  constructor(public payload: any) {}
}

export class StepCmp4096Action implements Action {
  readonly type = WizardActionTypes.STEP_CMP_4096;

  constructor(public payload: any) {}
}

export class SavePromoAction implements Action {
  readonly type = WizardActionTypes.SAVE_PROMO;

  constructor(public payload: { vndId: string; promo: string }) {}
}

export class SavePromoSuccessAction implements Action {
  readonly type = WizardActionTypes.SAVE_PROMO_SUCCESS;

  constructor(public payload?: any) {}
}

export class SavePromoFailureAction implements Action {
  readonly type = WizardActionTypes.SAVE_PROMO_FAILURE;

  constructor(public payload?: any) {}
}

export type WizardActions =
  | SetWizardIsDone
  | GetStepsAction
  | GetStepsSuccessAction
  | SetLoadingAction
  | SetMaxCompanyProgressAction
  | SetMaxPersonProgressAction
  | StepPrs1Action
  | StepCmp1Action
  | StepCmp2Action
  | StepCmp64Action
  | StepCmp8Action
  | GetDemoStepsSuccessAction
  | StepCmp260Action
  | StepCmp1536Action
  | StepCmp2048Action
  | StepCmp4096Action
  | SavePromoAction
  | SavePromoSuccessAction
  | SavePromoFailureAction;
